import React from "react";
import { Button } from "../../button";
import { ImageBuilder } from "../../global/image-builder";

export const DevelopersApiDetails = ({ section }) => {};

export const DevelopersPanels = ({ section }) => (
  <div className="grid md:grid-cols-2 gap-24">
    <CustomCard
      {...{
        label: "We’re built on trust.",
        img: "card-trust.svg",
        cta: "Read on",
        summary:
          "Protecting your data is our top priority. We adhere to the highest security standards with data encryption, enterprise-grade reliability, and SOC2 compliance.",
        url: "/security/",
      }}
    />
    <CustomCard
      {...{
        label: "Learn with community builders.",
        img: "card-community.svg",
        cta: "Join us",
        summary:
          "Join the Uncommon community to learn more about the API, ask product questions, share feedback, and connect with others as you build.",
        url: "/community/",
      }}
    />
  </div>
);

const CustomCard = ({ img, label, summary, cta, ctaSummary, url }) => (
  <a
    href={url}
    className="group transform transition-card scale-100 hover:scale-105"
  >
    <ImageBuilder src={`/static/img/${img}`} />
    <div className="text-sm font-semibold mt-4 mb-2">{label}</div>
    <div className="text-xl mb-10 leading-normal">{summary}</div>
    <Button
      text={cta}
      ctaLink={false}
      bgColor="bg-black"
      className="whitespace-nowrap transition-opacity opacity-100 group-hover:opacity-80"
    />
  </a>
);
