import React from "react";

// @todo break apart: PageContext, TokenContext, ClientContext

export const SiteContext = React.createContext({
  doc: {},
  banners: [],
  isHomepage: false,
  isBlog: false,
  isPageTop: true,
  // activeSection: "",
  // setActiveSection: () => true,
  setIsTracking: () => false,
  isBrowser: false,
  isNotMobile: false,
  isLarge: false,
  isDarkBg: false,
  isHeaderPage: false,
  hideNav: false,
  email: null,
  twitter: null,
  linkedin: null,
  slack: null,
  calendly: null,
  description: null,
  featureFlags: [],
  isActive: () => false,
  storage: () => false,
  token: (s) => s,
  isAdmin: false,
  interfaceTokens: [],
  location: {},
  metrics: {
    analytics: {
      storage: {},
      track: (s, {}) => undefined,
    },
    logEvent: () => undefined,
    // (action, label)
    logClick: () => undefined,
    logError: () => undefined,
  },
  isLiveProd: false,
  generateUnpublishedPages: false,
  uiTokens: new Map([
    ["signupComplete", "Complete!"],
    ["subscribeHeaderBlog", "Subscribe Now"],
    ["seeAllPosts", "See all posts"],
    ["suggestions", "We think you'd like these"],
    ["slogan", "You're great, everyone likes you."],
    ["backToBlog", "See all posts"],
    ["backToEvents", "More Events"],
    ["eventMeetingCta", "Join Meeting"],
    ["eventDefaultCtaHeader", "Not in our Slack yet?"],
    ["eventDefaultCta", "Join Now"],
    ["eventPresenters", "Presenters"],
    ["eventAbout", "About this Event"],
    ["eventAltCta", "Learn More"],
    ["eventRegisterCta", "Register"],
    ["eventRegisterHeader", "Register Now"],
    ["eventRecurringStart", "Start Date"],
    ["eventOver", "Event over"],
    ["formReset", "Reset Form"],
    ["formFieldFirstName", "Your first name"],
    ["formFieldLastName", "...and last name"],
    ["formFieldEmail", "Your work email address?"],
    ["formFieldOrg", "Organization"],
    ["formFieldSizeTitle", "Community Size"],
    ["formFieldSizePlaceholder", "Size of your community"],
    ["formFieldRoleTitle", "Job Role"],
    ["formFieldRolePlaceholder", "What do you do?"],
    ["formFieldRoleOther", "Something else?"],
    ["formFieldTeamSizeTitle", "Community Team Size"],
    ["formFieldTeamSizePlaceholder", "What size is your community team?"],
    ["formFieldStage", "What stage are you at with your community?"],
    ["formFieldTools", "Where does your community interact today?"],
    ["formFieldToolsOther", "Other tools?"],
    ["formFieldTopics", "What goals do you have for building community?"],
    ["formFieldListsOptional", "Stay Updated"],
    ["formFieldOpenTextTitle", "Anything Else"],
    ["formFieldOpenTextPlaceholder", "Submit a question"],
    ["formFeedback", "Send Feedback"],
    ["formDefaultButton", "Get Started"],
    ["formDefaultSuccessLabel", "Success"],
    ["formDefaultSuccessMessage", "Thank you!"],
    ["formRegSuccessLabel", "Welcome"],
    ["formRegSuccessMessage", "Thanks for signing up."],
    ["topicsHeader", "Explore These Topics"],
    ["twitterShare", "Recently read this post from @CommonRoomHQ - "],
    ["integrationsDataHeader", "Business data"],
    ["joinPhrase", "Join the conversation"],
    ["suggestionsBlog", "Related articles from our blog"],
    ["getStartedSummary", "Try Common Room for free"],
    ["backToResources", "See all resources"],
    ["resourcesSuggestions", "We think you'd like these"],
    ["pricingPlansSummary", "All trials start on the Team plan for 14-days."],
    [
      "signalsSubscribeCard",
      "Subscribe to stay updated when new signal guides are added",
    ],
    [
      "signalsSubscribeWidget",
      "Stay updated when new signals guides are added",
    ],
  ]),
});
