import React, { useContext } from "react";
import { pageDocPath, trackPath } from "../../helpers";
import { Button } from "./button";
import PortableText from "./portableText";
import { ATTRIBUTION_PARAM } from "./sections/snowflake/navigation";
import { SiteContext } from "./global/site-context";
import { cn } from "../lib/helpers";
import { CardContext } from "./card/context";
import { QuoteCardContent } from "./sections/quote-panels";
import { ImageBuilder } from "./global/image-builder";

import * as style from "./global/global.module.css";

// deep link workbooks folks with an account
// https://app.commonroom.io/community/251-common-room/workflows/${node.slug.current}

export const InlineCardWorkflow = ({ node, cta }) => {
  const site = useContext(SiteContext);
  return (
    <div>
      <a
        href={`/signup/?${ATTRIBUTION_PARAM}=workflow--${trackPath(node)}`}
        className="group"
        onClick={() =>
          site.metrics.logClick("call-to-action-click", "workflow")
        }
      >
        <div className="border border-dark-10 p-4 my-12 rounded-md bg-dark-2 grid sm:grid-cols-2/3-1/3 shadow-md">
          <div className="flex">
            <div className="mr-3 flex-none">
              <ImageBuilder
                image={node?.integrations[0].mainImage}
                width={48}
                className="mt-1 mx-2.5"
              />
            </div>
            <div>
              <p
                className="text-secondary text-sm font-medium tracking-loose"
                style={{ marginBottom: 0 }}
              >
                Workflow
              </p>
              <h4 className="font-medium text-lg mb-1 group-hover:text-link">
                {node.title}
              </h4>
              <div className="text-sm">
                <PortableText blocks={node.excerpt} />
              </div>
            </div>
          </div>
          <div className="justify-self-end mt-4 sm:mt-0">
            <Button
              text="Get started"
              bgColor="bg-black"
              noLink={true}
              href="/signup/"
            />
          </div>
        </div>
      </a>
    </div>
  );
};

export const InlineCardPlaybook = ({ node }) => {
  const site = useContext(SiteContext);
  return (
    <div>
      <a
        href={pageDocPath(node)}
        className="bg-white text-black group"
        onClick={() =>
          site.metrics.logClick("call-to-action-click", "playbook")
        }
      >
        <div className="border border-dark-10 p-4 my-12 rounded-md bg-dark-2 grid sm:grid-cols-2/3-1/3 shadow-md">
          <div className="flex">
            <div className="mr-4 flex-none">
              <ImageBuilder
                src="/static/img/emoji-playbook.svg"
                width={64}
                className="mt-1"
              />
            </div>
            <div>
              <p
                className="text-secondary text-sm font-medium tracking-loose"
                style={{ marginBottom: 0 }}
              >
                Playbook
              </p>
              <h4 className="font-medium text-lg mb-1 group-hover:text-link">
                {node.title}
              </h4>
              <div className="text-sm">
                <PortableText blocks={node.excerpt} />
              </div>
            </div>
          </div>
          <div className="justify-self-end mt-4 sm:mt-0">
            <Button
              text="Explore playbook"
              bgColor="bg-black"
              noLink={true}
              href="/playbooks/"
            />
          </div>
        </div>
      </a>
    </div>
  );
};

export const InlineCardQuote = ({ node, skipLogo, simpleLayout }) => (
  <div
    className={
      !simpleLayout
        ? cn(
            "border border-dark-5 my-12 p-4 md:p-12 text-xl leading-normal rounded-2xl",
            style.gradientBox
          )
        : null
    }
  >
    <div
      className={cn(
        "bg-white border rounded-2xl p-6 md:pl-8 md:pr-10",
        !simpleLayout
          ? "shadow-lg border-dark-30"
          : "border-dark-20 text-medium"
      )}
    >
      <div className="flex">
        {/* <span className="text-secondary text-5xl font-bold mr-1">
              &quot;
            </span> */}
        <CardContext.Provider
          value={{
            authors: [
              {
                author: node?.authors ? node.authors[0].author : node?.author,
              },
            ],
          }}
        >
          <QuoteCardContent
            q={{ ...node }}
            showLogo={!skipLogo}
            boldText={true}
          />
        </CardContext.Provider>
        {/* <blockquote>{node.quote.children}</blockquote> */}
      </div>
    </div>
  </div>
);
