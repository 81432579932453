import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Layout } from "../components/global/layout";
import "../style/main.scss";
import { mapEdgesToNodes } from "../lib/helpers";

// @todo query events for banners

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      description
      email
      twitter
      linkedin
      slack
      # calendly
      featureFlags
      interfaceTokens {
        label
        value
      }
    }
    assets: allSanityAsset(
      limit: 12
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        status: { in: ["published", "hidden"] }
        _rawBanner: { ne: null }
      }
    ) {
      edges {
        node {
          _type
          _id
          title
          emoji
          _rawBanner(resolveReferences: { maxDepth: 5 })
          externalUrl
          paths
          bannerLink
          slug {
            current
          }
          categories {
            _id
            title
            titlePublic
            slug {
              current
            }
          }
        }
      }
    }
    events: allSanityEvent(
      limit: 6
      sort: { fields: [startDate], order: DESC }
      filter: {
        status: { in: ["published", "hidden"] }
        _rawBanner: { ne: null }
      }
    ) {
      edges {
        node {
          _type
          _id
          title
          emoji
          _rawBanner(resolveReferences: { maxDepth: 5 })
          externalUrl
          paths
          slug {
            current
          }
          categories {
            _id
            title
            titlePublic
            slug {
              current
            }
          }
        }
      }
    }
  }
`;

export const LayoutContainer = (props) => {
  const { site, assets, events } = useStaticQuery(query) || {};

  return site ? (
    <Layout
      {...props}
      site={{ ...site }}
      assets={mapEdgesToNodes(events).concat(mapEdgesToNodes(assets))}
    />
  ) : null;
};
