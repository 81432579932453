// @ts-nocheck
import React from "react";
import PortableText from "../../portableText";
import { VideoPopover } from "../../video-popover";
import { cn } from "../../../lib/helpers";
import { Image } from "../featured-media";
import { SectionWrapper } from "../wrapper";
import { ImageBuilder } from "../../global/image-builder";

import * as marqueeStyles from "./marquee.module.css";

const PRODUCT_VIDEO_ID = "908050613";
const MOBILE_IMAGE = "/static/img/hero_video_mobile.png";

export const GRADIENT_BACKGROUND_STYLE =
  "radial-gradient(56.12% 73.00% at 37.75% -3.18%, rgba(107, 255, 228, 0.25) 0%, rgba(107, 255, 228, 0.00) 100%), radial-gradient(58.30% 72.52% at 69.25% 58.49%, rgba(207, 148, 59, 0.45) 0%, rgba(255, 143, 107, 0.00) 100%), linear-gradient(58deg, rgba(211, 183, 255, 0.15) 0%, rgba(235, 233, 117, 0.20) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.70) 100%), #D3B7FF";

// section.mainImage is used for video preview

export const Hero = ({ section }) => (
  <div
    style={{ background: GRADIENT_BACKGROUND_STYLE }}
    className="relative overflow-hidden pt-12 md:pt-8"
  >
    <div className="absolute opacity-80 top-24">
      <Image
        src="/static/img/dot-circle.svg"
        alt={section.header}
        width={800}
      />
    </div>
    <SectionWrapper container="large">
      {/* <div className="max-w-screen-lg mx-auto py-40 relative z-10"> */}
      <div className="lg:flex pb-12 lg:py-12">
        <div
          style={{
            maxWidth: "545px",
          }}
        >
          <PortableText
            blocks={section._rawSummary}
            className="sm:mx-6 lg:mx-0 mb-8"
          />
        </div>
        <div className="mx-auto md:max-w-2/3 sm:mt-24 lg:mt-0 lg:max-w-none relative md:-right-4 flex items-center">
          <VideoPopover
            image={section.image}
            mobileImage={MOBILE_IMAGE}
            alt="Common Room product video"
            width={1024}
            vimeoId={PRODUCT_VIDEO_ID}
          />
        </div>
      </div>
      {/* </div> */}
    </SectionWrapper>
  </div>
);

export const Activities = ({ section }) => (
  <div className="overflow-hidden">
    <ActivityRow i={0} />
    <ActivityRow i={1} style={marqueeStyles.marqueeGroupReverseSpeed3} />
    <ActivityRow i={2} style={marqueeStyles.marqueeGroupSpeed2} />
    <ActivityRow i={3} style={marqueeStyles.marqueeGroupReverse} />
    <ActivityRow i={4} style={marqueeStyles.marqueeGroupSpeed3} />
  </div>
);

const ActivityRow = ({ i, style }) => (
  <div
    className={cn("flex select-none pb-2 text-white", marqueeStyles.marquee)}
  >
    <div
      className={cn(
        "flex items-center justify-around",
        marqueeStyles.marqueeGroup,
        style
      )}
    >
      {[0, 1, 2, 3].map((index) => (
        <ImageBuilder
          src={`/static/img/activities-${i}.png`}
          height={35}
          width={1498}
          alt="row of data activities"
          key={index}
        />
      ))}
    </div>
  </div>
);
