import React, { useContext, useLayoutEffect } from "react";
import HubspotForm from "react-hubspot-form";
import { SiteContext } from "../global/site-context";
import { trackableFields } from "../web-form";
import {
  FIRST_SEEN_STASH,
  clientAttribution,
  getSimpleDate,
} from "../../lib/client-attribution";
import { ATTRIBUTION_PARAM } from "./snowflake/navigation";
import { cn, getStashed } from "../../lib/helpers";
import { DELAY_FORM_SUBMIT, HAS_SENT_CONTACT_STASH } from "../sticky-popup";

export const CONTACT_FORM_ID_HUBSPOT = "34796c27-056e-431a-a789-1d3eabe627e3";

const hiddenFieldMap = new Map();
hiddenFieldMap.set("campaignType", "campaign_type__most_recent_");
hiddenFieldMap.set("pageTitle", "event_title__most_recent_");
hiddenFieldMap.set("eventDate", "event_date__most_recent_");
hiddenFieldMap.set("pageUrl", "offer_page_url__most_recent_");
hiddenFieldMap.set("eventMeetingUrl", "event_url__most_recent_");
hiddenFieldMap.set("eventAddress", "event_address__most_recent_");

export const HubspotWebform = ({
  section = {},
  doc,
  className,
  minHeight,
  onFormSubmitted,
  attribution,
}) => {
  const site = useContext(SiteContext);

  useLayoutEffect(() => {
    window.addEventListener("message", msgHandler);
    return () => {
      window.removeEventListener("message", msgHandler);
    };
  }, []);

  const msgHandler = (event) => {
    if (event.data.type !== "hsFormCallback") return;

    if (event.data.eventName === "onFormSubmitted") {
      site.metrics.logEvent({
        label:
          doc?._type === "event" ? "sign up" : attribution?.label || "send",
        category: "button_click",
        action:
          doc?._type === "event" ? "event" : attribution?.action || "contact",
      });

      if (onFormSubmitted) {
        onFormSubmitted(event);
      }

      if (!section.formId || section.formId === CONTACT_FORM_ID_HUBSPOT) {
        site.metrics.analytics.storage.setItem(HAS_SENT_CONTACT_STASH, true);
      }
    }

    if (event.data.eventName === "onFormReady") {
      // Required to avoid submit errors
      // https://github.com/escaladesports/react-hubspot-form/issues/22
      const script = document.createElement("script");
      script.src =
        "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
      let jqueryScript = document.getElementsByTagName("script");
      let src = Array.from(jqueryScript).filter(
        (item) => item.src === script.src
      );
      if (src.length === 0) {
        document.body.appendChild(script);
      }

      clientAttribution({
        isActive: site.isActive,
        storage: site.metrics.analytics.storage,
        referrer: document.referrer,
        callback: (urlParams, storage) => {
          const setField = (fieldName, value, show) => {
            const wrapper = document
              .querySelector("iframe[class=hs-form-iframe]")
              .contentDocument.querySelector(`.hs_${fieldName}`);

            const input = document
              .querySelector("iframe[class=hs-form-iframe]")
              .contentDocument.querySelector(`input[name=${fieldName}]`);

            if (show && wrapper && input) {
              wrapper.style = { display: "block" };
              input.type = "text";
            }

            if (input && value) {
              input.value = value;
            }
          };

          const showHidden = urlParams.has("show");

          section.hiddenFields?.forEach((f) => {
            if (hiddenFieldMap.has(f.name)) {
              setField(hiddenFieldMap.get(f.name), f.value, showHidden);
            }
          });

          trackableFields.forEach((id) => {
            setField(
              `utm_parameter___${id}`,
              urlParams.get(`utm_${id}`),
              showHidden
            );
            setField(
              `utm_parameter___${id}_original`,
              storage.getItem(`origin.urlParams.${id}`),
              showHidden
            );
          });

          [
            {
              input: "page_cta_path_on_website",
              value: attribution?.website || urlParams.get(ATTRIBUTION_PARAM),
            },
            {
              input: "first_seen_on_website",
              value: getSimpleDate(FIRST_SEEN_STASH, storage),
            },
            {
              input: "utm_parameter___created_date",
              value: getSimpleDate(
                `urlParams.${trackableFields.find((f) =>
                  getStashed({ id: `urlParams.${f}`, storage })
                )}.created`,
                storage
              ),
            },
            {
              input: "utm_parameter___created_date_original",
              value: getSimpleDate(
                `origin.urlParams.${trackableFields.find((f) =>
                  storage.getItem(`origin.urlParams.${f}`)
                )}.created`,
                storage
              ),
            },
          ].forEach((field) => {
            setField(field.input, field.value, showHidden);
          });
        },
      });
    }
  };

  return (
    <div style={{ minHeight }} className={cn("relative", className)}>
      {site.doc.backgroundColor === "purple" && (
        <>
          <div
            className="absolute"
            style={{
              background:
                "radial-gradient(closest-side, rgba(207, 148, 59, 0.4), transparent)",
              top: "-33svh",
              right: "-50svh",
              height: "100svh",
              width: "100svh",
            }}
          ></div>
          <div
            className="absolute"
            style={{
              // lime
              background:
                "radial-gradient(closest-side, rgba(191, 255, 168, 0.5), transparent)",
              top: "-15rem",
              left: "-15svh",
              right: "-15svh",
              height: "100svh",
              maxHeight: "50rem",
              bottom: "-4rem",
            }}
          ></div>
        </>
      )}
      <div
        className={cn(
          "relative absolute z-50",

          site.doc.backgroundColor === "purple"
            ? "p-6 rounded-xl bg-white border border-link"
            : null
        )}
      >
        <HubspotForm
          portalId="21450559"
          formId={section.formId || CONTACT_FORM_ID_HUBSPOT}
          region="na1"
          loading={<div>Loading...</div>}
          onSubmit={() => {
            if (section.redirectUrl) {
              setTimeout(() => {
                window.location.href = section.redirectUrl;
              }, DELAY_FORM_SUBMIT);
            }
          }}
          translations={
            section.buttonText
              ? {
                  en: {
                    submitText: section.buttonText,
                  },
                }
              : {}
          }
        />
      </div>
    </div>
  );
};
