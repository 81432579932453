import React, { useContext } from "react";
import { cn } from "../../lib/helpers";
import { SiteContext } from "./site-context";

import * as styles from "./global.module.css";

// @todo deprecate and replace with <PageHeader>

export const GraphicBackground = () => {
  const site = useContext(SiteContext);
  const isTall = ["uncommon/events", "uncommon/jobs"].includes(
    site.doc?.slug?.current
  );

  const dotsBg = ["dots"].includes(
    site?.doc?.sections && site.doc.sections[0]?.background
  );

  if (site.isHeaderPage && !dotsBg) {
    return null;
  }

  return site.isHeaderPage ? (
    <div className={cn("h-full w-full absolute", styles.fadeInGraphics)}>
      {!site.isDarkBg && !dotsBg && (
        <div
          className="absolute mx-auto w-full"
          style={{
            backgroundColor: "#FAFAFA",
            backgroundImage: `url('/static/img/tile.svg')`,
            backgroundSize: "12px 12px",
            height: isTall ? "22rem" : "20rem",
          }}
        ></div>
      )}
    </div>
  ) : null;
};
