import React from "react";

/**
 * @type {React.VFC<{
 *  color?: string
 *  rotate: string
 *  className?: string
 *  size?: number
 * }>}
 */
export const ArrowLeft = ({ color, rotate, className, size }) => (
  <span
    style={
      rotate
        ? { transform: `rotate(${rotate}deg)`, display: "inline-block" }
        : null
    }
    className={className}
  >
    <svg
      width={size || "10"}
      height={size ? null : "10"}
      viewBox="0 0 14 13"
      fill={color || "currentColor"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.02734 11.9453C8.30078 11.6719 8.27344 11.2617 8.02734 10.9883L4.71875 7.84375H12.5938C12.9492 7.84375 13.25 7.57031 13.25 7.1875V6.3125C13.25 5.95703 12.9492 5.65625 12.5938 5.65625H4.71875L8.02734 2.53906C8.27344 2.26562 8.30078 1.85547 8.02734 1.58203L7.42578 0.980469C7.17969 0.734375 6.74219 0.734375 6.49609 0.980469L1.19141 6.3125C0.917969 6.55859 0.917969 6.96875 1.19141 7.21484L6.49609 12.5469C6.74219 12.793 7.15234 12.793 7.42578 12.5469L8.02734 11.9453Z" />
    </svg>
  </span>
);

export const ArrowRight = (props) => <ArrowLeft rotate={180} {...props} />;
