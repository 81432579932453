import React from "react";
import { cn, mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { LinkedIn } from "../../icon/linkedin";
import { Twitter } from "../../icon/twitter";

import * as globalStyles from "../../global/global.module.css";
import { ImageBuilder } from "../../global/image-builder";

const IMAGE_SIZE = 168;

export const TeamMembers = ({ section }) => {
  const { members } =
    useStaticQuery(graphql`
      query TeamMemberSectionQuery {
        members: allSanityMember(sort: { fields: [publishedAt], order: ASC }) {
          edges {
            node {
              name
              headline
              emoji
              twitter
              quote
              linkedin
              twitter
              mainImage {
                ...SanityImageSimple
              }
            }
          }
        }
      }
    `) || {};

  const memberList = members && mapEdgesToNodes(members);

  return (
    <>
      <ul className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 overflow-hidden">
        {memberList &&
          memberList.map((member, i) => (
            <li
              key={i}
              className={cn(
                globalStyles.revealChildrenHover,
                "mb-4 relative bg-dark-5 rounded-lg group"
              )}
            >
              <div className="p-4">
                {member.mainImage && member.mainImage.asset && (
                  <ImageBuilder
                    image={member.mainImage}
                    height={IMAGE_SIZE}
                    width={IMAGE_SIZE}
                    alt={`Portait of ${member.name}`}
                    className="w-full rounded-lg"
                  />
                )}
                <h4 className="mt-2">{member.name}</h4>
                <h5 className="text-xs font-semibold">{member.headline}</h5>
              </div>

              <div className="absolute top-0 right-0 bottom-0 left-0 p-4 opacity-0 group-hover:opacity-100 transition-opacity bg-lavender rounded-lg">
                <h4 className="mt-2">{member.name}</h4>
                <h5 className="text-xs font-semibold">{member.headline}</h5>
                <p className="text-3xl lg:text-4xl my-2">{member.emoji}</p>
                <p className="text-xs sm:text-sm">{member.quote}</p>
                {member.linkedin && (
                  <div className="text-sm mt-2 flex gap-2 absolute bottom-0 left-0 p-4">
                    {member.linkedin && (
                      <a
                        className="text-black hover:text-link opacity-80 hover:opacity-100"
                        href={member.linkedin}
                        target="_blank"
                      >
                        <LinkedIn size="24" />
                      </a>
                    )}
                    {member.twitter && (
                      <a
                        className="text-black hover:text-link opacity-80 hover:opacity-100"
                        href={`https://twitter.com/${member.twitter}`}
                        target="_blank"
                      >
                        <Twitter size="24" />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};
