import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { cn, mapEdgesToNodes } from "../../../lib/helpers";

const filters = [
  { label: "Community", slug: "community" },
  { label: "Advocacy", slug: "advocacy" },
  { label: "Education", slug: "education" },
  { divider: true },
  { label: "All", slug: "" },
];

// @todo filter for recent created date

export const JobsList = ({ section }) => {
  const { jobs } =
    useStaticQuery(graphql`
      query JobsListQuery {
        jobs: allSanityJob(limit: 100) {
          edges {
            node {
              title
              company
              url
              _createdAt
              categories {
                _id
                slug {
                  current
                }
              }
            }
          }
        }
      }
    `) || {};
  const jobList = jobs && mapEdgesToNodes(jobs);

  const [tag, setTag] = useState();
  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    setTag(urlParams.get("tag"));
  }, []);

  const filteredJobs = tag
    ? jobList.filter(
        (j) =>
          j.categories &&
          j.categories[0] &&
          j.categories[0].slug.current === tag
      )
    : jobList;

  // find unique
  const companyList = [...new Set(filteredJobs.map((j) => j.company))];

  return (
    <div className="md:text-xl max-w-screen-sm mx-auto">
      <div className="uppercase text-sm bg-dark-5 rounded-md py-3 text-center mb-8">
        {filters.map((filter, i) => {
          return filter.divider ? (
            <span className="mr-3" key={i}>
              |
            </span>
          ) : (
            <a
              href={filter.slug ? `?tag=${filter.slug}` : "?"}
              className={cn(
                tag === filter.slug
                  ? "text-black hover:text-link"
                  : "text-link hover:text-black",
                "mr-3 last:mr-0"
              )}
              key={i}
            >
              {filter.label}
            </a>
          );
        })}
      </div>
      {companyList &&
        companyList.sort().map((company, index) => {
          const jobsInCompany = filteredJobs.filter((job) => {
            return job.company === company;
          });

          return (
            <div
              className="md:grid grid-cols-2 gap-4 border-b-2 last:border-b-0 pb-4 mb-8 border-gray-200"
              key={index}
            >
              <p className="font-bold mb-3 md:mb-6">{company}</p>
              <div>
                {jobsInCompany.map((job, i) => (
                  <div className="mb-3 md:mb-6">
                    <a
                      href={job.url}
                      target="_blank"
                      rel="noopener"
                      className="hover:underline text-link block"
                      key={i}
                    >
                      {job.title}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
    </div>
  );
};
