import React from "react";
import { ImageBuilder } from "./global/image-builder";

export const Emoji = (props) => (
  <span
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
    style={props.style}
  >
    {props.symbol}
  </span>
);

const emojiMap = new Map();
emojiMap.set("heart", "emoji-heart.svg");
emojiMap.set("glasses", "emoji-glasses-face.svg");
emojiMap.set("chart", "emoji-chart.svg");
emojiMap.set("peeps", "emoji-peeps.svg");
emojiMap.set("eye", "emoji-eye.svg");
emojiMap.set("happy", "emoji-face-right.svg");
emojiMap.set("lock", "keyhole-emoji.svg");
emojiMap.set("avocado", "emoji-avocado.svg");
emojiMap.set("arrow-up-right", "emoji-arrow-up-right.svg");

export const ShowEmoji = ({ name, className }) =>
  emojiMap.has(name) ? (
    <ImageBuilder
      src={`/static/img/${emojiMap.get(name)}`}
      className={className}
    />
  ) : null;
