import React from "react";

export const SignalIcon = ({ strength }) => (
  <svg
    width="11"
    height="8"
    viewBox="0 0 11 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="4" width="3" height="4" rx="1" fill="currentColor" />
    <rect
      x="4"
      y="2"
      width="3"
      height="6"
      rx="1"
      fill="currentColor"
      opacity={["High", "Medium"].includes(strength) ? null : "0.3"}
    />
    <rect
      x="8"
      width="3"
      height="8"
      rx="1"
      fill="currentColor"
      opacity={"High" === strength ? null : "0.3"}
    />
  </svg>
);
