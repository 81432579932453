import React, { useContext } from "react";
import { SiteContext } from "./global/site-context";
import clientConfig from "../../client-config";
import { Button } from "./button";

const attr = clientConfig.site.websiteParam;

const ctaMap = new Map([
  [
    "uncommon",
    {
      headline: "Join Uncommon",
      summary:
        "Share expertise, get product help, and connect with community and DevRel leaders.",
      href: `${clientConfig.site.joinSlackPath}?${attr}=call-to-action-inline`,
    },
  ],
  [
    "getStarted",
    {
      headline: clientConfig.site.signupPhrase,
      summary: "Fuel your growth with a 14-day free trial.",
      href: `${clientConfig.site.signupPath}?${attr}=call-to-action-inline`,
      metric: "get-started",
    },
  ],
  [
    "bookDemo",
    {
      headline: clientConfig.site.demoPhrase,
      summary:
        "See for yourself why today's fastest-growing brands choose Common Room.",
      href: `${clientConfig.site.demoPath}?${attr}=call-to-action-inline`,
      metric: "book-demo",
    },
  ],
  [
    "contact",
    {
      headline: "Get in touch",
      summary: "Got questions? Let's chat.",
      href: `/contact/?${attr}=call-to-action-inline`,
    },
  ],
]);

export const CallToAction = ({ action }) => {
  const site = useContext(SiteContext);
  const cta = action && ctaMap.has(action) ? ctaMap.get(action) : null;

  return cta ? (
    <div>
      <a
        href={cta.href}
        className="block my-6 bg-lavender group hover:bg-purple-darker rounded-xl p-4 flex items-center border border-purple-darker"
        onClick={() => {
          site.metrics.logClick("call-to-action-click", cta.metric || action);
        }}
      >
        <div className="flex-grow">
          <p className="text-link">{cta.summary}</p>
        </div>

        <div className="flex-none ml-2 md:ml-8 lg:ml-12">
          <Button
            text={cta.headline}
            bgColor="bg-link"
            noLink={true}
            className="whitespace-nowrap"
            href="/"
          />
        </div>
      </a>
    </div>
  ) : null;
};
