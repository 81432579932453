import React from "react";
import { pageDocPath } from "../../../helpers";
import { graphql, useStaticQuery } from "gatsby";
import { cn, mapEdgesToNodes } from "../../lib/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { iconMap } from "../card/card";
import { pl } from "date-fns/locale";

export const CategoryList = () => {
  const {
    tags,
    nonVideoPosts,
    videoPosts,
    assets,
    videoPages,
    videos,
    pages,
    playbooks,
    stories,
    signals,
  } =
    useStaticQuery(graphql`
      query CategoryListQuery {
        tags: allSanityCategory(
          sort: { fields: [title], order: ASC }
          filter: { slug: { current: { ne: null } } }
        ) {
          edges {
            node {
              _id
              _type
              id
              title
              slug {
                current
              }
              status
              titlePublic
            }
          }
        }
        nonVideoPosts: allSanityPost(
          sort: { fields: [publishedAt], order: DESC }
          filter: {
            slug: { current: { ne: null } }
            publishedAt: { ne: null }
            status: { eq: "published" }
            mainVideo: { url: { eq: null } }
          }
        ) {
          edges {
            node {
              _id
              _type
              categories {
                _id
              }
            }
          }
        }
        videoPosts: allSanityPost(
          sort: { fields: [publishedAt], order: DESC }
          filter: {
            slug: { current: { ne: null } }
            publishedAt: { ne: null }
            status: { eq: "published" }
            mainVideo: { url: { ne: null } }
          }
        ) {
          edges {
            node {
              _id
              _type
              categories {
                _id
              }
            }
          }
        }
        assets: allSanityAsset(
          sort: { fields: [_createdAt], order: DESC }
          filter: {
            # keep these on two lines, linter breaks the query
            slug: { current: { ne: null } }
            status: { eq: "published" }
          }
        ) {
          edges {
            node {
              _id
              _type
              categories {
                _id
              }
            }
          }
        }
        playbooks: allSanityPlaybook(
          sort: { fields: [_createdAt], order: DESC }
          filter: {
            # keep these on two lines, linter breaks the query
            slug: { current: { ne: null } }
            status: { eq: "published" }
          }
        ) {
          edges {
            node {
              _id
              _type
              categories {
                _id
              }
            }
          }
        }
        pages: allSanityPage(
          sort: { fields: [_createdAt], order: DESC }
          filter: {
            slug: { current: { ne: null } }
            status: { eq: "published" }
            contentType: { in: ["video", "guide", "report", "playbook"] }
          }
        ) {
          edges {
            node {
              _id
              _type
              categories {
                _id
              }
            }
          }
        }
        stories: allSanityStory(
          sort: { fields: [publishedAt], order: DESC }
          filter: {
            slug: { current: { ne: null } }
            status: { eq: "published" }
          }
        ) {
          edges {
            node {
              _id
              _type
              categories {
                _id
              }
            }
          }
        }
        signals: allSanitySignal(
          sort: { fields: [_createdAt], order: DESC }
          filter: {
            slug: { current: { ne: null } }
            status: { eq: "published" }
          }
        ) {
          edges {
            node {
              _id
              _type
              categories {
                _id
              }
            }
          }
        }
        videoPages: allSanityVideo(
          filter: {
            slug: { current: { ne: null } }
            status: { eq: "published" }
          }
        ) {
          edges {
            node {
              _id
              _type
              categories {
                _id
              }
            }
          }
        }
        videos: allGoogleSpreadsheetWwWebsiteContentVideos(
          sort: { fields: [publishDate], order: DESC }
          filter: { status: { eq: "published" } }
        ) {
          edges {
            node {
              videoId
              # community
              # devRel
              # gtm
            }
          }
        }
      }
    `) || {};

  return (
    <table className="w-full text-sm">
      {mapEdgesToNodes(tags).map((tag) => (
        <tr
          key={tag._id}
          className="hover:text-black hover:bg-dark-2 border-b-6 border-transparent"
        >
          <td className="px-2">{tag.status}</td>
          <td
            className={cn(
              "text-base",
              ["published", "hidden"].includes(tag.status)
                ? "text-black"
                : "text-dark-50",
              tag.status === "published" ? "font-medium" : null
            )}
          >
            <a
              href={pageDocPath({ _type: "category", ...tag })}
              className="hover:text-link"
            >
              {tag.titlePublic || tag.title}
            </a>
          </td>
          <td>
            <Stats
              tag={tag}
              {...{
                nonVideoPosts,
                videoPosts,
                assets,
                videoPages,
                videos,
                pages,
                playbooks,
                stories,
                signals,
              }}
            />
          </td>
        </tr>
      ))}
    </table>
  );
};

const Stats = ({
  tag,
  nonVideoPosts,
  videoPosts,
  assets,
  videoPages,
  videos,
  pages,
  playbooks,
  stories,
  signals,
}) => {
  const postCount = []
    .concat(nonVideoPosts.edges)
    .concat(videoPosts.edges)
    .filter((n) => n.node.categories.find((c) => c._id === tag._id)).length;

  const guideCount = []
    .concat(assets.edges)
    .concat(pages.edges)
    .filter((n) => n.node.categories.find((c) => c._id === tag._id)).length;

  const playbookCount = playbooks.edges.filter((n) =>
    n.node.categories.find((c) => c._id === tag._id)
  ).length;

  const storyCount = stories.edges.filter((n) =>
    n.node.categories.find((c) => c._id === tag._id)
  ).length;

  const videoCount = []
    // .concat(videoPosts.edges)
    .concat(videoPages.edges)
    // @todo include bulk videos in tagged count
    // .concat(videos.edges)
    .filter((n) => n.node.categories.find((c) => c._id === tag._id)).length;

  const signalCount = []
    .concat(signals.edges)
    .filter((n) => n.node.categories.find((c) => c._id === tag._id)).length;

  const total = []
    .concat(nonVideoPosts.edges)
    .concat(videoPosts.edges)
    .concat(assets.edges)
    .concat(videoPages.edges)
    // .concat(videos.edges)
    .concat(pages.edges)
    .concat(playbooks.edges)
    .concat(stories.edges)
    .concat(signals.edges)
    .filter((n) => n.node.categories.find((c) => c._id === tag._id)).length;

  return (
    <ul className="flex gap-1">
      <li className="flex justify-end items-center pr-2 mr-2 border-r-2 border-dark-30 font-medium w-24">
        {total}
      </li>
      {!!postCount && (
        <li>
          <span
            className={cn(
              "p-1 px-1.5 rounded-md inline-block",
              `bg-${iconMap.get("post").color || "dark-20"}`
            )}
            style={dataWidth(postCount)}
          >
            {postCount}
            <FontAwesomeIcon
              size="sm"
              icon={iconMap.get("post").icon}
              className="text-dark-30 ml-1"
            />
          </span>
        </li>
      )}
      {!!playbookCount && (
        <li>
          <span
            className={cn(
              "p-1 px-1.5 rounded-md inline-block",
              `bg-${iconMap.get("playbook").color || "dark-20"}`
            )}
            style={dataWidth(playbookCount)}
          >
            {playbookCount}
            <FontAwesomeIcon
              size="sm"
              icon={iconMap.get("playbook").icon}
              className="text-dark-30 ml-1"
            />
          </span>
        </li>
      )}
      {!!storyCount && (
        <li>
          <span
            className={cn(
              "p-1 px-1.5 rounded-md inline-block",
              `bg-${iconMap.get("story").color || "dark-20"}`
            )}
            style={dataWidth(storyCount)}
          >
            {storyCount}
            <FontAwesomeIcon
              size="sm"
              icon={iconMap.get("story").icon}
              className="text-dark-30 ml-1"
            />
          </span>
        </li>
      )}
      {!!guideCount && (
        <li>
          <span
            className={cn(
              "p-1 px-1.5 rounded-md inline-block",
              `bg-${iconMap.get("asset").color || "dark-20"}`
            )}
            style={dataWidth(guideCount)}
          >
            {guideCount}
            <FontAwesomeIcon
              size="sm"
              icon={iconMap.get("asset").icon}
              className="text-dark-30 ml-1"
            />
          </span>
        </li>
      )}
      {!!signalCount && (
        <li>
          <span
            className={cn(
              "p-1 px-1.5 rounded-md inline-block",
              `bg-${iconMap.get("signal").color || "dark-20"}`
            )}
            style={dataWidth(signalCount)}
          >
            {signalCount}
            <FontAwesomeIcon
              size="sm"
              icon={iconMap.get("signal").icon}
              className="text-dark-30 ml-1"
            />
          </span>
        </li>
      )}
      {!!videoCount && (
        <li>
          <span
            className={cn(
              "p-1 px-1.5 rounded-md inline-block",
              `bg-${iconMap.get("video").color || "dark-20"}`
            )}
            style={dataWidth(videoCount)}
          >
            {videoCount}
            <FontAwesomeIcon
              size="sm"
              icon={iconMap.get("video").icon}
              className="text-dark-30 ml-1"
            />
          </span>
        </li>
      )}
    </ul>
  );
};

const dataWidth = (count) => {
  return { minWidth: 30 + count * 3.5 + "px" };
};
