// @ts-check
import React, {
  useLayoutEffect,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useInView } from "react-intersection-observer";
import { useSpringRef, useTransition, animated } from "react-spring";
import { cn } from "../../../lib/helpers";
import { LogoBox } from "../logos";

const CAROUSEL_DELAY = 3000;

/**
 * @param {*} slides
 */
export const Carousel = ({
  slides,
  className,
  delay,
  minHeight,
  companies,
  skipButtons,
}) => {
  const { ref, inView } = useInView({
    initialInView: false,
    threshold: 0.25,
  });
  const [{ index }, setPlayerState] = useState({
    index: 0,
  });
  const setIndex = useCallback(
    /**
     * @param {'next' | 'previous'} mode
     * @return {void}
     */
    (mode) => {
      setPlayerState(({ index }) => {
        const nextIndex =
          mode === "next"
            ? (index + 1) % slides.length
            : index === 0
            ? (index = slides.length - 1)
            : index - 1;
        return {
          index: nextIndex,
        };
      });
    },
    [setPlayerState]
  );
  const slide = slides[index];
  const springRef = useSpringRef();

  const transitions = useTransition(slide, {
    springRef,
    keys: null,

    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
  });

  useLayoutEffect(() => {
    springRef.start();
  }, [index]);

  useEffect(() => {
    if (inView) {
      let timer = setTimeout(() => {
        setIndex("next");
      }, delay || CAROUSEL_DELAY);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [index, inView]);

  useEffect(() => {
    if (inView && index !== 0) {
      setPlayerState({ index: 0 });
    }
  }, [inView]);

  return (
    <>
      <div
        className={cn(
          !skipButtons ? "sm:grid gap-4" : null,
          "max-w-full items-center",
          className
        )}
        style={{ gridTemplateColumns: "2rem auto 2rem" }}
        ref={ref}
      >
        <div className="relative">
          <div className="block invisible" style={{ minHeight: minHeight }}>
            {slides[0]}
          </div>
          {transitions((style, slide) => (
            <animated.div
              className="absolute top-0 left-0 right-0 bottom-0 flex-auto max-w-full max-h-full"
              style={style}
            >
              {slide}
            </animated.div>
          ))}
        </div>
        {!skipButtons && (
          <button
            className="hidden sm:block text-black flex-none w-8 h-8 opacity-20 hover:opacity-50 order-first"
            onClick={() => setIndex("previous")}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-circle-left"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="svg-inline--fa fa-chevron-circle-left fa-w-16 fa-9x"
            >
              <path
                fill="currentColor"
                d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zM142.1 273l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L226.9 256l101.6-101.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L142.1 239c-9.4 9.4-9.4 24.6 0 34z"
              ></path>
            </svg>
          </button>
        )}
        {!skipButtons && (
          <button
            className="hidden sm:block text-black flex-none w-8 h-8 opacity-20 hover:opacity-50"
            onClick={() => setIndex("next")}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-circle-right"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="32"
              height="32"
              className="svg-inline--fa fa-chevron-circle-right fa-w-16 fa-9x"
            >
              <path
                fill="currentColor"
                d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z"
              ></path>
            </svg>
          </button>
        )}
      </div>
      <div className="hidden sm:flex items-center justify-center p-4 mb-6">
        {new Array(slides.length).fill(null).map((i, dotIndex) => (
          <span
            key={dotIndex}
            className={cn(
              "h-2 w-2 block mx-1 bg-black rounded-full",
              index !== dotIndex ? "opacity-20" : null
            )}
          ></span>
        ))}
      </div>
      <Logos logos={companies} setPlayerState={setPlayerState} index={index} />
    </>
  );
};

/**
 * @param {*} logos
 */
const Logos = ({ logos, setPlayerState, index }) => (
  <div className="grid grid-cols-2 sm:mx-6 lg:mx-0 md:grid-cols-3 lg:grid-cols-4 gap-1 mt-4 sm:mt-0">
    {logos.map(
      (
        /** @type {{ title: any; mainImageMonoSolid: any; mainImage: any; }} */ company,
        /** @type {React.Key | null | undefined} */ i
      ) => (
        <div
          className="group hover:bg-dark-5 relative flex item-center justify-center mx-4 md:mx-10 cursor-pointer"
          key={i}
          onClick={() => {
            setPlayerState({ index: i });
          }}
        >
          <LogoBox
            image={company?.mainImageMonoSolid || company?.mainImage}
            alt={company.title}
            className={cn("h-full", index === i ? "opacity-0" : "opacity-100")}
          />
          <div className="absolute top-0 left-0 right-0 bottom-0 flex item-center">
            <LogoBox
              image={company?.mainImage}
              alt={company.title}
              className={cn(index === i ? "opacity-100" : "opacity-0")}
            />
          </div>
        </div>
      )
    )}
  </div>
);
