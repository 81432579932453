import React, { useContext } from "react";
import { Field, ErrorMessage } from "formik";
import { cn, isEmail } from "../lib/helpers";
import { SelectField, PickGroupField } from "./web-form-pickable";
import { SectionContext } from "./sections/context";

import * as styles from "./web-form-field.module.css";

// @todo input rounding rules are very fragile

// @todo make feature flag for this
export const FLOAT_LABELS = false;

export const FieldLabel = ({ field, touched }) => {
  const section = useContext(SectionContext);

  return (
    <label
      htmlFor={field.name}
      className={cn(
        section?.isDarkBg ? "text-white" : "text-dark-60",
        FLOAT_LABELS
          ? ["radio"].find((t) => t === field.type) || touched[field.name]
            ? "visible"
            : "invisible"
          : null,
        FLOAT_LABELS
          ? "absolute top-2 left-3 text-micro uppercase z-10"
          : "inline-block mx-2 my-2 text-sm font-semibold"
      )}
    >
      {field.title}
      {field.required ? <span className="ml-0.5">*</span> : null}
    </label>
  );
};

export const WebFormField = ({
  field,
  value,
  touched,
  setFieldTouched,
  handleChange,
  className,
  values,
  errors = {},
}) => {
  const section = useContext(SectionContext);
  const hasError = errors[field.name] && touched[field.name];

  return (
    <div className={cn(className, "relative")}>
      {field.title && <FieldLabel field={field} touched={touched} />}
      <ErrorMessage
        key={field.name}
        name={field.name}
        component="div"
        className="absolute top-0 right-0 text-sm my-2 ml-3 text-red"
      />
      <FieldComponent
        field={field}
        value={value}
        touched={touched}
        setFieldTouched={setFieldTouched}
        handleChange={handleChange}
        values={values}
        hasError={hasError}
      />
    </div>
  );
};

const FieldComponent = ({
  field,
  value,
  touched,
  setFieldTouched,
  handleChange,
  values,
  hasError,
}) => {
  const section = useContext(SectionContext);

  // be aware of -webkit-autofill css
  const basicFieldClasses = cn(
    section?.isDarkBg ? styles.inputFieldDark : styles.inputFieldLight,
    hasError
      ? "border-red"
      : section?.isDarkBg
      ? "border-light-10 focus:border-light-30"
      : "border-dark-10 focus:border-dark-30",
    section?.isDarkBg ? "text-white bg-gray-1000" : "bg-dark-5",
    FLOAT_LABELS ? "pt-5" : "py-3 text-sm",
    "p-2 md:px-3 rounded-xl w-full ring-0 focus:ring-0 outline-none shadow-none border-2"
  );

  switch (field.type) {
    case "select":
      return (
        <SelectField
          field={field}
          validate={field.required ? validRequiredPick : null}
          touched={touched}
          setFieldTouched={setFieldTouched}
          handleChange={handleChange}
          value={value}
          basicFieldClasses={basicFieldClasses}
        />
      );
    case "radio":
    case "checkbox":
      return (
        <PickGroupField
          field={field}
          validate={field.required ? validRequiredPick : null}
          touched={touched}
          setFieldTouched={setFieldTouched}
          handleChange={handleChange}
          basicFieldClasses={basicFieldClasses}
          values={values}
        />
      );
    case "textarea":
      return (
        <Field
          name={field.name}
          id={field.name}
          className={basicFieldClasses}
          placeholder={field.placeholder}
          validate={field.required ? validRequired : null}
          onChange={(e) => {
            setFieldTouched(field.name, true);
            handleChange(e);
          }}
          component="textarea"
          rows="3"
        />
      );
    default:
      return (
        <Field
          name={field.name}
          id={field.name}
          className={basicFieldClasses}
          placeholder={field.placeholder}
          validate={
            field.validate
              ? field.validate
              : field.required
              ? validRequired
              : null
          }
          onChange={(e) => {
            setFieldTouched(field.name, true);
            handleChange(e);
          }}
          type={field.type || "text"}
        />
      );
  }
};

const validRequired = (value) => (value === "" ? "Required field" : null);

// @todo make these messages appear
const validRequiredPick = (value) => (value === "" ? "Required field" : null);

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// @todo field-level validation to delay email
// export const validEmail = (value) => {
//   console.log("validate email", value);

//   // return sleep(1500).then(() => {
//   return value === ""
//     ? "Required field"
//     : !isEmail(value)
//     ? "Invalid email"
//     : null;
//   // });
// };
