import React, { useContext } from "react";
import CookieConsent from "react-cookie-consent";
import { SiteContext } from "./site-context";

import * as styles from "./gdpr.module.css";

// cookie name must match gatsby-config.js and layout.js
export const GDPR_COOKIE = "gdpr-agree";

export const Gdpr = () => {
  const site = useContext(SiteContext);
  const cookieName =
    site.isLiveProd || site.isActive("allowTrack")
      ? GDPR_COOKIE
      : "faux-gdpr-cookie";

  return (
    <CookieConsent
      location="bottom"
      buttonText="OK!"
      declineButtonText="Decline"
      cookieName={cookieName}
      buttonClasses={styles.button}
      contentClasses={styles.content}
      // disableStyles={true}
      style={{
        padding: "1rem 1rem",
        background: "#111",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "inherit",
      }}
      buttonStyle={{
        fontWeight: "500",
        borderRadius: "0.5rem",
        padding: "0.5rem 1rem",
        margin: "0",
      }}
      contentStyle={{
        margin: "0",
        flex: "inherit",
      }}
      onAccept={() => {
        site.setIsTracking(true);
      }}
    >
      <span className="inline sm:hidden pr-2">
        We use cookies to better understand our visitors.{" "}
        <a href="/privacy-policy/" target="_blank" className="text-sm">
          Read more.
        </a>
      </span>
      <span className="hidden sm:inline pr-4 text-md">
        We use cookies to better understand our visitors and personalize your
        experience.{" "}
        <a href="/privacy-policy/" target="_blank" className="text-sm">
          Read more.
        </a>
      </span>
    </CookieConsent>
  );
};
