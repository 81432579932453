import React, { useContext } from "react";
import { cn } from "../../lib/helpers";
import { SiteContext } from "../global/site-context";
import PortableText from "../portableText";
import { pageDocPath } from "../../../helpers";
import { BackToZone } from "../back-to-zone";
import { Image } from "./featured-media";
import { SlackJoin } from "./snowflake/slack-join";
import { CardCarousel } from "./card-carousel";

import * as styles from "../global/global.module.css";

const RESOURCES_TAG_ID = "76163fdd-ce53-459f-8e7a-0ff52c31eb3d";

const alignMap = new Map();
alignMap.set("centered", "text-center mx-auto");

export const PageHeader = ({ section, detailsBox, detailsBoxLayout }) => {
  const site = useContext(SiteContext);
  const path = pageDocPath(site?.doc);

  const mainImage = section.image || section.mainImage;

  // @todo use section.layout for graphic background alignment
  // const bgColor = section.background;
  // const bgImg = site?.doc.backgroundImage;
  // const bgImgMobile = site?.doc.backgroundImageMobile;

  // @todo use PageHeader.section.media rather than SectionWrapper.mainImage

  const showBackLink =
    site.isActive("resourceHub") &&
    site.doc?.categories?.find((c) => c._id === RESOURCES_TAG_ID);

  // @todo Need an insertable email subscribe widget with ID field
  const showJoinSlack = ["/join-slack/", "/community/"].includes(path);

  const pixelImage = ["jpg", "png"].includes(mainImage?.asset.extension);

  return (
    <div
      id={section?.anchor}
      className={cn(
        !site.banners.length > 0 && !showBackLink ? "pt-6" : null,
        alignMap.has(section?.layout) ? alignMap.get(section.layout) : "",
        detailsBox
          ? detailsBoxLayout ||
              "grid md:grid-cols-2 lg:grid-cols-3/5-2/5 gap-8 md:gap-24 lg:gap-32"
          : section.media || mainImage
          ? "grid md:grid-cols-2 gap-20"
          : null
      )}
    >
      <div>
        {showBackLink && (
          <BackToZone text={site.token("backToResources")} to="/resources/" />
        )}
        {section?.header && (
          <h1
            className={cn(
              "leading-none",
              section?._rawSummary
                ? "mb-2 text-lg font-semibold opacity-60"
                : "text-5xl sm:text-7xl tracking-tight font-bold"
            )}
          >
            {section.header}
          </h1>
        )}
        {section?._rawSummary && (
          <div className="text-lg">
            <PortableText blocks={section._rawSummary} />
          </div>
        )}
        {showJoinSlack && (
          <div className="mt-10 max-w-md mx-auto">
            <SlackJoin />
          </div>
        )}
      </div>
      {section.media && (
        <div className="flex justify-center items-center">{section.media}</div>
      )}
      {detailsBox && <div>{detailsBox}</div>}
      {section.layout === "mediaRight" && (
        <div className="hidden md:flex justify-end">
          <Image
            image={mainImage}
            width={
              pixelImage ? mainImage.asset.metadata.dimensions.width / 2 : 400
            }
            alt={mainImage.alt}
            className="self-center"
          />
        </div>
      )}
    </div>
  );
};

export const PageHeaderPlus = ({ section }) => (
  <PageHeader
    section={section}
    detailsBoxLayout="grid md:grid-cols-2 lg:grid-cols-1/3-2/3 gap-8 md:gap-24"
    detailsBox={
      <>
        <div
          className={cn(
            "w-1/3 absolute top-1/2 left-1/2 h-1/3 ml-12",
            styles.radialOrange
          )}
        ></div>
        <div>
          <CardCarousel />
        </div>
      </>
    }
  />
);
