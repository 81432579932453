import React from "react";

export const SectionContext = React.createContext({
  isDarkBg: false,
  container: "",
  isColumn: false,
  anchor: "",
  children: {},
  background: "",
  padding: "",
  container: "",
  name: "",
  layout: "",
  _type: "",
  noHeaders: false,
  mainImage: {},
  desktopOnly: false,
  effect: "",
  rounded: false,
  constrainImages: false,
  category: "",
});
