import React, { useContext } from "react";
import { cn, hexToRgb } from "../../lib/helpers";
import { Button } from "../button";
import { SiteContext } from "../global/site-context";
import { ArrowLeft } from "../icon/arrow-left";
import { Slack } from "../icon/slack";
import { SectionContext } from "./context";

const tailwindTypefaces = [
  "micro",
  "xs",
  "sm",
  "base",
  "lg",
  "xl",
  "2xl",
  "3xl",
  "4xl",
  "5xl",
  "6xl",
  "7xl",
  "8xl",
];

const typeWeights = [
  "thin",
  "extralight",
  "light",
  "normal",
  "base",
  "medium",
  "semibold",
  "bold",
  "extrabold",
  "black",
];

const lineHeights = ["none", "tight", "snug", "normal", "relaxed", "loose"];

const colors = {
  main: {
    primary: "#2D2D2D",
    secondary: "#6F727C",
    auxiliary: "#ABAEB8",
    link: "#723EC7",
  },
  purple: {
    "ultralight-purple": "#F8F5FB",
    "default/light-purple": "#EBE2F9",
    "darker-purple": "#D7C6F3",
    "lavender-purple": "#B099D6",
    "deep-purple": "#6D5692",
    "deeper-purple": "#7353A7",
    "purple-300": "#8F40F2", // text gradient
    "purple-400": "#8062B0", // pill
    "purple-700": "#592366", // badge
  },
  other: {
    peach: "#FFF1CC",
    // peachLight: "#ffc46c",
    // peachDark: "#A27500",
    // limeLight: "#bfffa8",
    lime: "#BFFFA8",
    // limeDark: "#19AA08",
    mint: "#BDF9DF",
    // mintDark: "#006338",
    robin: "#C3F0FF",
    lemon: "#F8F7B3",
    melon: "#F7BDBF",
  },
  highlight: {
    default: "#3F64EF",
    hover: "#3A59CB",
    press: "#334EB3",
  },
};

export const StyleGuide = () => {
  const site = useContext(SiteContext);
  const sectionContext = useContext(SectionContext);
  const headerClasses = cn(
    "uppercase mb-8",
    sectionContext.isDarkBg ? "text-yellow-200" : ""
  );

  return (
    <>
      <h3 className={headerClasses}>Typography</h3>
      <div className="grid sm:grid-cols-3 gap-8">
        <div>
          {tailwindTypefaces.map((row, i) => (
            <p className={`text-${row}`} key={i}>
              {`text-${row}`}
            </p>
          ))}
        </div>
        <div className="uppercase">
          {tailwindTypefaces.map((row, i) => (
            <p className={`text-${row}`} key={i}>
              text-{row}
            </p>
          ))}
        </div>
        <div>
          {typeWeights.map((row, i) => (
            <p className={`font-${row}`} key={i}>
              font-{row} ({i + 1}00)
            </p>
          ))}
        </div>
      </div>
      <hr className="my-12" />
      <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 mt-12">
        {lineHeights.map((row, i) => (
          <div key={i}>
            <p className={`leading-${row}`}>
              <span className={headerClasses}>leading-{row}</span>
              <br />
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </p>
          </div>
        ))}
      </div>
      <hr className="my-12" />
      <h3 className={headerClasses}>Screens</h3>
      <div className="w-full relative">
        <div className="max-w-screen-sm bg-dark-30 text-xl uppercase text-white p-2 px-3 mb-2">
          Small
        </div>
        <div className="max-w-screen-md bg-dark-30 text-xl uppercase text-white p-2 px-3 mb-2">
          Medium
        </div>
        <div className="max-w-screen-lg bg-dark-30 text-xl uppercase text-white p-2 px-3 mb-2">
          Large
        </div>
        <div className="max-w-screen-xl bg-dark-30 text-xl uppercase text-white p-2 px-3 mb-2">
          Extra-large
        </div>
      </div>

      <hr className="my-12" />
      <div>
        <h3 className={headerClasses} id="colors">
          Color Palette
        </h3>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-6 gap-3 text-micro sm:text-sm text-right font-semibold">
        {Object.entries(colors).map(([key, value]) => (
          <div
            className="col-start-1 col-span-3 md:col-span-6 grid grid-flow-col"
            key={key}
          >
            {Object.entries(value).map(([k, v]) => {
              const rgb = hexToRgb(v);
              // tailwind "purge" strips unused classes
              return (
                <div
                  className={`bg-${key}-${k} text-black p-4`}
                  style={{
                    backgroundColor: v,
                  }}
                  key={`${key}-${k}`}
                >
                  <div className="opacity-50 hover:opacity-100">
                    {k} <p>{v}</p>
                    <p>
                      {rgb.r}, {rgb.g}, {rgb.b}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>

      <hr className="my-12" />
      <div>
        <h3 className={headerClasses} id="buttons">
          Buttons
        </h3>
      </div>
      <Buttons
        colorRow={["bg-black", "bg-mint", "bg-lavender", "bg-link"]}
        bigRow={[
          "",
          "bg-black",
          "bg-mint",
          "bg-lavender",
          "bg-link",
          "bg-transparent",
        ]}
        colorSecondary="bg-black"
      />
      <Buttons
        colorRow={["bg-white", "bg-mint", "bg-lavender", "bg-link"]}
        bigRow={[
          "",
          "bg-white",
          "bg-mint",
          "bg-lavender",
          "bg-link",
          "bg-black",
        ]}
        className="bg-black"
      />

      <hr className="my-12" />
      <pre>CONTEXT: {process.env.CONTEXT}</pre>
      <pre className="whitespace-normal overflow-x-scroll">
        SITE:
        {JSON.stringify(
          Object.entries(site).filter(
            ([key]) => !["interfaceTokens", "doc", "metrics"].includes(key)
          )
        )}
      </pre>
    </>
  );
};

const Buttons = ({ className, colorRow, bigRow }) => (
  <div className={cn("p-8", className)}>
    <div className="grid grid-cols-3 md:grid-cols-6 gap-3">
      <div>
        <Button text="Basic" />
      </div>
      <div>
        <Button text="Icon" icon={<Slack color="#000" size={16} />} />
      </div>
      <div>
        <Button
          text="Icon Right"
          icon={<ArrowLeft rotate={180} />}
          iconRight={true}
        />
      </div>
    </div>
    <div className="mt-8 grid grid-cols-3 md:grid-cols-6 gap-3">
      {colorRow.map((c, i) => (
        <div key={i}>
          <Button text={c} bgColor={c} key={i} />
        </div>
      ))}
    </div>
    <div className="mt-10 grid grid-cols-3 md:grid-cols-6 gap-3">
      {bigRow.map((c, i) => (
        <div key={i}>
          <Button text="Big" isBig={true} bgColor={c} key={i} />
        </div>
      ))}
    </div>
  </div>
);
