import React, { useState, useContext, useEffect } from "react";
import { Field } from "formik";
import { cn } from "../lib/helpers";
import { Emoji } from "../components/emoji";
import { SectionContext } from "./sections/context";

import * as styles from "./web-form-field.module.css";
import { FLOAT_LABELS } from "./web-form-field";

export const SelectField = ({
  field,
  touched,
  setFieldTouched,
  handleChange,
  validate,
  basicFieldClasses,
}) => {
  const section = useContext(SectionContext);
  const [otherPicked, setOtherPicked] = useState(false);
  const textColor = touched[field.name]
    ? null
    : section?.isDarkBg
    ? "text-light-80"
    : "text-dark-80";

  return (
    <div
      className={cn(
        styles.selectWrapper,
        section.isDarkBg ? styles.selectWrapperDark : null
      )}
    >
      <Field
        name={field.name}
        id={field.name}
        as="select"
        className={cn(
          textColor,
          field.allowText ? "" : "p-2",
          section?.isDarkBg
            ? "bg-gray-1000 border-light-10 focus:text-white"
            : "bg-dark-5 border-dark-10 focus:text-black",
          FLOAT_LABELS ? "pt-5" : "py-3 text-sm",
          "border-2 rounded-xl w-full appearance-none p-2 md:px-3 focus:ring-0 outline-none"
        )}
        validate={validate}
        onChange={(e) => {
          setFieldTouched(field.name, true);
          setOtherPicked(e.target.value === "other");
          handleChange(e);
        }}
      >
        <option disabled value="">
          {field.placeholder || "Choose one"}
        </option>
        {field.options.map((option, i) => (
          <option value={option.value || option.title} key={i}>
            {option.title}
          </option>
        ))}
        {field.allowText && <option value="other">Other</option>}
      </Field>
      {field.allowText && otherPicked && (
        <Field
          name={`${field.name}_opentext`}
          id={field.name}
          className={cn(basicFieldClasses, "mt-1 mb-2")}
          placeholder={field.allowTextPlaceholder}
          onChange={(e) => {
            setFieldTouched(field.name, true);
            handleChange(e);
          }}
        />
      )}
    </div>
  );
};

export const StructuredOption = ({ option }) => {
  const section = useContext(SectionContext);
  return (
    <>
      <b className="font-semibold group-hover:font-bold group-hover:text-yellow-200">
        {option.title}
      </b>
      <span
        className={cn(
          "block mt-0.5 text-sm",
          section.isDarkBg
            ? "text-light-60 group-hover:text-yellow-200"
            : "text-dark-60"
        )}
      >
        {option.summary}
      </span>
    </>
  );
};

export const FieldGroupLabel = ({ text, className }) => {
  const section = useContext(SectionContext);
  return text ? (
    <p
      className={cn(
        "font-semibold ml-3 mb-2",
        section?.isDarkBg ? "text-white" : "",
        className
      )}
    >
      {text}
    </p>
  ) : null;
};

export const PickGroupField = ({
  field,
  touched,
  setFieldTouched,
  handleChange,
  validate,
  basicFieldClasses,
  values,
}) => {
  const section = useContext(SectionContext);
  const [otherPicked, setOtherPicked] = useState(false);

  return (
    <div className={cn(styles.radioWrapper)}>
      {field.placeholder && (
        <FieldGroupLabel text={field.placeholder} className="pb-1" />
      )}
      <ul
        className={cn(
          field.options.length > 1
            ? field.allowText
              ? styles.pickGroupAnswersWithOther
              : styles.pickGroupAnswers
            : null,
          field.fullWidthAnswers ? "grid-cols-1" : "grid-cols-2",
          "text-sm grid gap-1"
        )}
        role="group"
        aria-labelledby={field.title}
      >
        {field.options.map((option, i) => (
          <li key={i}>
            <label
              key={i}
              className={cn(
                "flex items-start h-full leading-normal px-3 py-2",
                section?.isDarkBg
                  ? "bg-light-10 group hover:bg-light-20 hover:text-black"
                  : "bg-dark-5 hover:bg-dark-10",
                field.options.length < 2 ? "rounded-xl" : null
              )}
            >
              <div>
                <Field
                  // radio + checkbox
                  type={field.type}
                  className={cn(
                    section?.isDarkBg
                      ? styles.radioInputDark
                      : styles.radioInputLight,
                    option.icon ? "hidden" : "",
                    "mr-2 mt-1 flex-grow"
                  )}
                  name={field.name}
                  value={option.value}
                  validate={validate}
                  checked={values[field.name].includes(option.value)}
                  onChange={(e) => {
                    setFieldTouched(field.name, true);
                    handleChange(e);
                  }}
                />
              </div>
              <div>
                {option.icon && (
                  <span className="text-2xl mr-2">
                    <Emoji symbol={option.icon} label={option.value} />
                  </span>
                )}
                <span>{option.title}</span>
              </div>
            </label>
          </li>
        ))}
        {field.allowText && (
          <li className="col-span-2">
            <label
              className={cn(
                "flex items-start h-full leading-normal px-3 py-2",
                section?.isDarkBg
                  ? "bg-light-10 hover:bg-light-20 text-white hover:text-yellow-200"
                  : "bg-dark-5 hover:bg-dark-10"
              )}
            >
              <Field
                // radio + checkbox
                type={field.type}
                className={cn(
                  section?.isDarkBg
                    ? styles.radioInputDark
                    : styles.radioInputLight,

                  "mr-2 mt-1"
                )}
                value="other"
                validate={validate}
                name={field.name}
                onChange={(e) => {
                  setFieldTouched(field.name, true);
                  setOtherPicked(true);
                  handleChange(e);
                }}
              />
              <span className="font-semibold">Other</span>
            </label>
          </li>
        )}
      </ul>
      {field.allowText && otherPicked && (
        <Field
          name={`${field.name}_opentext`}
          id={field.name}
          placeholder={field.allowTextPlaceholder}
          className={cn(basicFieldClasses, "mt-1")}
          // validate={validate}
          onChange={(e) => {
            setFieldTouched(field.name, true);
            handleChange(e);
          }}
        />
      )}
    </div>
  );
};
