import React, { useContext } from "react";
import { Carousel } from "./snowflake/carousel";
import { graphql, useStaticQuery } from "gatsby";
import { cn, mapEdgesToNodes } from "../../lib/helpers";
import PortableText from "../portableText";
import { Byline } from "../card/byline";
import { CardContext } from "../card/context";
import { SectionContext } from "./context";
import { ImageBuilder } from "../global/image-builder";
import { SiteContext } from "../global/site-context";

const LOGO_IMAGE_HEIGHT = 100;
const DELAY = 4000;
const MAX_QUOTES = 8;

export const quoteCarouselQuery = graphql`
  query QuoteCarouselQuery {
    default: allSanityQuotable(
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        # headline: { eq: null }
        url: { eq: null }
        categories: {
          elemMatch: { _id: { in: ["429d6b97-3b6d-4f55-9eb8-13589d5ea01a"] } }
        }
        companies: {
          elemMatch: { mainImage: { asset: { _id: { ne: null } } } }
        }
      }
      # first three are sliced off for the homepage
      limit: 11
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }

    gtmUsers: allSanityQuotable(
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        # headline: { eq: null }
        url: { eq: null }
        categories: {
          elemMatch: { _id: { in: ["6342771e-fb66-4e36-a5f6-23571a9f3941"] } }
        }
        # companies: {
        #   elemMatch: { mainImage: { asset: { _id: { ne: null } } } }
        # }
      }
      limit: 8
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }

    plg: allSanityQuotable(
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        # headline: { eq: null }
        url: { eq: null }
        categories: {
          elemMatch: { _id: { in: ["2942ebd3-0be6-44cf-afe8-1ce09a18f2c8"] } }
        }
        # companies: {
        #   elemMatch: { mainImage: { asset: { _id: { ne: null } } } }
        # }
      }
      limit: 8
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }

    sales: allSanityQuotable(
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        # headline: { eq: null }
        url: { eq: null }
        categories: {
          elemMatch: { _id: { in: ["067d08c2-833d-447f-8bed-e63211d3c644"] } }
        }
        # companies: {
        #   elemMatch: { mainImage: { asset: { _id: { ne: null } } } }
        # }
      }
      limit: 8
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }

    gtm: allSanityQuotable(
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        # headline: { eq: null }
        url: { eq: null }
        categories: {
          elemMatch: { _id: { in: ["df0bb227-e656-4be1-af6a-5b8410d9a26e"] } }
        }
        # companies: {
        #   elemMatch: { mainImage: { asset: { _id: { ne: null } } } }
        # }
      }
      limit: 8
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
  }
`;

// @todo use a common logo component
export const QuoteCarousel = ({ section }) => {
  const site = useContext(SiteContext);
  const results = useStaticQuery(quoteCarouselQuery) || {};
  const quotes =
    section.category && results[section.category]
      ? mapEdgesToNodes(results[section.category])
      : mapEdgesToNodes(results.default);

  return (
    <Carousel
      className="text-white"
      minHeight={site.isLarge ? 310 : 370}
      delay={DELAY}
      slides={quotes.slice(0, MAX_QUOTES).map((q, i) => (
        <QuoteSlide quote={q} key={i} />
      ))}
      companies={quotes.slice(0, MAX_QUOTES).map((q) => q.companies[0])}
      skipButtons={true}
    />
  );
};

const QuoteSlide = ({ quote }) => (
  <div
    className={cn(
      "p-6 py-12 lg:px-0 lg:pl-10 lg:pl-16 rounded-lg h-full grid md:grid-cols-2/3-1/3 lg:grid-cols-2"
    )}
    style={{
      background: quote.companies?.[0].color
        ? `#${quote.companies?.[0].color}`
        : "black",
    }}
  >
    <div className="flex items-center">
      <div>
        <PortableText
          blocks={quote._rawExcerpt}
          className="mb-6 sm:text-lg font-medium"
        />
        <SectionContext.Provider value={{ isDarkBg: true }}>
          <CardContext.Provider value={{ authors: quote?.authors }}>
            <Byline hideBorder={true} />
          </CardContext.Provider>
        </SectionContext.Provider>
      </div>
    </div>
    <div className="hidden md:flex justify-center items-center">
      <ImageBuilder
        image={quote.companies?.[0].mainImageMono}
        height={
          quote.companies?.[0].mainImageMono?.asset.extension === "svg"
            ? quote.companies?.[0].mainImageMono?.asset.metadata.dimensions
                .height
            : LOGO_IMAGE_HEIGHT
        }
        width={
          quote.companies?.[0].mainImageMono?.asset.extension === "svg"
            ? quote.companies?.[0].mainImageMono?.asset.metadata.dimensions
                .width
            : Math.round(
                LOGO_IMAGE_HEIGHT *
                  quote.companies?.[0].mainImageMono.asset?.metadata?.dimensions
                    ?.aspectRatio
              )
        }
        quality={100}
        className="w-full max-w-1/2 lg:max-w-1/3"
        alt={`${quote.companies?.[0].title} logo`}
      />
    </div>
  </div>
);
