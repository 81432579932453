import { useEffect, useState } from "react";
import { pageDocPath } from "../../helpers";
import { useCookies } from "react-cookie";
import { HAS_SIGNUP_CLICK_STASH } from "./client-attribution";
import { dualTypeTrue } from "./helpers";

const SCORE_STASH = "intent";
const PREVIOUS_PATH_STASH = "prevPath";
export const PAGE_VIEWS_STASH = "pageViews";

export const APP_AUTH_COOKIE = "cr-token";
const APP_ONBOARD_COOKIE = "cr-onb-token";

const pageValueMap = new Map([
  ["pricing", 8],
  ["contact", 8],
  ["demo", 8],
  ["developers", 8],
  ["join-slack", 4],
  ["integrations", 4],
  ["playbooks", 4],
]);

const SIGNUP_SCORE = 16;

export const useScore = ({ storage, doc }) => {
  const storageBackend =
    typeof window !== "undefined" ? window.sessionStorage || storage : null;
  const stashedScore = storageBackend
    ? parseInt(storageBackend.getItem(SCORE_STASH))
    : null;
  const stashedPageViews = storageBackend
    ? parseInt(storageBackend.getItem(PAGE_VIEWS_STASH))
    : null;

  const [cookies, _setCookie] = useCookies([
    APP_AUTH_COOKIE,
    APP_ONBOARD_COOKIE,
  ]);
  const [score, setScore] = useState(isNaN(stashedScore) ? 0 : stashedScore);

  useEffect(() => {
    if (typeof window === "undefined") return;

    // entering the app sets score to max
    if (
      cookies.hasOwnProperty("cr-onb-token") ||
      cookies.hasOwnProperty("cr-token") ||
      dualTypeTrue(storage.getItem(HAS_SIGNUP_CLICK_STASH))
    ) {
      setScore(SIGNUP_SCORE);
      storageBackend.setItem(SCORE_STASH, SIGNUP_SCORE);
      return SIGNUP_SCORE;
    }

    // add page score to running total, avoid double counting
    const path = pageDocPath(doc);
    const pageValue = pageValueMap.get(path.split("/")[1]);
    const newScore = score + pageValue;
    const pathMatch = storageBackend.getItem(PREVIOUS_PATH_STASH) === path;

    // avoid page reloads or rerenders
    if (!pathMatch) {
      if (pageValue) {
        // cumulative score
        setScore(newScore);
        storageBackend.setItem(SCORE_STASH, newScore);
      }
      // page hit count
      storageBackend.setItem(
        PAGE_VIEWS_STASH,
        isNaN(stashedPageViews) ? 1 : stashedPageViews + 1
      );
    }

    storageBackend.setItem(PREVIOUS_PATH_STASH, path);
  }, []);

  return score;
};
