import React, { useContext } from "react";
import { getComponentMap } from ".";
import { cn } from "../../lib/helpers";
import { PageSectionColumns } from "./column-content";
import { EmbedSection } from "./embed-section";
import { HubspotWebform } from "./hubspot-webform";
import { PageSectionSimple } from "./simple-content";
import { SectionWrapper } from "./wrapper";
import { SiteContext } from "../global/site-context";

// @todo unify rendering with page.js along with parallax

export const PageSectionComponentList = ({ content, maxColumns, layout }) => {
  const available = getComponentMap();
  const site = useContext(SiteContext);

  // obvious temp hack
  const demoPage = site?.doc?.backgroundColor === "purple";

  return (
    <div
      className={cn(
        "grid grid-cols-1 md:grid-cols-2",
        layout === "tight" ? "gap-1" : "gap-8 md:gap-12 lg:gap-20",
        layout === "1/3-2/3" ? "md:grid-cols-1/3-2/3" : null
      )}
    >
      {content.map((column, i) => (
        <div key={i}>
          {column.content.map((section, i) => {
            const title = section?.header;
            // @todo get the slug from the doc/page
            const slug = "SLUG";

            if (!section) {
              return (
                <SectionWrapper {...section} key={i}>
                  Missing Component
                </SectionWrapper>
              );
            }

            switch (section._type) {
              case "pageSectionSimple":
                return (
                  <SectionWrapper
                    {...section}
                    key={i}
                    container="full"
                    isColumn={true}
                  >
                    <div
                      className={
                        // hack for demo page
                        section.container === "narrow"
                          ? "px-6 lg:px-8 xl:px-6 opacity-60 leading-tight"
                          : null
                      }
                    >
                      <PageSectionSimple section={section} />
                    </div>
                  </SectionWrapper>
                );

              case "pageComponent":
                if (available.has(section.name)) {
                  const sectionObj = available.get(section.name);
                  const Tag = sectionObj.component;
                  return (
                    <SectionWrapper
                      {...{
                        ...section,
                        noHeaders: sectionObj.noHeaders,
                        desktopOnly:
                          sectionObj.desktopOnly ||
                          (section.name === "customerLogoWallShort" &&
                            demoPage),
                      }}
                      key={i}
                      container="full"
                      isColumn={true}
                    >
                      <Tag section={section} />
                    </SectionWrapper>
                  );
                }
                return (
                  <SectionWrapper {...section} key={i}>
                    <p>Missing Component: {section.name}</p>
                  </SectionWrapper>
                );

              case "pageSectionColumns":
                return (
                  <SectionWrapper {...section} key={i} isColumn={true}>
                    <PageSectionColumns section={section} />
                  </SectionWrapper>
                );

              case "webFormHubspot":
                return (
                  <SectionWrapper
                    {...section}
                    key={i}
                    container="full"
                    isColumn={true}
                  >
                    <HubspotWebform
                      section={{ ...section }}
                      doc={{ _type: "page", title, slug }}
                      className={section.padding !== "none" ? "mb-36" : null}
                      minHeight="24rem"
                    />
                  </SectionWrapper>
                );

              case "embedSection":
                return (
                  <SectionWrapper {...section} key={i} index={i}>
                    <EmbedSection
                      section={{ ...section }}
                      doc={{ _type: "page", title, slug }}
                    />
                  </SectionWrapper>
                );

              default:
                return JSON.stringify(section);
            }
          })}
        </div>
      ))}
    </div>
  );
};
