import React from "react";

export const PlayIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="currentColor" />
    <path
      d="M9.22461 17.3762C8.56836 17.3762 8.09961 16.8606 8.09961 16.2512V8.00122C8.09961 7.41528 8.56836 6.89966 9.22461 6.89966C9.41211 6.89966 9.62305 6.94653 9.81055 7.06372L16.5605 11.1887C16.8887 11.3762 17.0996 11.7512 17.0996 12.1262C17.0996 12.5247 16.8887 12.8997 16.5605 13.0872L9.81055 17.2122C9.62305 17.3293 9.41211 17.3762 9.22461 17.3762Z"
      fill={color || "white"}
    />
  </svg>
);
