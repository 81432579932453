// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import TextTransition, { presets } from "react-text-transition";
import { SiteContext } from "./global/site-context";
import { cn } from "../lib/helpers";

const TEXT_ROTATE_DELAY = 1200;
const TEXT_ROTATE_TIME = 2000;

/**
 * @typedef {Object} RotateText
 *
 * @prop {React.ReactNode} prefix
 * @prop {string=} className
 */

/**
 * @type {React.VFC<RotateText>}
 */
export const RotateText = ({
  prefix,
  suffix,
  words,
  colors,
  // longest,
  className,
  wordClass,
  spacing = "1.5",
}) => {
  const [index, setIndex] = useState(0);
  let intervalId = 0;

  const site = useContext(SiteContext);

  useEffect(() => {
    setTimeout(() => {
      intervalId = setInterval(
        () => setIndex((index) => index + 1),
        TEXT_ROTATE_TIME
      );
    }, TEXT_ROTATE_DELAY);

    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className={cn("inline-flex", className)}>
      {prefix ? <span>{prefix}</span> : null}
      <div
        style={{ color: colors ? colors[index % colors.length] : null }}
        className={cn(
          prefix ? `ml-${spacing}` : null,
          suffix ? `mr-${spacing}` : null,
          wordClass,
          "relative"
        )}
      >
        {site.isBrowser ? (
          <TextTransition springConfig={presets.stiff} inline={true}>
            {words[index % words.length]}
          </TextTransition>
        ) : (
          words[0]
        )}
      </div>
      {suffix ? <span>{suffix}</span> : null}
    </div>
  );
};
