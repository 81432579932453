import React from "react";
import { Logo } from "../../icon/logo";
import { LogoLink } from "./navigation";

export const PoweredBy = () => (
  <p className="text-micro sm:text-xs text-center pt-4">
    <LogoLink>
      <span className="font-medium opacity-50 relative" style={{ top: "1px" }}>
        Powered by
      </span>{" "}
      <Logo color="rgba(0,0,0,0.4)" className="inline w-28 sm:w-32" />
    </LogoLink>
  </p>
);
