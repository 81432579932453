import React, { useContext, useEffect, useRef, useState } from "react";
import { useSpringCarousel } from "react-spring-carousel";
import { cn, mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import PortableText from "../../portableText";
import { ArrowLeft, ArrowRight } from "../../icon/arrow-left";
import { SiteContext } from "../../global/site-context";
import { CONTAINER_MAP } from "../wrapper";
import Container from "../../global/container";
import { ImageBuilder } from "../../global/image-builder";
import { SLIDE_SPEED } from "../tabbed";

import * as styles from "../../global/global.module.css";

// @todo avoid duplicate code in: carousel-slides.js, tabbed.js, signal-sources.js, card-carousel.js

const query = graphql`
  query SignalSourcesQuery {
    docs: allSanitySignalSource(
      sort: { fields: [orderRank] }
      filter: { status: { in: ["published", "hidden"] } }
    ) {
      edges {
        node {
          _type
          _id
          title
          _rawExcerpt
          sourcePeer
          headline
          mainImage {
            ...SanityImage
          }
        }
      }
    }
  }
`;

const PEER_LABEL_MAP = new Map([
  [1, "1st-party"],
  [2, "2nd-party"],
  [3, "3rd-party"],
]);

export const SignalSources = ({ section }) => {
  const data = useStaticQuery(query) || {};
  const docs = data && data.docs && mapEdgesToNodes(data.docs);
  const site = useContext(SiteContext);
  const [isAutoplayed, setIsAutoplayed] = useState(true);
  const autoPlayRef = useRef();

  useEffect(() => {
    clearInterval(autoPlayRef.current);
    if (isAutoplayed) {
      autoPlayRef.current = setInterval(() => {
        slideToNextItem();
      }, SLIDE_SPEED);
    }

    return () => clearInterval(autoPlayRef.current);
  }, [isAutoplayed]);

  const {
    carouselFragment,
    slideToPrevItem,
    slideToNextItem,
    useListenToCustomEvent,
  } = useSpringCarousel({
    withLoop: true,
    gutter: site.isNotMobile ? 16 : 0,
    itemsPerSlide: site.isLarge ? 3.5 : site.isNotMobile ? 2.2 : 1.2,
    items: docs.map((doc, i) => ({
      id: doc._id,
      renderItem: (
        <div className="w-full rounded-2xl border border-dark-10 relative bg-purple-default select-none mr-6 xl:mr-0">
          <ImageBuilder
            image={doc.mainImage}
            alt={doc.title}
            width={840}
            height={450}
            draggable="false"
          />
          <div className="bg-white p-4 md:p-6 rounded-b-2xl h-40 sm:h-52 md:h-56 lg:60 overflow-hidden">
            <h4 className="font-semibold text-sm text-link mb-2">
              {doc.title}
            </h4>
            <h5 className="font-semibold mb-3">{doc.headline}</h5>
            <PortableText
              blocks={doc._rawExcerpt}
              className="text-sm md:text-base leading-tight text-secondary"
            />
          </div>
          <div className="absolute top-3 right-3">
            {PEER_LABEL_MAP.has(doc.sourcePeer) && (
              <>
                <span className="inline-block py-1 px-2 rounded-md text-xs tracking-wide text-white bg-highlight-default">
                  New
                </span>
                <span className="inline-block py-1 px-2 rounded-md text-xs tracking-wide text-white ml-2 bg-purple-deep">
                  {PEER_LABEL_MAP.get(doc.sourcePeer)}
                </span>
              </>
            )}
          </div>
        </div>
      ),
    })),
  });

  useListenToCustomEvent((event) => {
    if (event.eventName === "onDrag") {
      site.metrics.logEvent({
        category: "interactive",
        action: "carousel-drag",
        label: "signal-sources",
      });
      setIsAutoplayed(false);
    }
  });

  return (
    <div className="relative pb-10 pt-1">
      <div className="flex justify-end mb-4 mr-4 lg:mr-auto">
        {/* <a
          onClick={() => {
            if (!isAutoplayed) {
              slideToNextItem();
            }
            setIsAutoplayed(!isAutoplayed);
          }}
          className={CAROUSEL_BUTTON_CSS}
          role="button"
          aria-label="autoplay"
        >
          <ImageBuilder
            src="/static/img/icons/loader.svg"
            alt="autoplay"
            width={16}
            className={cn(
              isAutoplayed ? "animate-spin-slow" : null,
              "hover:animate-none"
            )}
          />
        </a> */}
        <a
          onClick={() => {
            slideToPrevItem();
            setIsAutoplayed(false);
          }}
          className={cn(
            "hover:bg-dark-10 hover:text-black",
            styles.carouselButton
          )}
          role="button"
          aria-label="previous"
        >
          <ArrowLeft size={16} className="text-link" />
        </a>
        <a
          onClick={() => {
            slideToNextItem();
            setIsAutoplayed(false);
          }}
          className={cn(
            "hover:bg-dark-10 hover:text-black",
            styles.carouselButton
          )}
          role="button"
          aria-label="next"
        >
          <ArrowRight size={16} className="text-link" />
        </a>
      </div>
      <div>
        <div
          className={cn(
            styles.grabbable,
            site.isLarge ? styles.fullWidthCarousel : null
          )}
        >
          {site.isLarge ? (
            <div className={styles.fullWidthCarouselSpacer}></div>
          ) : null}
          <div className="overflow-hidden">{carouselFragment}</div>
        </div>
      </div>
    </div>
  );
};
