import React from "react";
import { mapEdgesToNodes } from "../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { QuotePanels } from "./quote-panels";

const DEFAULT_QUERY_RESULTS = "gtm";

export const Quotables = ({ section }) => {
  const results = useStaticQuery(quotesGraphQL) || {};
  const desired = results[section?.category || DEFAULT_QUERY_RESULTS];
  const count = section?.limit || section?.cards;

  const docList = mapEdgesToNodes(
    desired?.edges.length
      ? desired
      : results[DEFAULT_QUERY_RESULTS].edges.length
      ? results[DEFAULT_QUERY_RESULTS]
      : []
  );
  const alteredList = []
    .concat(
      section?.contentRefs?.length > 0
        ? section.contentRefs.filter((c) => c._type === "quotable")
        : []
    )
    .concat(
      section?.quotes?.length > 0 ? section?.quotes.concat(docList) : docList
    );

  return (
    <QuotePanels
      quotes={count ? alteredList.slice(0, count) : alteredList}
      showLogo={true}
    />
  );
};

export const quotesGraphQL = graphql`
  query QuotesQuery {
    gtm: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["df0bb227-e656-4be1-af6a-5b8410d9a26e"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    community: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["83eadc53-9133-46a5-9be4-dce52fc1e2fb"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    devrel: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["acebef1a-78bc-489a-b970-1760f1c33d34"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    uncommon: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["5a3d3357-3b24-4764-bf83-01a4fb40f5c4"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    gtmUsers: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["6342771e-fb66-4e36-a5f6-23571a9f3941"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    sales: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["067d08c2-833d-447f-8bed-e63211d3c644"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    marketing: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["0ca9a6b3-3e40-42ed-8e2b-3f798a365fb4"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    homepage: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["429d6b97-3b6d-4f55-9eb8-13589d5ea01a"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    coss: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["65e914c7-6b39-487c-88b7-ae1fddc13b29"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    plg: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["2942ebd3-0be6-44cf-afe8-1ce09a18f2c8"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    abx: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["7662f4d2-9875-4dc5-be5e-91dd093375ae"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    revops: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["b0283bcf-1949-4988-ba0e-b43175d42846"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    sales: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["067d08c2-833d-447f-8bed-e63211d3c644"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    marketing: allSanityQuotable(
      limit: 3
      sort: { fields: [orderRank], order: ASC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["0ca9a6b3-3e40-42ed-8e2b-3f798a365fb4"] } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
  }
`;
