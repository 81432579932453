import React, { useContext, useEffect, useState } from "react";
import { Logo } from "../../icon/logo";
import { cn } from "../../../lib/helpers";
import { SiteContext } from "../../global/site-context";
import { HamburgerIcon } from "../../icon/hamburger";
import { CloseIcon } from "../../icon/close-x";
import { trackPath } from "../../../../helpers";
import { appendUtms, clientAttribution } from "../../../lib/client-attribution";
import { TinyDownArrow } from "../../icon/tiny-down-arrow";

// @gotcha importing these constants can cause build failtures

// @todo turn into site setting tokens
export const SIGNUP_URL = "/signup/";
export const SIGNUP_PHRASE = "Start for free";

export const LOGIN_URL = "https://app.commonroom.io";
export const LOGIN_PHRASE = "Log in";

export const DEMO_URL = "/demo/";
export const DEMO_PHRASE = "Book a demo";

export const ATTRIBUTION_PARAM = "website_attribution";

const DEFAULT_COLOR = "dark-80";

export const MAIN_MENU_FOOTER = [
  {
    label: "Product",
    to: null,
    links: [
      {
        label: "Platform Overview",
        to: "/product/customer-intelligence-platform/",
      },
      {
        label: "Signals",
        to: "/product/signals/",
      },
      {
        label: "Job Changes",
        to: "/solutions/job-change/",
        indent: true,
      },
      {
        label: "Website Visits",
        to: "/solutions/web-visitor-identification/",
        indent: true,
      },
      {
        label: "Product-led Sales",
        to: "/solutions/product-led-sales/",
        indent: true,
      },
      {
        label: "Person360™",
        to: "/product/person-360/",
      },
      {
        label: "LinkedIn Prospecting",
        to: "/solutions/chrome-extension/",
        indent: true,
      },
      {
        label: "Prospector",
        to: "/solutions/prospector/",
        indent: true,
      },
      {
        label: "Automation",
        to: "/product/automation/",
      },
      {
        label: "RoomieAI™",
        to: "/product/ai/",
        divider: true,
      },
      {
        label: "Integrations",
        to: "/integrations/",
      },
      {
        label: "Security",
        to: "/security/",
        footerOnly: true,
      },
      {
        label: "Pricing",
        to: "/pricing/",
        footerOnly: true,
      },
    ],
  },
  {
    label: "Solutions",
    to: null,
    links: [
      {
        label: "Sales",
        to: "/solutions/sales/",
        skipFooter: true,
      },
      {
        label: "Marketing",
        to: "/solutions/marketing/",
        skipFooter: true,
      },
      {
        label: "Community",
        to: "/solutions/community/",
        skipFooter: true,
      },
      {
        label: "Revenue Operations",
        to: "/solutions/revenue-operations/",
        skipFooter: true,
      },
      {
        label: "Developer Relations",
        to: "/solutions/developer-relations/",
        skipFooter: true,
        divider: true,
      },
    ],
  },
  {
    label: "Resources",
    links: [
      {
        label: "Resource Hub",
        to: "/resources/",
      },
      {
        label: "Customers",
        to: "/customers/",
        footerOnly: true,
      },
      {
        label: "Playbooks",
        to: "/playbooks/",
      },
      {
        label: "Signal Guides",
        to: "/resources/signals/",
      },
      {
        label: "Blog",
        to: "/blog/",
      },
      {
        label: "Docs",
        href: "/docs/",
      },
      {
        label: "Community",
        to: "/community/",
      },
    ],
  },
  {
    label: "Customers",
    to: "/customers/",
  },
  {
    label: "Pricing",
    to: "/pricing/",
  },
];

export const mainMenu = MAIN_MENU_FOOTER.map((l) =>
  l.links ? { ...l, links: l.links.filter((m) => !m.footerOnly) } : l
).concat([
  {
    label: LOGIN_PHRASE,
    href: LOGIN_URL,
  },
]);

/**
 * collapsed state can be managed externally or left internal
 */
export const CollapsibleMenu = ({
  color,
  className,
  isMainNav,
  isCollapsed,
  setCollapsed,
}) => {
  const site = useContext(SiteContext);
  const [isCollapsedSelf, setCollapsedSelf] = useState(true);
  const [expandedMenu, setExpandedMenu] = useState("");
  const path = trackPath(site?.doc);

  const isToggled = setCollapsed ? isCollapsed : isCollapsedSelf;

  const menuLinks = mainMenu.concat([
    {
      label: DEMO_PHRASE,
      href: `${DEMO_URL}?${ATTRIBUTION_PARAM}=collapse-nav`,
      className:
        "bg-dark-50 shadow-xl hover:bg-link transform scale-100 hover:scale-95 transition-nav w-full text-white p-2 block text-center mt-32 md:mb-0",
      attributes: {
        onClick: () => {
          site.metrics.logEvent({
            label: SIGNUP_PHRASE.toLowerCase(),
            category: "button_click",
            action: "nav-demo",
          });
        },
      },
    },
    {
      label: SIGNUP_PHRASE,
      href: `${SIGNUP_URL}?${ATTRIBUTION_PARAM}=collapse-nav--${path}`,
      className:
        "bg-link shadow-xl hover:bg-dark-80 hover:text-yellow-200 transform scale-100 hover:scale-95 transition-nav w-full text-white p-2 block text-center mb-36 md:mb-0",
      attributes: {
        onClick: () => {
          site.metrics.logEvent({
            label: SIGNUP_PHRASE.toLowerCase(),
            category: "button_click",
            action: "nav-signup",
          });
        },
      },
    },
  ]);

  useEffect(() => {
    if (!isToggled) {
      clientAttribution({
        isActive: site.isActive,
        storage: site.metrics.analytics.storage,
        referrer: document.referrer,
        callback: (urlParams) => {
          appendUtms({
            dom: document,
            urlParams,
            doc: site.doc,
            storage: site.metrics.analytics.storage,
          });
        },
      });
    }
  }, [isToggled]);

  return (
    <>
      {!isToggled && (
        <a
          className="fixed top-0 left-0 h-screen w-screen bg-white md:opacity-20 z-100"
          onClick={(e) => {
            setCollapsed ? setCollapsed(true) : setCollapsedSelf(true);
            e.stopPropagation();
          }}
        ></a>
      )}
      <div
        className={cn(
          !isCollapsed && isMainNav ? "absolute top-3 right-6" : "relative",
          "text-white transition-link cursor-pointer z-100",
          className
        )}
        onClick={(e) => {
          setCollapsed
            ? setCollapsed(!isToggled)
            : setCollapsedSelf(!isToggled);
          e.stopPropagation();
        }}
      >
        <div
          className={cn(
            "rounded-2xl w-12 h-9",
            "flex items-center justify-center text-center text-2xl",
            color ? `bg-${color}` : null,
            color === DEFAULT_COLOR ? "hover:bg-dark-80 transition-link" : "",
            site.banners.length > 0 && !isToggled
              ? "fixed right-5 top-3 md:top-11"
              : "mt-0.5"
          )}
        >
          {isToggled ? (
            <div
              className={cn(
                "flex flex-wrap justify-center content-center h-full",
                (!color && site.isDarkBg) || !isMainNav
                  ? "text-white"
                  : "text-black"
              )}
            >
              <HamburgerIcon size={color ? 14 : null} />
            </div>
          ) : (
            <div className={isMainNav ? "text-black" : "text-white"}>
              <CloseIcon />
            </div>
          )}
        </div>

        {!isToggled && (
          <>
            <span
              className="md:hidden text-gray-900 fixed left-6 z-100"
              style={{ top: 19 }}
            >
              <LogoLink>
                <Logo className="h-6" />
              </LogoLink>
            </span>
            <ul className="block z-100 fixed top-10 md:top-20 right-0 md:right-10 w-screen md:w-auto h-screen md:h-auto px-3 pt-16 pb-10 md:py-4 md:px-2 md:shadow-lg md:bg-white md:rounded-xl flex flex-col text-left text-black leading-none text-xl md:text-base font-semibold whitespace-nowrap">
              {menuLinks &&
                menuLinks.map((item, i) => {
                  const linkClass =
                    "block p-3 md:py-1.5 hover:bg-dark-5 rounded-md";

                  return item.links ? (
                    <ExpandableMenuItem
                      link={item}
                      exandedMenu={expandedMenu}
                      setExandedMenu={setExpandedMenu}
                      key={`sticky-nav-${item.label}-${i}`}
                      linkClass={linkClass}
                    />
                  ) : (
                    <li
                      className={cn(
                        i === menuLinks.length - 1
                          ? "flex-grow flex items-start"
                          : null,
                        "mb-4 md:mb-0"
                      )}
                      key={i}
                    >
                      <MenuLink link={item} linkClass={linkClass} />
                    </li>
                  );
                })}
            </ul>
          </>
        )}
      </div>
    </>
  );
};

const ExpandableMenuItem = ({
  link,
  exandedMenu,
  setExandedMenu,
  linkClass,
}) => (
  <li className="mb-4 md:mb-0">
    <span
      className={cn(
        "flex justify-between items-center p-3 md:py-1.5 hover:bg-dark-5 rounded-md relative",
        link.class
      )}
      onClick={(e) => {
        setExandedMenu(exandedMenu !== link.label ? link.label : "");
        e.stopPropagation();
      }}
    >
      {link.label}
      <span className="ml-8 mr-4">
        <TinyDownArrow rotate={exandedMenu !== link.label ? 270 : 0} />
      </span>
    </span>
    {exandedMenu === link.label && (
      <ul className="font-normal">
        {link.links.map((link, i) => (
          <li
            key={i}
            className={
              link.to || link.href ? (link.indent ? "ml-6" : "ml-4") : "ml-2"
            }
          >
            {link.to || link.href ? (
              <MenuLink link={link} linkClass={linkClass} showIndent={true} />
            ) : link.divider ? (
              <hr className="bg-dark-20 my-4" style={{ height: "2px" }} />
            ) : (
              <span className="block p-2 my-2 text-secondary whitespace-nowrap">
                {link.label}
              </span>
            )}
          </li>
        ))}
      </ul>
    )}
  </li>
);

/**
 * Force a full page reload on the homepage for A/B tests.
 */
export const LogoLink = (props) => (
  <a href="/" {...props} aria-label="Logo link to homepage"></a>
);

export const MenuLink = React.forwardRef(
  ({ link, linkClass, showIndent }, ref) => {
    return link.href ? (
      <a
        href={link.href}
        className={cn(link.className, linkClass)}
        {...link.attributes}
      >
        {showIndent && link.indent ? <span>&middot; </span> : null}
        {link.label}
      </a>
    ) : (
      <a
        href={link.to ? link.to : "#"}
        className={cn(link.className, linkClass)}
        ref={ref}
        {...link.attributes}
      >
        {showIndent && link.indent ? <span>&middot; </span> : null}
        {link.label}
        {link.links && <TinyDownArrow className="ml-1 mt-1 opacity-60" />}
      </a>
    );
  }
);
