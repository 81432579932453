import React from "react";

export const ArcadeEmbed = ({ url }) =>
  url ? (
    <div
      style={{
        position: "relative",
        paddingBottom: "calc(66% + 41px)",
        height: 0,
        width: "100%",
        marginBottom: "1rem",
      }}
    >
      <iframe
        src={url.replace("/share", "")}
        loading="lazy"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          colorScheme: "light",
        }}
      ></iframe>
    </div>
  ) : null;
