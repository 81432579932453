import React from "react";
import { cn } from "../../lib/helpers";

export const Divider = ({ className, padding }) => (
  <hr
    className={cn(
      "h-0.5 bg-dark-10",
      padding === "none" ? null : "my-8",
      className
    )}
  />
);

const LinesBgMap = new Map([
  ["graphic-1", { max: "-200px -340px", xl: "-225px -340px" }],
  ["graphic-2", { max: "-217px -897px" }],
  ["graphic-3", { max: "-80px -1510px" }],
  ["graphic-4", { max: "-150px -2098px" }],
  ["graphic-5", { max: "-160px -2680px" }],
  ["graphic-6", { max: "-210px -3280px", xl: "-211px -3280px" }],
]);

export const LinesBg = ({ section }) => (
  <>
    <div className="p-24 block xl:hidden"></div>
    <div
      className="p-24 hidden xl:block max:hidden"
      style={{
        background: "url(/static/img/lines-bg.svg)",
        backgroundPosition:
          LinesBgMap.get(section.anchor)?.xl ||
          LinesBgMap.get(section.anchor)?.max,
        backgroundSize: "1440px 4059px",
      }}
    ></div>
    <div
      className="p-24 hidden max:block"
      style={{
        background: "url(/static/img/lines-bg.svg)",
        backgroundPosition: LinesBgMap.get(section.anchor)?.max,
        backgroundSize: "1440px 4059px",
      }}
    ></div>
  </>
);
