import React, { useState } from "react";
import { cn, hashCode, mapEdgesToNodes } from "../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { pageDocPath } from "../../../helpers";
import clientConfig from "../../../client-config";
import { Image } from "./featured-media";
import { format } from "date-fns";
import { ImageBuilder } from "../global/image-builder";

const query = graphql`
  query LandingListQuery {
    companies: allSanityCompany(
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        prospect: { eq: true }
        slug: { current: { ne: null } }
        status: { in: ["published", "hidden"] }
      }
    ) {
      edges {
        node {
          _type
          title
          url
          status
          _createdAt
          slug {
            current
          }
          mainImageSolid {
            ...SanityImageSimple
          }
          prospect
        }
      }
    }

    pages: allSanityPage(
      sort: { fields: [_createdAt], order: DESC }
      filter: { contentType: { eq: "landing" } }
    ) {
      edges {
        node {
          _type
          title
          status
          _createdAt
          slug {
            current
          }
        }
      }
    }

    assets: allSanityAsset(sort: { fields: [_createdAt], order: DESC }) {
      edges {
        node {
          _type
          title
          status
          _createdAt
          slug {
            current
          }
        }
      }
    }
  }
`;

const STATUS_COLORS = new Map([
  ["published", "bg-green-600"],
  ["hidden", "bg-blue-100"],
  ["draft", "bg-orange-400"],
  ["review", "bg-yellow"],
  ["archived", "bg-purple-400"],
]);

const TYPE_COLORS = new Map([
  ["page", "bg-robin"],
  ["asset", "bg-melon"],
  ["company", "bg-lemon"],
]);

export const LandingList = ({ section }) => {
  const data = useStaticQuery(query) || {};
  const [nodes, _setNodes] = useState(
    mapEdgesToNodes(data.pages)
      .concat(mapEdgesToNodes(data.assets))
      .concat(mapEdgesToNodes(data.companies))
  );

  return (
    <ul>
      {nodes.length
        ? nodes.map((n, i) => {
            const path =
              n._type === "company"
                ? `${pageDocPath(n)}?password=${hashCode(n.title)}`
                : pageDocPath(n);
            return (
              <li
                key={i}
                className="grid grid-cols-2/3-1/3 border-b border-dark-20 pb-3 mb-3 relative"
              >
                <span className="absolute top-1 -left-7 text-secondary text-sm">
                  {i + 1}
                </span>
                <div className="flex-grow">
                  <a
                    href={path}
                    target="_blank"
                    className="hover:text-link block"
                  >
                    {n._type === "company" ? (
                      <div className="flex items-center">
                        {n.mainImageSolid ? (
                          <ImageBuilder image={n.mainImageSolid} width={40} />
                        ) : (
                          <ImageBuilder
                            src={`https://logo.clearbit.com/${n.url}`}
                            width={40}
                            className="bg-white"
                          />
                        )}
                        <p className="text-xl font-semibold ml-2">{n.title}</p>
                      </div>
                    ) : (
                      <p className="text-xl font-semibold">{n.title}</p>
                    )}
                  </a>
                  <p>
                    <input
                      type="text"
                      className="text-secondary text-sm px-2 py-1 rounded-lg bg-dark-5 mt-1 w-full"
                      defaultValue={`${clientConfig.site.host}${path}`}
                    />
                  </p>
                </div>
                <div className="flex items-center ml-2">
                  <p className="mr-2 flex-grow">
                    {format(new Date(n._createdAt), "MMMM d, yyyy")}
                    <br />
                    <span
                      className={cn(
                        "text-xs uppercase p-1 py-1.25 font-semibold text-secondary rounded-md",
                        TYPE_COLORS.get(n._type)
                      )}
                    >
                      {n._type}
                    </span>
                  </p>
                  <p
                    className={cn(
                      "py-4 px-2 rounded-md text-white font-semibold text-xs uppercase",
                      STATUS_COLORS.get(n.status)
                    )}
                  >
                    {n.status}
                  </p>
                </div>
              </li>
            );
          })
        : null}
    </ul>
  );
};
