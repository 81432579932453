import React from "react";
import { mapEdgesToNodes } from "../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { CardGrid } from "../card/card-grid";

const getDefaults = (results) => {
  return [
    ...new Map(
      []
        .concat(mapEdgesToNodes(results.sales))
        .concat(mapEdgesToNodes(results.community))
        .concat(mapEdgesToNodes(results.devrel))
        .map((p) => [p.id, p])
    ).values(),
  ].sort((a, b) => (a._createdAt < b._createdAt ? 1 : -1));
};

export const PlaybooksBlock = ({ section }) => {
  const results = useStaticQuery(query) || {};
  const docs =
    section?.category && results[section?.category]?.edges?.length
      ? mapEdgesToNodes(results[section?.category])
      : getDefaults(results);

  const count = section?.cards || 3;

  return (
    <CardGrid
      nodes={docs.length > 0 ? docs.slice(0, count) : docs}
      maxColumns={3}
      browseMoreHref={section?.showMore ? "/playbooks/" : null}
      browseMoreText={"View all playbooks"}
      hideEvents={true}
      makeThumbnail={true}
      hideDate={true}
      hideLabels={true}
      showSubTags={false}
      showExtended={false}
      hideTitles={true}
      hideType={true}
      showSummary={true}
    />
  );
};

export const query = graphql`
  query PlaybooksQuery {
    gtm: allSanityPlaybook(
      limit: 3
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["df0bb227-e656-4be1-af6a-5b8410d9a26e"] } }
        }
      }
    ) {
      edges {
        node {
          ...cardNodePlaybook
        }
      }
    }
    community: allSanityPlaybook(
      limit: 3
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["83eadc53-9133-46a5-9be4-dce52fc1e2fb"] } }
        }
      }
    ) {
      edges {
        node {
          ...cardNodePlaybook
        }
      }
    }
    devrel: allSanityPlaybook(
      limit: 3
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["acebef1a-78bc-489a-b970-1760f1c33d34"] } }
        }
      }
    ) {
      edges {
        node {
          ...cardNodePlaybook
        }
      }
    }
    sales: allSanityPlaybook(
      limit: 3
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["067d08c2-833d-447f-8bed-e63211d3c644"] } }
        }
      }
    ) {
      edges {
        node {
          ...cardNodePlaybook
        }
      }
    }
    marketing: allSanityPlaybook(
      limit: 3
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        status: { eq: "published" }
        _rawExcerpt: { ne: null }
        categories: {
          elemMatch: { _id: { in: ["0ca9a6b3-3e40-42ed-8e2b-3f798a365fb4"] } }
        }
      }
    ) {
      edges {
        node {
          ...cardNodePlaybook
        }
      }
    }
  }
`;
