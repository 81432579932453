import React from "react";

export const CloseIcon = ({ size }) => (
  <svg
    width={size || "22"}
    height={size ? "" : "18"}
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.22266 16.7783L18.779 1.22197"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M3.22266 1.22192L18.779 16.7783"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);
