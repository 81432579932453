import React, { useContext } from "react";
import { cn } from "../../lib/helpers";
import { EmailSignup } from "../global/email-signup";
import { SiteContext } from "../global/site-context";

export const Subscribe = ({
  section = {},
  showBox,
  showChannels,
  buttonIsSmall,
}) => {
  const site = useContext(SiteContext);
  const showBoxPanel = showBox || site.isBlog;

  return (
    <div
      className={cn(
        showBoxPanel
          ? "p-6 rounded-xl bg-lavender mx-auto max-w-lg"
          : "text-center"
      )}
    >
      {section.header && (
        <h3
          className={cn(
            "text-lg tracking-tight font-semibold text-center text-link",
            showBoxPanel ? "mb-6" : "mb-12"
          )}
        >
          {section.header}
        </h3>
      )}
      <div className="mx-auto max-w-md">
        <EmailSignup
          buttonClasses={section.buttonClasses || "md:w-36"}
          attribution={{
            campaignType: "page-section",
            website_attribution: "page-section",
            ...section.attribution,
          }}
          domId={section.formDomId}
          buttonText={section.buttonText}
          placeholder={
            site?.doc?.slug?.current === "uncommon"
              ? "Get the newsletter"
              : section.placeholder || null
          }
          showChannels={showChannels}
          buttonIsSmall={buttonIsSmall}
        />
      </div>
    </div>
  );
};
