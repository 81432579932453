import React from "react";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { ImageBuilder } from "../../global/image-builder";
import { Twitter } from "../../icon/twitter";
import { LinkedIn } from "../../icon/linkedin";
import { Button } from "../../button";
import { Logo } from "../../icon/logo";
import tailwindConfig from "../../../../tailwind.config";

const AVATAR_SIZE = 64;
const PARTNER_JOIN_LINK = "/partners-join/";

export const Profiles = ({ section }) => {
  const { authors } =
    useStaticQuery(graphql`
      query ProfilesectionQuery {
        authors: allSanityAuthor(
          sort: { fields: [_createdAt], order: ASC }
          filter: { isFeatured: { eq: true } }
          limit: 8
        ) {
          edges {
            node {
              name
              title
              url
              image {
                ...SanityImage
              }
              companies {
                title
              }
              twitter
              linkedin
              skills
            }
          }
        }
      }
    `) || {};

  const docs = mapEdgesToNodes(authors).concat({ bonus: true });

  return (
    <ul className="grid grid-cols-2 md:grid-cols-4 gap-0.5 overflow-hidden rounded-xl overflow-hidden">
      {docs &&
        docs.map((author, i) => (
          <li
            className="flex flex-col justify-start text-center bg-white hover:bg-light-40"
            key={i}
            style={
              author.bonus
                ? {
                    background:
                      "radial-gradient(56.12% 73% at 37.75% -3.18%, rgba(107, 255, 228, 0.25) 0%, rgba(107, 255, 228, 0.00) 100%), radial-gradient(93.84% 75.53% at 120.55% 114.42%, rgba(181, 136, 255, 0.45) 0%, rgba(114, 62, 199, 0.00) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.70) 100%), linear-gradient(0deg, rgba(211, 183, 255, 0.40) 0%, rgba(211, 183, 255, 0.40) 100%), #EBE2F9",
                  }
                : null
            }
          >
            <a
              href={
                author.bonus
                  ? PARTNER_JOIN_LINK
                  : author.linkedin
                  ? author.linkedin
                  : author.twitter
                  ? `https://twitter.com/${author.twitter}`
                  : author.url
              }
              target="_blank"
              className="p-4 mb-2 md:pb-3 md:py-8 h-full"
            >
              {!author.bonus ? (
                <>
                  <ImageBuilder
                    image={author.image}
                    alt={author.name}
                    width={AVATAR_SIZE}
                    height={AVATAR_SIZE}
                    className="rounded-full block mx-auto"
                  />
                  <p className="font-medium text-xl leading-tight mt-3">
                    {author.name}
                  </p>
                  <div className="text-xs text-purple-lavender mt-3">
                    {author.skills.map((skill, i) => (
                      <span
                        key={i}
                        className="mx-0.5 mb-1 border-2 border-purple-default py-1 px-2 rounded-md inline-block bg-white"
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                  <div className="flex justify-center mt-3">
                    {author.linkedin && (
                      <a
                        href={author.linkedin}
                        className="text-brand-linkedin bg-white border border-dark-10 rounded-md my-1 flex px-2.5 py-2"
                        target="_blank"
                      >
                        <span>
                          <LinkedIn />
                        </span>
                        <span className="ml-1 mt-0.5 font-medium text-sm">
                          LinkedIn
                        </span>
                      </a>
                    )}
                    {author.twitter && (
                      <a
                        href={author.twitter}
                        className="bg-white border border-dark-10 rounded-md my-1 flex px-2.5 py-2"
                        target="_blank"
                      >
                        <Twitter />
                        <span className="ml-1 mt-0.5 font-medium text-sm">
                          @{author.twitter}
                        </span>
                      </a>
                    )}
                  </div>
                </>
              ) : (
                <div>
                  <span className="bg-white p-3 mb-2 rounded-full inline-block">
                    <Logo
                      iconOnly={true}
                      className="w-10"
                      color={tailwindConfig.theme.colors.link}
                    />
                  </span>
                  <p className="font-semibold text-xl text-link">
                    Hey, Partner
                  </p>
                  <p className="text-link opacity-60 font-medium my-3">
                    Join the party.
                  </p>
                  <Button text="Apply to join" />
                </div>
              )}
            </a>
          </li>
        ))}
    </ul>
  );
};
