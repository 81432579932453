import React, { useEffect, useState } from "react";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { CardEvent } from "../../card/card-event";
import { CardContext } from "../../card/context";
import { isPast, isFuture } from "date-fns";
import { SectionHeader } from "../wrapper";

export const Events = ({ types }) => {
  const [future, setFuture] = useState([]);
  const [past, setPast] = useState([]);

  const { docs, recurring } =
    useStaticQuery(graphql`
      query EventsQuery {
        docs: allSanityEvent(
          limit: 16
          sort: { fields: [startDate], order: DESC }
          filter: {
            isPublished: { eq: true }
            slug: { current: { ne: null } }
            recurring: { eq: null }
          }
        ) {
          edges {
            node {
              ...cardNodeEvent
            }
          }
        }
        recurring: allSanityEvent(
          limit: 24
          sort: { fields: [startDate], order: DESC }
          filter: {
            isPublished: { eq: true }
            slug: { current: { ne: null } }
            recurring: { ne: null }
          }
        ) {
          edges {
            node {
              ...cardNodeEvent
            }
          }
        }
      }
    `) || {};

  useEffect(() => {
    const docList = docs && mapEdgesToNodes(docs);
    const recurringList = recurring && mapEdgesToNodes(recurring);
    setPast(docList.filter((e) => isPast(new Date(e.startDate))));
    setFuture(
      docList
        .filter((e) => isFuture(new Date(e.startDate)))
        .reverse()
        .concat(recurringList)
    );
  }, [docs, recurring]);

  return (
    <>
      {future.length > 0 ? (
        <ul className="grid gap-4 grid-cols-1">
          {future.map((node) => (
            <li
              key={node.id}
              className="border-b-2 last:border-b-0 pb-8 mb-4 border-gray-200"
            >
              <CardContext.Provider value={{ ...node }}>
                <CardEvent />
              </CardContext.Provider>
            </li>
          ))}
        </ul>
      ) : (
        <p className="md:ml-3">Nothing scheduled. Check back soon.</p>
      )}
      {past.length > 0 && (
        <>
          <div className="my-8 pt-4 border-t border-dark-30">
            <SectionHeader>Past Events</SectionHeader>
          </div>

          <ul className="grid gap-4 grid-cols-1">
            {past.map((node) => (
              <li
                key={node.id}
                className="border-b-2 last:border-b-0 pb-8 mb-4 border-gray-200"
              >
                <CardContext.Provider value={{ ...node }}>
                  <CardEvent />
                </CardContext.Provider>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};
