import React from "react";
import { Alert } from "./alert";

import * as styles from "./dialog.module.css";

export const Dialog = ({ title, message }) => (
  <div className={styles.successWrapper}>
    <Alert
      className={styles.successMessage}
      message={<p className="hidden ml-2 sm:inline">{message}</p>}
      title={title}
    />
  </div>
);
