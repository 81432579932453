import React from "react";

export const ClientContext = React.createContext({
  // filters
  filters: [],
  setFilters: () => false,
  menuMatch: {},
  setMenuMatch: () => false,
  titleOverride: null,
  // search
  isSearchActive: false,
  setSearchActive: () => false,
  setQuery: () => false,
  query: "",
  searchTypes: [],
  setSearchTypes: () => false,
  hasSearched: false,
  setHasSearched: () => false,
  // @todo migrate all https://github.com/common-room/www-common-room/pull/511/files
  //   isPageTop: true,
  //   setIsTracking: () => false,
  //   isBrowser: false,
  //   isNotMobile: false,
  //   isLarge: false,
  //   isAdmin: false,
});
