import React from "react";
import { mapEdgesToNodes } from "../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { Image } from "./featured-media";

export const Videos = ({ section }) => {
  const { defaults } = useStaticQuery(videosGraphQL) || {};
  const docList = mapEdgesToNodes(defaults);
  const videos = section.limit ? docList.slice(0, section.limit) : docList;

  return (
    <div className="grid sm:grid-cols-3 gap-8">
      {videos.map((video) => (
        <a
          href={video.mainVideo.url}
          className="group mx-auto"
          target="_blank"
          key={video._id}
        >
          <div key={video._id}>
            <Image
              image={video.mainImage}
              alt={video.title}
              width={384}
              className="mb-4 w-full"
              style={{ maxWidth: 384 }}
            />
            <h4 className="mb-1">{video.title}</h4>
            <p className="text-secondary text-sm">{video.duration}</p>
          </div>
        </a>
      ))}
    </div>
  );
};

export const videosGraphQL = graphql`
  query VideosQuery {
    defaults: allSanityVideo(
      limit: 3
      sort: { fields: [_createdAt], order: DESC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          _id
          # _rawExcerpt
          mainVideo {
            ...externalVideo
          }
          mainImage {
            ...SanityImage
          }
          title
          duration
        }
      }
    }
  }
`;
