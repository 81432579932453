import React from "react";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { CardContext } from "../../card/context";
import { PublishDate } from "../../card/publish-date";

export const Newsletters = () => {
  const { newsletters } =
    useStaticQuery(graphql`
      query NewslettersQuery {
        newsletters: allSanityNewsletter(
          limit: 25
          sort: { fields: [publishedAt], order: DESC }
          filter: { status: { eq: "published" }, url: { ne: null } }
        ) {
          edges {
            node {
              _id
              title
              publishedAt
              url
            }
          }
        }
      }
    `) || {};

  const NewslettersList = newsletters && mapEdgesToNodes(newsletters);

  return (
    <ul>
      {NewslettersList &&
        NewslettersList.sort().map((n) => (
          <NewsletterCard node={n} key={n._id} />
        ))}
    </ul>
  );
};

const NewsletterCard = ({ node }) => (
  <CardContext.Provider value={{ ...node }}>
    <li className="relative lg:-left-3 md:text-center lg:text-left">
      <a
        className="text-xl lg:hover:bg-gray-200 transition-colors duration-200 py-6 lg:px-6 rounded-md grid lg:flex gap-2 lg:justify-between"
        href={node.url}
        target="_blank"
        rel="noopener"
      >
        <p className="font-semibold hover:text-link">{node.title}</p>
        <div className="text-link">
          <PublishDate />
        </div>
      </a>
    </li>
  </CardContext.Provider>
);
