import React from "react";
import clientConfig from "../../client-config";
import BasePortableText from "@sanity/block-content-to-react";
import { serializers } from "./serializers";
import { cn } from "../lib/helpers";

import * as styles from "./portableText.module.css";

const PortableText = ({ blocks, className }) => (
  <div className={cn(styles.portableText, className)}>
    <BasePortableText
      blocks={blocks}
      serializers={serializers}
      {...clientConfig.sanity}
    />
  </div>
);

export default PortableText;
