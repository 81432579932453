import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { WebFormField } from "./web-form-field";
import { Dialog } from "./global/dialog";
import { Button } from "./button";
import { HiddenFields } from "./web-form-hidden-fields";
import { SiteContext } from "./global/site-context";
import { cn } from "../lib/helpers";
import { SectionContext } from "./sections/context";
import { FieldGroupLabel } from "./web-form-pickable";
import { clientAttribution } from "../lib/client-attribution";

export const STASHED_ATTRIBUTION_TIME_LIMIT_HOURS = 72;
const POST_URL = "/.netlify/functions/webforms";
const SHOW_GROUP_LABELS = false;
const DUPLIATE_ERROR_MESSAGES_NEAR_SUBMIT = false;

export const trackableFields = [
  "source",
  "campaign",
  "medium",
  "content",
  "term",
];

export const getUtmParam = ({ id, urlParams }) =>
  urlParams.has(`utm_${id}`)
    ? urlParams.get(`utm_${id}`)
    : urlParams.has(id)
    ? urlParams.get(id)
    : null;

export const WebForm = ({
  fields,
  submitButton,
  id,
  header,
  hiddenFields,
  setSubmitted,
  isSubmitted,
  success,
  messages,
  supressMessage,
}) => {
  const site = useContext(SiteContext);
  const section = useContext(SectionContext);
  const [successTrigger, setSuccessTrigger] = useState(false);
  const [showHidden, setShowHidden] = useState(false);
  const isClient = typeof navigator !== "undefined";
  const onlyOneNameField =
    fields.filter((f) => ["first_name", "last_name"].includes(f.name))
      .length === 1;

  // @todo homepage signup hack
  //const campaignType = hiddenFields.find((f) => f.name === "campaign_type");
  const isMainSignup =
    site.isSignup && fields.find((f) => f.name === "first_name");

  // field init list for open text pickable fields
  const openTextFields = fields.reduce(
    (list, field) =>
      field.allowText
        ? list.concat({
            name: `${field.name}_opentext`,
          })
        : list,
    []
  );

  const [initVals, setInitVales] = useState(
    fields
      .concat(openTextFields || [])
      .concat(hiddenFields || [])
      .reduce(
        (obj, field) =>
          Object.assign({}, obj, {
            [field.name]: field.value || "",
          }),
        {}
      )
  );

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log(values);

    try {
      const res = await fetch(POST_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (!res.ok) {
        console.log("POST RETURN ERROR", res);
        site.metrics.logError("post-response-fail-webform", res);
      } else {
        console.log("POST SUCCESS", res);
      }
    } catch (e) {
      console.log("POST ERROR", e);
      site.metrics.logError("post-try-catch-webform", e);
    } finally {
      setSubmitting(false);
      setSuccessTrigger(true);
      resetForm();
      setSubmitted(true);
    }
  };

  useEffect(() => {
    if (!site.isActive("postStashedParams")) return;

    clientAttribution({
      isActive: site.isActive,
      storage: site.metrics.analytics.storage,
      referrer: document.referrer,
      callback: (urlParams) => {
        setInitVales(
          // make key value and combine
          fields
            .concat(openTextFields)
            .concat(hiddenFields)
            .reduce(
              (obj, field) =>
                Object.assign({}, obj, {
                  [field.name]:
                    getUtmParam({ id: field.name, urlParams }) ||
                    field.value ||
                    "",
                }),
              {}
            )
        );
        if (urlParams.has("show")) {
          setShowHidden(true);
        }
      },
    });
  }, []);

  return (
    <>
      {header && (
        <h3 className="text-xl uppercase text-center mb-4">{header}</h3>
      )}
      <Formik
        initialValues={initVals}
        onSubmit={onSubmit}
        id={id}
        enableReinitialize={true}
        // validateOnChange={false}
        // validateOnBlur={true}
      >
        {({
          isSubmitting,
          values,
          touched,
          setFieldTouched,
          handleChange,
          errors,
        }) => (
          <Form>
            {isSubmitted ? (
              success
            ) : (
              <div className={isSubmitting ? "opacity-50" : "opacity-100"}>
                <div className="grid grid-cols-2 gap-3 gap-x-5">
                  {fields.map((field, i) =>
                    field.type === "label" ? (
                      <React.Fragment key={field.title + i}>
                        {SHOW_GROUP_LABELS && (
                          <FieldGroupLabel
                            text={field.title}
                            className="col-span-2"
                          />
                        )}
                      </React.Fragment>
                    ) : (
                      <WebFormField
                        className={cn(
                          field.halfWidth && !onlyOneNameField
                            ? "col-span-2 sm:col-span-1"
                            : "col-span-2",
                          field.spaced ? "mb-8" : "",
                          ["radio", "checkbox"].includes(field.type)
                            ? "mt-8"
                            : ""
                        )}
                        field={field}
                        key={field.name + i}
                        value={values[field.name]}
                        touched={touched}
                        setFieldTouched={setFieldTouched}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                      />
                    )
                  )}
                </div>
                <HiddenFields fields={hiddenFields} show={showHidden} />
                <div className="text-center mt-8">
                  <Button
                    text={submitButton.text || site.token("formDefaultButton")}
                    className="w-full"
                    isBig={true}
                    deactivated={isSubmitting}
                    metrics={{
                      action: hiddenFields.find(
                        (f) => f.name === "campaign_type"
                      ).value,
                    }}
                  />
                </div>
              </div>
            )}
            {successTrigger && !supressMessage && (
              <Dialog
                title={
                  messages?.successLabel ||
                  site.token("formDefaultSuccessLabel")
                }
                message={
                  messages?.success || site.token("formDefaultSuccessMessage")
                }
              />
            )}
            {DUPLIATE_ERROR_MESSAGES_NEAR_SUBMIT &&
              fields.map((field) =>
                field.required ? (
                  <ErrorMessage
                    key={field.name}
                    name={field.name}
                    component="div"
                    className="text-center text-xs mt-2 text-red"
                  />
                ) : null
              )}
          </Form>
        )}
      </Formik>
      <div className="text-sm text-center mt-4 mb-16">
        <a
          href={`mailto:hello@commonroom.io?subject=Website Form Feedback${
            isClient ? `&body=${"%0D%0A".repeat(3)}${navigator.userAgent}` : ""
          }`}
          className={cn(
            "hover:text-link",
            section.isDarkBg ? "text-light-40" : "text-dark-30"
          )}
          target="_blank"
        >
          {site.token("formFeedback")}
        </a>
      </div>
    </>
  );
};
