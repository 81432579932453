import React, { useContext } from "react";
import { cn, mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { pageDocPath } from "../../../../helpers";
import { Image } from "../featured-media";
import { SiteContext } from "../../global/site-context";
import { ImageBuilder } from "../../global/image-builder";

const LOGO_IMAGE_SIZE = 40;

const namePrefixMap = new Map([
  ["Common Room API", "API"],
  ["Google Sheets import", "Google Sheets"],
]);

const CRM_INTEGRATIONS = [
  "b8b32e68-81d4-4257-93b1-6db738623f0e",
  "82a26102-47d3-49e9-8f13-0c5a11744312",
];

// Mini list
export const IntegrationsList = ({ section }) => {
  const site = useContext(SiteContext);

  // change filter back to isPublished after launch
  const { docs } =
    useStaticQuery(graphql`
      query IntegrationsListSectionQuery {
        docs: allSanityIntegration(
          limit: 28
          sort: { fields: [isFeatured, company], order: ASC }
          filter: {
            isPublished: { eq: true }
            mainImageSolid: { asset: { _id: { ne: null } } }
          }
        ) {
          edges {
            node {
              _type
              _id
              company
              mainImageSolid {
                ...SanityImageSimple
              }
              status
            }
          }
        }
      }
    `) || {};

  const docList = docs ? mapEdgesToNodes(docs) : [];
  const prunedList = docList.filter((d) => !CRM_INTEGRATIONS.includes(d._id));
  const crmList = docList.filter((d) => CRM_INTEGRATIONS.includes(d._id));
  const mainImage = section.image || section.mainImage;

  return site.isNotMobile ? (
    <div style={{ maxWidth: 840 }} className="mx-auto">
      <Panel list={prunedList} fullWidth={true} />
      <div className="flex justify-center my-4">
        <ImageBuilder src="/static/img/icons/home-arrow.svg" alt="arrow down" />
      </div>
      <div className="p-8 relative border border-dark-10 rounded-3xl overflow-hidden flex justify-center">
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-black mix-blend-overlay"></div>
        <Image
          image={mainImage}
          width={774}
          className="relative"
          alt="identity data sources"
        />
      </div>
      <div className="flex justify-center my-4">
        <ImageBuilder
          src="/static/img/icons/home-arrows-two-ways.svg"
          alt="synching"
        />
      </div>
      <Panel list={crmList} />
    </div>
  ) : (
    <Panel list={docList} fullWidth={true} />
  );
};

const Panel = ({ list, fullWidth }) => (
  <div className={!fullWidth ? "flex justify-center" : null}>
    {!fullWidth && <div className="flex-grow"></div>}
    <div className="ml-4 md:ml-0 mx-auto md:p-8 md:border md:border-dark-10 md:rounded-3xl md:bg-light-50">
      <ul
        className={cn(
          "font-semibold text-sm md:text-base",
          "grid grid-cols-2 gap-6 gap-y-5 sm:gap-x-12 md:gap-x-6 lg:gap-8 lg:gap-y-5",
          list.length > 2 ? "md:grid-cols-3 lg:grid-cols-4" : null
        )}
      >
        {list.map((doc) => (
          <li key={doc._id}>
            {doc.status === "published" ? (
              <a
                href={pageDocPath(doc)}
                className="flex items-center transform transition-size scale-100 hover:scale-110"
              >
                <Tile doc={doc} />
              </a>
            ) : (
              <div className="flex items-center">
                <Tile doc={doc} />
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
    {!fullWidth && <div className="flex-grow"></div>}
  </div>
);

export const Tile = ({ doc }) => (
  <>
    <ImageBuilder
      className="block mr-2 shadow-lg rounded-md overflow-hidden w-8 sm:w-auto"
      image={doc.mainImageSolid}
      height={LOGO_IMAGE_SIZE}
      width={LOGO_IMAGE_SIZE}
      quality={100}
      alt={`${doc.company} logo`}
    />
    <span>
      {namePrefixMap.has(doc.company)
        ? namePrefixMap.get(doc.company)
        : doc.company}
    </span>
  </>
);
