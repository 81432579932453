import React from "react";

export const HamburgerIcon = ({ size }) => (
  <svg
    fill="currentColor"
    height={size || "18"}
    width={size ? "" : "24"}
    viewBox="0 0 24 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="currentColor" strokeLinecap="round" strokeWidth="2">
      <path d="m1 1h22" />
      <path d="m1 9h22" />
      <path d="m1 17h22" />
    </g>
  </svg>
);
