import React from "react";
import { ImageBuilder } from "../global/image-builder";

export const CheckMark = ({ color, className, size = 24 }) => (
  <div className={className}>
    <ImageBuilder
      className={`${color ? `bg-${color}` : ""} rounded-full`}
      src="/static/img/emoji-check.svg"
      width={size}
      height={size}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  </div>
);
