import React from "react";
import { Field } from "formik";

export const HiddenFields = ({ fields, show }) =>
  fields.map((field, i) => (
    <span key={i} className={show ? "" : "hidden"}>
      {show && (
        <label
          htmlFor={field.name}
          className="mt-3 mb-1 block text-sm lowercase"
        >
          {field.name}
        </label>
      )}
      <Field
        type="text"
        placeholder={field.name}
        name={field.name}
        key={field.name + i}
        className="bg-light-20 py-1 px-2"
      />
    </span>
  ));
