import React, { useEffect, useState } from "react";
import { cn, mapEdgesToNodes } from "../../lib/helpers";
import { graphql, Link, useStaticQuery } from "gatsby";
import { CardGrid } from "../card/card-grid";
import { matchCategories } from "./resources-list";
import { SearchPopup } from "./snowflake/search";
import { ImageBuilder } from "../global/image-builder";

const DEFAULT_LIMIT = 144;

const MENU = [
  {
    label: "All",
    slug: "",
    collapse: true,
  },
  {
    label: "Sales & marketing",
    slug: "go-to-market",
    bulkHeader: "gtm",
    emoji: "emoji-arrow-up-right.svg",
  },
  {
    label: "Community teams",
    slug: "community-teams",
    bulkHeader: "community",
    emoji: "emoji-peeps.svg",
  },
  {
    label: "DevRel",
    slug: "community/developer-relations",
    bulkHeader: "devRel",
    emoji: "emoji-avocado-vertical.svg",
  },
];

const query = graphql`
  query PlaybooksListQuery {
    playbooks: allSanityPlaybook(
      limit: 72
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        # keep these on two lines, linter breaks the query
        slug: { current: { ne: null } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNodePlaybook
        }
      }
    }
  }
`;

export const PlaybooksList = ({ section, hideFilters }) => {
  const data = useStaticQuery(query) || {};
  const playbooks = data && data.playbooks && mapEdgesToNodes(data.playbooks);
  const miniBlock = section.cards && section.cards < 4;

  const unique = [
    ...new Map(
      playbooks
        .map((p) => {
          return { ...p, publishedAt: p._createdAt };
        })

        // .concat(topics)
        .map((p) => [p.id || p.url, p])
    ).values(),
  ].sort((a, b) => (a.publishedAt < b.publishedAt ? 1 : -1));

  const [nodes, setNodes] = useState(unique);

  return (
    <>
      {/* <Search /> */}
      {!hideFilters && (
        <Filters nodes={unique} setNodes={setNodes} collapse={miniBlock} />
      )}
      {nodes.length ? (
        <CardGrid
          maxColumns={3}
          nodes={nodes.slice(0, section.cards || DEFAULT_LIMIT)}
          browseMoreHref={section.showMore ? "/playbooks/" : null}
          browseMoreText={section.showMore ? "See all playbooks" : null}
          hideEvents={true}
          hideLabels={false}
          hideDate={true}
          showSubTags={!miniBlock}
          showExtended={!miniBlock}
          hideTitles={true}
          showSummary={true}
          hideType={true}
        />
      ) : (
        <p className="text-center mt-24">Whoops. Please let us know.</p>
      )}
    </>
  );
};

const Filters = ({ nodes, setNodes, collapse }) => {
  const [filters, setFilters] = useState([]);
  const menuList = MENU;

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    setFilters(urlParams.get("filters")?.toLowerCase().split(",") || []);
  }, []);

  useEffect(() => {
    setNodes(
      !filters
        ? nodes
        : nodes.filter((n) =>
            !filters.length || filters[0] === ""
              ? true
              : matchCategories(n, MENU, filters)
          )
    );
  }, [filters]);

  return (
    <ul className="flex flex-wrap justify-center mb-12">
      {menuList.map((m, i) =>
        !(collapse && m.collapse) ? (
          !m.divider ? (
            <li className="mr-3 font-medium last:mr-0" key={i}>
              <Link
                to={`/resources/?filters=${m.slug === "" ? [] : m.slug}`}
                className={cn(
                  "py-2 px-3 rounded-lg inline-block my-2 flex items-center",
                  (m.slug !== "" && filters && filters.includes(m.slug)) ||
                    ((filters.length === 0 ||
                      (filters.length === 1 && filters[0] === "")) &&
                      m.slug === "")
                    ? "text-white bg-dark-90"
                    : "bg-dark-5 hover:bg-dark-10"
                )}
                onClick={(e) => {
                  e.preventDefault();
                  setFilters([m.slug]);
                  window.history.pushState({}, "", `?filters=${m.slug}`);
                }}
              >
                {m.emoji && (
                  <ImageBuilder
                    src={`/static/img/${m.emoji}`}
                    className="h-4 mr-1"
                  />
                )}
                {m.label}
              </Link>
            </li>
          ) : (
            <li className="flex justify-center items-center">
              <div className="ml-3 mr-6 rounded-full bg-dark-30 w-2 h-2"></div>
            </li>
          )
        ) : null
      )}
      {/* {!collapse && (
        <li>
          <SearchPopup iconOnly={true} results="playbook" />
        </li>
      )} */}
    </ul>
  );
};
