import React from "react";
import { cn } from "../../lib/helpers";

export const Alert = ({ title, message, className }) => {
  return (
    <div
      className={cn(
        className,
        "bg-teal flex shadow-xl items-center rounded-full text-center p-2 effect-appear-once"
      )}
      role="alert"
    >
      <div className="rounded-full flex items-center text-xl bg-mint px-4 py-3 text-xs font-bold mr-3">
        <span role="img" aria-label="tada">
          🎉
        </span>{" "}
        <span className="ml-2 uppercase text-sm text-teal">{title}</span>
      </div>
      <div className="mr-2 text-white text-left flex-auto">{message}</div>
    </div>
  );
};
