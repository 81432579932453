import React, { useMemo } from "react";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { JobListPanel } from "./careers";
import PortableText from "../../portableText";
import { Button } from "../../button";
import { ArrowLeft } from "../../icon/arrow-left";

const dept = "Job Board";
const jobLimit = 5;

// one from each company, ready for shared component with api data
const preprocessJobs = ({ jobList, companyList }) =>
  companyList
    .slice(0, jobLimit)
    .sort()
    .map((company) =>
      jobList.find((j) => {
        return j.company === company;
      })
    )
    .map((j) => {
      return {
        ...j,
        absolute_url: j.url,
        departments: [{ name: dept }],
      };
    });

export const Jobs = ({ section }) => {
  const { jobs } =
    useStaticQuery(graphql`
      query JobBoardSectionQuery {
        jobs: allSanityJob(
          limit: 50
          sort: { fields: [_createdAt], order: DESC }
        ) {
          edges {
            node {
              title
              company
              url
            }
          }
        }
      }
    `) || {};

  const jobList = jobs ? mapEdgesToNodes(jobs) : [];
  const companyList = [...new Set(jobList.map((j) => j.company))];
  const jobData = useMemo(() => {
    return preprocessJobs({ jobList, companyList });
  }, [jobList]);

  return (
    <>
      <div className="grid sm:grid-cols-2 gap-12">
        <div>
          <PortableText blocks={section._rawSummary} />
          <a
            href="/uncommon/jobs/"
            className="text-link hover:underline font-semibold"
          >
            {`See all ${jobList.length} listings`}
            <ArrowLeft
              size="16"
              rotate="180"
              className="relative ml-2 top-0.5"
            />
          </a>
        </div>
        {jobData && (
          <JobListPanel jobs={jobData} departments={[dept]} hideDept={true} />
        )}
      </div>
    </>
  );
};
