import React, { useState } from "react";
import { Button } from "./button";
import { Modal } from "./global/modal";

export const Popover = ({ buttonBg, buttonText, content }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="mb-6">
      <Button
        bgColor={buttonBg}
        text={buttonText || "Click here"}
        isBig={true}
        metrics={{
          action: "popover-cta",
        }}
        onClick={() => {
          setOpen(true);
        }}
      />
      {isOpen && <Modal setOpen={setOpen}>{content}</Modal>}
    </div>
  );
};
