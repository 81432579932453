import React, { useContext } from "react";
import { Link } from "gatsby";
import { CardContext } from "./context";
import { Byline } from "./byline";
import { addMinutes, isFuture, addDays, parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { pageDocPath } from "../../../helpers";
import { cn } from "../../lib/helpers";
import { Button } from "../button";
import RRule from "rrule";
import { SectionContext } from "../sections/context";
import { SiteContext } from "../global/site-context";

export const CardEvent = () => {
  const site = useContext(SiteContext);
  const section = useContext(SectionContext);
  const card = useContext(CardContext);

  const eventDate = parseISO(card.startDate);
  const rucurRule = new RRule.fromText(card.recurring);
  const recurFreq = RRule.FREQUENCIES[rucurRule.options.freq];
  const isFutureOrToday = isFuture(addDays(eventDate, 1));

  return (
    <a
      className="relative block group bg-lavenderlight rounded-2xl transition-link p-4"
      href={card.externalUrl || pageDocPath(card)}
      target={card.externalUrl ? "_blank" : null}
    >
      <div className="absolute top-0 right-0 bottom-0 left-0 bg-black opacity-0 group-hover:opacity-5 transition-opacity rounded-2xl"></div>
      <div
        className={cn(
          ["narrow", "thin"].includes(section.container) || card.maxColumns > 1
            ? "flex-col gap-4"
            : "justify-between gap-8"
        )}
      >
        <div>
          <div className="float-right">
            <Button
              className="mt-8 sm:mt-0"
              text={
                card.signupOpen && (isFutureOrToday || card.recurring)
                  ? "Register"
                  : card.mainVideo
                  ? "Watch Now"
                  : "More Details"
              }
              bgColor="bg-black"
              noLink={true}
            />
          </div>
          <h3 className="text-3xl mb-1 leading-tight tracking-tight">
            {card.title}
          </h3>
          <div className="text-dark-50">
            <p>
              {card.recurring
                ? card.recurringPublic || recurFreq
                : format(eventDate, "MMMM do, yyyy")}
            </p>
            <p>
              {`${format(eventDate, "h:mmaa")} - ${format(
                addMinutes(eventDate, card.duration),
                "h:mmaa zzz"
              )}`}
            </p>
          </div>
          {card.authors.length > 0 && (
            <div className="mt-4">
              <Byline maxAuthors={1} />
            </div>
          )}
        </div>
      </div>
    </a>
  );
};
