import React from "react";
import { ImageBuilder } from "../../global/image-builder";

// @todo site settings
export const SPOTIFY_SHOW_URL =
  "https://open.spotify.com/show/2lqQ5xDkwo56hGxDRIwbIU?si=O2kWLpItRn-9fkwHQz5iHg";
export const APPLE_SHOW_URL =
  "https://podcasts.apple.com/us/podcast/go-to-market-mavericks/id1774317155";

export const Podcast = () => (
  <>
    <iframe
      height="450"
      width="100%"
      title="Media player"
      src="https://embed.podcasts.apple.com/us/podcast/go-to-market-mavericks/id1774317155?itscg=30200&amp;itsct=podcast_box_player&amp;ls=1&amp;mttnsubad=1774317155&amp;theme=light"
      id="embedPlayer"
      style={{
        border: 0,
        borderRadius: 12,
        width: "100%",
        height: 450,
        maxWidth: 660,
        paddingTop: 16,
        marginBottom: 16,
      }}
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
      allow="autoplay *; encrypted-media *; clipboard-write"
    ></iframe>
    <a
      href={SPOTIFY_SHOW_URL}
      target="_blank"
      className="inline-block transform hover:scale-105 transition-transform"
    >
      <ImageBuilder
        src="/static/img/spotify-podcast-wht-blk.svg"
        className="w-48"
      />
    </a>
  </>
);
