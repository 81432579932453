import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { CardGrid } from "../../card/card-grid";

const query = graphql`
  query CompanyNewsQuery {
    docs: allSanityPost(
      limit: 24
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, isPublished: { eq: true } }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
  }
`;

export const CompanyNews = () => {
  const { docs } = useStaticQuery(query) || {};

  return docs ? (
    <CardGrid
      maxColumns={1}
      nodes={mapEdgesToNodes(docs)}
      browseMoreHref="/resources/blog/"
    />
  ) : null;
};
