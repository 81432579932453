import React from "react";

export const LinkedIn = ({ color, size }) => (
  <svg
    width={size || "20"}
    height={size ? "" : "19"}
    viewBox="0 0 20 19"
    fill={color || "currentColor"}
    xmlns="http://www.w3.org/2000/svg"
    alt="LinkedIn"
  >
    <path d="M17.5 0.75H2.46094C1.79688 0.75 1.25 1.33594 1.25 2.03906V17C1.25 17.7031 1.79688 18.25 2.46094 18.25H17.5C18.1641 18.25 18.75 17.7031 18.75 17V2.03906C18.75 1.33594 18.1641 0.75 17.5 0.75ZM6.52344 15.75H3.94531V7.42969H6.52344V15.75ZM5.23438 6.25781C4.375 6.25781 3.71094 5.59375 3.71094 4.77344C3.71094 3.95312 4.375 3.25 5.23438 3.25C6.05469 3.25 6.71875 3.95312 6.71875 4.77344C6.71875 5.59375 6.05469 6.25781 5.23438 6.25781ZM16.25 15.75H13.6328V11.6875C13.6328 10.75 13.6328 9.5 12.3047 9.5C10.9375 9.5 10.7422 10.5547 10.7422 11.6484V15.75H8.16406V7.42969H10.625V8.5625H10.6641C11.0156 7.89844 11.875 7.19531 13.125 7.19531C15.7422 7.19531 16.25 8.95312 16.25 11.1797V15.75Z" />
  </svg>
);
