import React, { useContext, useEffect, useState } from "react";
import { SiteContext } from "../global/site-context";
import { WebForm } from "../web-form";
import { textToSlug } from "../../../helpers";
import PortableText from "../portableText";
import { StructuredOption } from "../web-form-pickable";
import { validEmail } from "../web-form-field";

// @todo put options into the schema to share with attribution
// @todo put field configs into a non-section component
// @todo activate or deactivate fields as a config

const SUBSCRIBE_CHECKED_BY_DEFAULT = true;

const makeOptions = (list) =>
  list.map((r) => {
    return { title: r, value: textToSlug(r) };
  });

const makeStructuredOptions = (options) => {
  return options.map((o) => {
    return {
      value: o.value,
      title: <StructuredOption option={o} />,
    };
  });
};

export const Registration = ({
  section,
  doc,
  success,
  setSubmitted,
  isSubmitted,
  supressMessage,
}) => {
  const site = useContext(SiteContext);
  const [isSub, setSub] = useState();

  useEffect(() => {
    if (isSub) {
      window.scrollTo(0, 0);
    }
  }, [isSub]);

  return (
    <>
      <WebForm
        messages={{
          successLabel: site.token("formRegSuccessLabel"),
          success: site.token("formRegSuccessMessage"),
        }}
        submitButton={{ text: section.buttonText }}
        fields={makeSignupFields({ site, section })}
        hiddenFields={makeHiddenFields(section)}
        setSubmitted={setSubmitted || setSub}
        isSubmitted={setSubmitted ? isSubmitted : isSub}
        success={
          success || (
            <div className="text-center">
              <PortableText blocks={section._rawSuccess} />
            </div>
          )
        }
        supressMessage={supressMessage}
      />
    </>
  );
};

const sizeOptions = [
  { title: "< 100" },
  { title: "100 - 500" },
  { title: "500 - 1000" },
  { title: "1000 - 5k" },
  { title: "5k - 10k" },
  { title: "10k - 50k" },
  { title: "50k+" },
  { title: "I have no idea" },
];

const topicOptions = [
  {
    title: "Build a community from scratch",
    icon: "🏗",
    value: "build",
  },
  {
    title: "Strengthen my community management skill set",
    icon: "📚",
    value: "manage",
  },
  {
    title: "Grow a community faster",
    icon: "🚀",
    value: "grow",
  },
  {
    title: "Enable community engagement and participation",
    icon: "👋",
    value: "participate",
  },
  {
    title: "Identify and foster advocates",
    icon: "🥳",
    value: "advocates",
  },
  {
    title: "Measure the business impact of my community",
    icon: "📊",
    value: "measure",
  },
  {
    title: "Get internal resources to run my community",
    icon: "💰",
    value: "funding",
  },
  {
    title: " Extend the reach of my community",
    icon: "🗺",
    value: "broaden",
  },
];

const tools = [
  "Slack",
  "GitHub",
  "Discord",
  "Twitter",
  "Discourse",
  "ZenDesk",
  "Insided",
  "Bevy",
  "Meetup",
  "Reddit",
  "Facebook",
  "Stack Exchange",
  "Stack Overflow",
  "Intercom",
  "LinkedIn",
  "YouTube",
  "Medium",
];

const departments = [
  "Customer Success",
  "Engineering",
  "Executive",
  "Marketing",
  "Sales",
  "Support",
];

const industries = [
  "Communications",
  "Software",
  "Services",
  "Machine Learning",
];

const orgSizeOptions = [
  { title: "Small (under 500)", value: "small" },
  { title: "Medium (under 1500)", value: "medium" },
  { title: "Enterprise (under 5000)", value: "large" },
  { title: "Mega (over 5000)", value: "mega" },
];

const jobOptions = [
  { title: "Business Development" },
  { title: "Community Management & Support" },
  { title: "Customer Success" },
  { title: "Developer Relations" },
  { title: "Marketing" },
  { title: "Product Development" },
  { title: "Sales" },
  { title: "User Research" },
];

const whereOptions = makeStructuredOptions([
  {
    title: "Chat",
    summary: "Slack, Discord",
    value: "chat",
  },
  {
    title: "Forums",
    summary: "Discourse, StackOverflow",
    value: "forums",
  },
  {
    title: "Social media",
    summary: "Twitter, Facebook",
    value: "social",
  },
  {
    title: "Code repos",
    summary: "GitHub",
    value: "code",
  },
  {
    title: "Events",
    summary: "Bevy, Meetup",
    value: "events",
  },
  {
    title: "Learning",
    summary: "Skilljar",
    value: "learning",
  },
]);

const goalsOptions = makeStructuredOptions([
  {
    title: "Amplify content",
    summary: "Uncover helpful content made by your community",
    value: "amplify-content",
  },
  {
    title: "Increase engagement",
    summary: "Strengthen relationships with community members",
    value: "increase-engagement",
  },
  {
    title: "Find champions",
    summary: "Discover and reward product champions",
    value: "find-champions",
  },
  {
    title: "Get product feedback",
    summary: "Learn from users faster than ever",
    value: "product-feedback",
  },
  {
    title: "Understand customer needs",
    summary: "Hear what customers think and find the knowledge gaps",
    value: "customer-needs",
  },
  {
    title: "Measure and report",
    summary: "Analyze the impact of your community",
    value: "measure-report",
  },
  {
    title: "Route visibility",
    summary:
      "Get community needs to the right teams (sales, support, product, customer success, etc)",
    value: "route-visibility",
  },
  {
    title: "Triage bugs",
    summary: "Solve problems faster and facilitate product support",
    value: "triage-bugs",
  },
]);

const teamSizeOptions = [
  { title: "1 - 2 🙌", value: "1-2" },
  { title: "3 - 4", value: "3-4" },
  { title: "5 - 10", value: "5-10" },
  { title: "11 - 20", value: "11-20" },
  { title: "21+ heyo!", value: "21+" },
];

const stageOptions = makeStructuredOptions([
  {
    title: "Seeding",
    summary: "Just starting to invest",
    value: "seeding",
  },
  {
    title: "Growing",
    summary: "Driving engagement, amplifying community-authored content",
    value: "growing",
  },
  {
    title: "Sustaining",
    summary: "Facilitating a healthy, active, peer-to-peer community",
    value: "sustaining",
  },
  {
    title: "Proving impact",
    summary:
      "Tying community to attribution, retention, growth, and product sentiment",
    value: "proving-impact",
  },
]);

const makeSignupFields = ({ site, section }) => {
  const sendgridListsOptional =
    section.sendgridListsOptional || section.campaignType === "event"
      ? "uncommon"
      : null;

  const chosenFields =
    section.webformFields && section.webformFields.length
      ? section.webformFields
      : section.campaignType === "event"
      ? [
          "first_name",
          "email",
          "organization",
          "organization_role",
          "team_size",
          // "stage",
          "anything_else",
        ]
      : [
          "you",
          "first_name",
          "last_name",
          "email",
          "org",
          "organization",
          "organization_role",
          // "department",
          // "industry",
          // "org_size",
          "team_size",
          "stage",
          // "community_size",
          // "tools_used",
          // "topics",
          "anything_else",
        ];

  const allFields = [
    {
      type: "label",
      title: "About You",
      name: "you",
    },
    {
      name: "first_name",
      title: "First name",
      placeholder: site.token("formFieldFirstName"),
      required: true,
      halfWidth: true,
    },
    {
      name: "last_name",
      title: "Last name",
      placeholder: site.token("formFieldLastName"),
      required: true,
      halfWidth: true,
    },
    {
      name: "email",
      title: "Work email",
      placeholder: site.token("formFieldEmail"),
      type: "email",
      required: true,
      validate: validEmail,
      // spaced: true,
    },
    {
      type: "label",
      title: "About your organization",
      name: "org",
    },
    {
      name: "organization",
      title: "Organization",
      placeholder: site.token("formFieldOrg"),
      required: true,
    },
    {
      name: "organization_role",
      title: site.token("formFieldRoleTitle"),
      placeholder: site.token("formFieldRolePlaceholder"),
      type: "select",
      // see: web/src/functions/sendgrid-forms for value swapping
      allowText: true,
      allowTextPlaceholder: site.token("formFieldRoleOther"),
      options: jobOptions,
      required: true,
    },
    {
      name: "department",
      title: "Department",
      placeholder: "Department",
      type: "select",
      // see: web/src/functions/sendgrid-forms for value swapping
      allowText: true,
      allowTextPlaceholder: "What team is it?",
      options: makeOptions(departments),
      // required: true,
    },
    {
      name: "org_size",
      title: "Organization Size",
      placeholder: "Size of your organization",
      type: "select",
      options: orgSizeOptions,
      // required: true,
    },
    {
      name: "team_size",
      title: site.token("formFieldTeamSizeTitle"),
      placeholder: site.token("formFieldTeamSizePlaceholder"),
      type: "select",
      options: teamSizeOptions,
      required: true,
    },
    {
      name: "community_size",
      title: site.token("formFieldSizeTitle"),
      placeholder: site.token("formFieldSizePlaceholder"),
      type: "select",
      options: sizeOptions,
      // required: true,
    },
    {
      name: "industry",
      title: "Industry",
      placeholder: "Industry focus",
      type: "select",
      allowText: true,
      options: makeOptions(industries),
      // required: true,
    },
    {
      name: "stage",
      placeholder: site.token("formFieldStage"),
      type: "radio",
      options: stageOptions,
      required: true,
    },
    {
      name: "tools_used",
      placeholder: site.token("formFieldTools"),
      type: "checkbox",
      allowText: true,
      allowTextPlaceholder: site.token("formFieldToolsOther"),
      options: whereOptions,
      required: true,
    },
    {
      name: "topics",
      placeholder: site.token("formFieldTopics"),
      type: "checkbox",
      options: goalsOptions,
      required: true,
    },
    {
      name: "address",
      title: "Address",
      placeholder: "Mailing address",
      type: "textarea",
    },
    {
      name: "anything_else",
      title: site.token("formFieldOpenTextTitle"),
      placeholder:
        section.formFieldOpenTextPlaceholder ||
        site.token("formFieldOpenTextPlaceholder"),
      type: "textarea",
    },
  ].concat([
    sendgridListsOptional
      ? {
          name: "lists_optional",
          placeholder: site.token("formFieldListsOptional"),
          type: "checkbox",
          fullWidthAnswers: true,
          persist: true,
          value: SUBSCRIBE_CHECKED_BY_DEFAULT ? [sendgridListsOptional] : null,
          options: [
            {
              title: "Yes please",
              value: sendgridListsOptional,
            },
            // @todo multi-list opt-in/out
            // {
            //   title: "Release Notes",
            //   value: "releases",
            // },
          ],
        }
      : {},
  ]);

  return allFields.filter(
    (f) =>
      f.type === "label" || f.persist || chosenFields.find((c) => f.name === c)
  );
};

const makeHiddenFields = (section) =>
  [].concat(section.hiddenFields || []).concat(
    [
      {
        name: "lists",
        value: section.lists
          ? section.lists.join(",").concat(section?.sendgridListId || [])
          : "",
      },
      {
        name: "campaign_type",
        value: section.campaignType || "",
      },
      {
        name: "campaign",
        value: section.campaign || "",
      },
      {
        name: "content",
        value: "",
      },
      {
        name: "medium",
        value: "",
      },
      {
        name: "source",
        value: "",
      },
      {
        name: "alertEmail",
        value: section.alertEmail,
      },
    ].concat(
      !section.hiddenFields ||
        !section.hiddenFields.some((f) => f.name === "sendgridTemplateId")
        ? [
            {
              name: "sendgridTemplateId",
              value: section.sendgridTemplateId,
            },
          ]
        : []
    )
  );
