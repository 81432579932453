import React, { useContext, useEffect, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { clientAttribution } from "../../lib/client-attribution";
import { SiteContext } from "../global/site-context";
import { trackableFields, getUtmParam } from "../web-form";
import { toFirstCapital } from "../../lib/helpers";

// var iframedoc = document.getElementById('hs-form-iframe-0').contentWindow.document;
// iframedoc.getElementsByTagName('form')[0].submit();

export const EmbedSection = ({ section = {}, doc }) => {
  const site = useContext(SiteContext);
  const [utms, setUtms] = useState({});

  useCalendlyEventListener({
    onEventScheduled: (e) => console.log("CALENDLY EVENT", e.data),
  });

  useEffect(() => {
    if (site.isBrowser) {
      clientAttribution({
        isActive: site.isActive,
        storage: site.metrics.analytics.storage,
        referrer: document.referrer,
        callback: (urlParams, storage) => {
          setUtms(
            trackableFields.reduce(
              (obj, field) =>
                Object.assign({}, obj, {
                  [`utm${toFirstCapital(field)}`]:
                    getUtmParam({ id: field, urlParams }) || "",
                }),
              {}
            )
          );
        },
      });
    }
  }, []);

  return section.type === "calendly" ? (
    <div className="my-0 md:my-12 xl:my-0 rounded-xl bg-white border border-dark-10 overflow-hidden">
      <InlineWidget
        url={`${section.embedId}?hide_gdpr_banner=1`}
        utm={utms}
        pageSettings={{
          hideEventTypeDetails: true,
          hideLandingPageDetails: true,
          primaryColor: "8062B0",
          //   textColor: "4d5055",
        }}
        styles={{ height: "750px" }}
        //   prefill={{
        //     email: 'test@test.com',
        //     firstName: 'Jon',
        //     lastName: 'Snow',
        //     name: 'Jon Snow',
        //     customAnswers: {
        //       a1: 'a1',
        //     },
        //   }}
      />
    </div>
  ) : section.type === "navattic" ? (
    <iframe
      src={`${section.embedId}?hide_gdpr_banner=1`}
      className="w-full h-full border-0 rounded-xl"
      allow="fullscreen"
      style={{ minHeight: "850px" }}
    ></iframe>
  ) : (
    "Missing type"
  );
};
