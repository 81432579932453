import React, { useContext } from "react";
import { cn } from "../lib/helpers";
import { SiteContext } from "./global/site-context";
import { SectionContext } from "./sections/context";
import { textToSlug } from "../../helpers";

/**
 * @type {React.VFC<{
 *   text: React.ReactNode,
 *   metrics?: {
 *     action?: string
 *     label?: string
 *   }
 *   icon: React.ReactNode
 *   iconRight?: boolean
 *   href?: string
 *   isBig?: boolean
 *   className?: string
 *   attributes?: {
 *     target: string
 *   }
 *  onClick?: React.MouseEventHandler<HTMLButtonElement>
 * }>}
 */
export const Button = ({
  text,
  deactivated,
  className,
  bgColor,
  onClick,
  isSecondary,
  isSmall,
  isBig,
  href,
  metrics,
  noLink,
  attributes,
  icon,
  iconRight,
  isJumbo,
}) => {
  const site = useContext(SiteContext);
  const section = useContext(SectionContext);

  const background =
    bgColor === "bg-black" ? "bg-primary" : bgColor ? bgColor : "bg-link";

  const label = typeof text === "object" ? text[0] : text;

  const paddingY =
    bgColor === "bg-transparent"
      ? isBig
        ? "py-2"
        : isSmall
        ? "py-1.5"
        : "py-2.5"
      : isBig
      ? "py-2.5"
      : isSmall
      ? "py-2"
      : "py-3";

  const classes = cn(
    "hover:opacity-80 inline-block text-center",
    "transition-colors duration-300 focus:outline-none",
    "rounded-lg",
    "px-4",
    className,
    isBig ? "text-base font-semibold shadow-lg" : "text-sm font-medium",
    paddingY,
    background,
    isSecondary ? "opacity-50" : null,
    ![
      "bg-black",
      "bg-primary",
      "bg-magenta",
      "bg-link",
      "bg-blue-600",
      "bg-green-200",
      "bg-light-10",
      "bg-brand-twitter",
      "bg-brand-linkedin",
      "bg-brand-slack",
    ].includes(background)
      ? bgColor === "bg-white"
        ? "text-link"
        : "text-black"
      : "text-white",
    section.isDarkBg && bgColor === "bg-black"
      ? "border border-light-20 hover:border-light-30"
      : bgColor === "bg-transparent"
      ? "border-2 border-black"
      : !isBig
      ? "border-dark-10"
      : null,
    bgColor === "bg-gray-200" ? "hover:text-link" : null,
    bgColor === "bg-light-10" ? "hover:bg-light-20" : null,
    isJumbo ? "border-4 border-dark-10 xs:shadow-none" : null
  );

  const onClickWithTracking = (e) => {
    if (onClick) {
      onClick(e);
    }

    site.metrics.logEvent({
      category: "button_click",
      action: metrics?.action || "default",
      label: metrics?.label
        ? metrics?.label
        : label
        ? textToSlug(label)
        : "default",
    });
  };

  return href ? (
    <>
      {noLink ? (
        <span className={classes} onClick={onClickWithTracking}>
          <InnerContent {...{ label, icon, iconRight }} />
        </span>
      ) : (
        <>
          {attributes && attributes.target === "_blank" ? (
            <a href={href} {...attributes} className={classes}>
              <InnerContent
                {...{ label, icon, iconRight, onClick: onClickWithTracking }}
              />
            </a>
          ) : (
            <a
              href={href}
              {...attributes}
              className={classes}
              onClick={onClickWithTracking}
            >
              <InnerContent {...{ label, icon, iconRight }} />
            </a>
          )}
        </>
      )}
    </>
  ) : (
    <button
      type="submit"
      disabled={deactivated}
      className={classes}
      onClick={onClickWithTracking}
    >
      <InnerContent {...{ label, icon, iconRight }} />
    </button>
  );
};

const InnerContent = ({ onClick, label, icon, iconRight }) =>
  icon || onClick ? (
    <span className="flex items-center justify-center" onClick={onClick}>
      {iconRight ? (
        <>
          <span className="mr-1">{label}</span>
          {icon}
        </>
      ) : (
        <>
          <span className="mr-1">{icon}</span>
          {label}
        </>
      )}
    </span>
  ) : (
    label
  );
