import React from "react";
import { Button } from "./button";
import { ArrowLeft } from "./icon/arrow-left";
import { cn } from "../lib/helpers";
import { pageDocPath } from "../../helpers";

const isKnownDomain = (domain) => {
  // https://www.commonroom.io
  // https://wwwcommonroom.gtsb.io
  // https://preview-wwwcommonroom.gtsb.io
  // https://www-common-room.netlify.app
  // https://branch--www-common-room.netlify.app

  return domain.match(
    /www\.commonroom\.io|\.gtsb\.io|\.netlify\.app|localhost:8000|localhost:8888$/
  );
};

// @todo should work opposite and check for http
const isInternalUrl = (url) => {
  return url && url.match(/^\/|#/);
};

// const isEmailUrl = (url) => {
//   return url && url.match(/^mailto:/);
// };

export const BUTTON_BG_MAP = new Map([
  ["buttonBlack", "bg-black"],
  ["buttonLink", "bg-link"],
  ["buttonBlue", "bg-link"],
  ["buttonGreen", "bg-green-600"],
  ["buttonDarkGreen", "bg-green-200"],
  ["buttonDarkBlue", "bg-blue-600"],
  ["buttonWhite", "bg-white"],
  ["buttonBlackOutline", "bg-transparent"],
]);

export const makeButton = ({ mark, children, location, attributes }) => {
  const marginClass =
    mark?.margin === "left"
      ? "sm:ml-2 mt-4 sm:mt-0"
      : mark?.margin === "right"
      ? "mr-2 mb-4 sm:mb-0"
      : mark?.margin === "top"
      ? "mt-6"
      : "";

  const attributesAmmended = mark.openTab
    ? { ...attributes, ...{ target: "_blank" } }
    : attributes;

  return (
    <Button
      text={children || ""}
      href={location}
      bgColor={
        BUTTON_BG_MAP.has(mark.style)
          ? BUTTON_BG_MAP.get(mark.style)
          : "bg-magenta"
      }
      icon={
        mark?.icon !== "none" ? (
          <ArrowLeft
            color={cn(mark?.style !== "buttonGreen" ? "#FFF" : "")}
            size="12"
            rotate="180"
          />
        ) : null
      }
      iconRight={true}
      metrics={{ action: "content-cta" }}
      className={cn(
        marginClass,
        mark?.style === "buttonLink" ? "shadow-lg" : null
      )}
      attributes={attributesAmmended}
      isBig={mark.size === "big"}
      isSmall={mark.size === "small"}
    />
  );
};

export const renderLink = ({ mark, children }) => {
  const { href, style } = mark;

  if (!href && !mark.reference) return "BROKEN LINK";

  if (href && href.match(/^__GHOST_URL__./)) return "IMPORTED BAD LINK";

  const downloadLink = href && href.match(/\/download\/.*/);

  const arrowClass =
    mark.style === "linkArrowGreen"
      ? "text-green-200 font-semibold"
      : mark.style === "linkArrowBlue"
      ? "text-blue-600 font-semibold"
      : mark.style === "linkArrow"
      ? "text-link font-semibold"
      : null;

  // sniff links to contact form and point to demo
  const location = mark.reference
    ? pageDocPath(mark.reference)
    : (isInternalUrl(href) || isKnownDomain(href)) &&
      href.match(/\/contact/) &&
      children &&
      typeof children[0] === "string" &&
      children[0].match(/demo/)
    ? "/demo/"
    : href;

  if (isInternalUrl(location) || mark.reference) {
    return style && !arrowClass ? (
      makeButton({ mark, children, location })
    ) : (
      <a
        href={location}
        className={cn(
          "hover:underline transition-link",
          arrowClass || "text-link"
        )}
        attributes={downloadLink || mark.openTab ? { target: "_blank" } : null}
      >
        {children}
        {arrowClass && (
          <ArrowLeft size="16" rotate="180" className="relative ml-2 top-0.5" />
        )}
      </a>
    );
  }

  // @todo isEmailUrl(location)

  try {
    const url = new URL(href);
    const attributes =
      downloadLink || !isKnownDomain(url.hostname)
        ? { target: "_blank" }
        : null;

    return style ? (
      makeButton({ mark, children, location, attributes })
    ) : (
      <a
        href={location}
        {...attributes}
        className="text-link hover:underline transition-link"
      >
        {children}
      </a>
    );
  } catch (e) {
    console.error("Invalid URL in content body", e);
    return children;
  }
};
