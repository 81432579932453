import React from "react";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { CardCta } from "../../card/card";
import { pageDocPath } from "../../../../helpers";
import { ImageBuilder } from "../../global/image-builder";

const STORY_CTA_TEXT = "Read the story";

// stats tiles
export const CustomerStories = ({ section }) => {
  const data =
    useStaticQuery(graphql`
      query CustomerStoriesQuery {
        docs: allSanityStory(
          sort: { fields: [orderRank], order: ASC }
          filter: {
            slug: { current: { ne: null } }
            status: { eq: "published" }
          }
        ) {
          edges {
            node {
              ...cardNodeStory
            }
          }
        }
      }
    `) || {};

  const docs = mapEdgesToNodes(data.docs).filter(
    (doc) => doc.isFeatured && doc.statistics.length > 0
  );

  return docs.length > 0 ? (
    <ul className="grid sm:grid-cols-2 lg:grid-cols-4 gap-2 p-2 bg-white border border-dark-20 rounded-lg mb-10">
      {docs.map((doc, i) => (
        <li key={i}>
          <a
            href={pageDocPath(doc)}
            className="block relative bg-dark-2 hover:bg-dark-5 border border-dark-10 rounded-lg p-4 pb-20 pt-2 md:p-6 md:pb-20 md:pt-2"
          >
            <ImageBuilder
              image={doc?.company?.mainImage}
              quality={100}
              height={60}
              style={{
                maxHeight: "60px",
              }}
            />
            <div className="text-6xl sm:text-9xl font-thin leading-none tracking-tight mt-4 mb-2">
              {doc.statistics[0].number}
            </div>
            <div>{doc.statistics[0].suffix}</div>
            <CardCta text={STORY_CTA_TEXT} />
          </a>
        </li>
      ))}
    </ul>
  ) : null;
};
