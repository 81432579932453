import React from "react";
import { imageBuilder } from "../../lib/helpers";

const DEFAULT_QUALITY = 99;

export const ImageBuilder = ({
  image,
  alt,
  width,
  height,
  className,
  style,
  onClick,
  src,
  quality,
  draggable,
}) =>
  image?.asset?.metadata ? (
    <img
      src={imageBuilder(image)
        .withOptions(
          width && height
            ? {
                quality: quality || DEFAULT_QUALITY,
                auto: "format",
                width: width * 2,
                height: height * 2,
              }
            : width
            ? {
                quality: quality || DEFAULT_QUALITY,
                auto: "format",
                width: width * 2,
              }
            : {
                quality: quality || DEFAULT_QUALITY,
                auto: "format",
                height: height * 2,
              }
        )
        .url()}
      {...{
        width:
          width ||
          Math.round(height * image.asset.metadata.dimensions.aspectRatio),
        height,
        alt,
        className,
        style,
        onClick,
        draggable,
      }}
    />
  ) : src ? (
    <img
      {...{ src, width, height, alt, className, style, onClick, draggable }}
    />
  ) : null;
