import React, { useContext } from "react";
import { pageDocPath, trackPath } from "../../../../helpers";
import { Button } from "../../button";
import { SiteContext } from "../../global/site-context";
import PortableText from "../../portableText";
import {
  ATTRIBUTION_PARAM,
  DEMO_PHRASE,
  DEMO_URL,
  SIGNUP_PHRASE,
  SIGNUP_URL,
} from "./navigation";
import { ImageBuilder } from "../../global/image-builder";

export const DEMO_PAGES = [
  "/demo/",
  "/demo-new/",
  "/contact/",
  "/hello/databricks-bm9kZWpz/",
  "/hello/deel-ZGF0YWJ/",
  "/hello/grafana-5eh8xp3/",
  "/hello/stripe-4twr3iz9o/",
];

export const GetStarted = ({ section }) => {
  const site = useContext(SiteContext);
  const path = trackPath(site?.doc);

  return (
    <div className="text-center">
      <ImageBuilder
        src="/static/img/rocket-2.svg"
        className="mx-auto"
        width="254"
        height="161"
        alt="rocket ship blasting off"
      />
      <div className="my-6">
        {section?._rawSummary ? (
          <PortableText blocks={section._rawSummary} />
        ) : (
          <h4 className="text-2xl md:text-4xl font-semibold tracking-tight mt-8 mb-4 first:mt-0">
            {site.token("getStartedSummary")}
          </h4>
        )}
      </div>
      <div>
        <Button
          text={SIGNUP_PHRASE}
          href={`${SIGNUP_URL}?${ATTRIBUTION_PARAM}=get-started-banner--${path}`}
          bgColor="bg-link"
          className="mt-1 mb-5"
          isBig={true}
        />
        {!DEMO_PAGES.includes(pageDocPath(site?.doc)) && (
          <p className="font-semibold text-secondary">
            Wanna learn more?{" "}
            <a
              href={`${DEMO_URL}?${ATTRIBUTION_PARAM}=get-started-banner--${path}`}
              className="text-link underline hover:no-underline"
            >
              {DEMO_PHRASE}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};
