import React from "react";

export const List = ({ color, size }) => (
  <svg
    width={size || "14"}
    height={size ? "" : "13"}
    viewBox="0 0 14 11"
    fill={color || "currentColor"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.71875 4.8125C1.26172 4.8125 0.90625 5.19336 0.90625 5.625C0.90625 6.08203 1.26172 6.4375 1.71875 6.4375C2.15039 6.4375 2.53125 6.08203 2.53125 5.625C2.53125 5.19336 2.15039 4.8125 1.71875 4.8125ZM1.71875 0.75C1.26172 0.75 0.90625 1.13086 0.90625 1.5625C0.90625 2.01953 1.26172 2.375 1.71875 2.375C2.15039 2.375 2.53125 2.01953 2.53125 1.5625C2.53125 1.13086 2.15039 0.75 1.71875 0.75ZM1.71875 8.875C1.26172 8.875 0.90625 9.25586 0.90625 9.6875C0.90625 10.1445 1.26172 10.5 1.71875 10.5C2.15039 10.5 2.53125 10.1445 2.53125 9.6875C2.53125 9.25586 2.15039 8.875 1.71875 8.875ZM12.8906 0.953125H5.14648C4.81641 0.953125 4.5625 1.23242 4.5625 1.5625C4.5625 1.86719 4.81641 2.17188 5.14648 2.17188H12.8906C13.2207 2.17188 13.5 1.91797 13.5 1.58789C13.5 1.2832 13.2207 0.953125 12.8906 0.953125ZM12.8906 9.07812H5.14648C4.81641 9.07812 4.5625 9.35742 4.5625 9.6875C4.5625 10.043 4.81641 10.2969 5.14648 10.2969H12.8906C13.2207 10.2969 13.5 10.043 13.5 9.6875C13.5 9.35742 13.2207 9.07812 12.8906 9.07812ZM12.8906 5.01562H5.14648C4.81641 5.01562 4.5625 5.29492 4.5625 5.625C4.5625 5.98047 4.81641 6.23438 5.14648 6.23438H12.8906C13.2207 6.23438 13.5 5.98047 13.5 5.625C13.5 5.29492 13.2207 5.01562 12.8906 5.01562Z"
      fill="#131313"
    />
  </svg>
);
