import React, { useContext, useEffect } from "react";
import { SiteContext } from "../../global/site-context";

// @todo discuss self-service plan, use doc._id

const ASSETS = [
  {
    // evaluation guide
    page: "-e9cb7440-b49d-5183-9c22-0d29437cf831",
    url: "https://cdn.sanity.io/files/vt347z5x/production/5af0c2201c6640b02d5531791222a758ad6349f2.pdf",
  },
  {
    // dev compensation survey
    page: "-d93848b8-ab0a-50cc-9a4a-241d42318a53",
    url: "https://cdn.sanity.io/files/vt347z5x/production/960240d351d1d59a009fe927df770c5ee3da4312.pdf",
  },
  {
    // dev compensation survey - follow up
    page: "-042c37e5-5576-5de0-8c66-8771b159052c",
    url: "https://cdn.sanity.io/files/vt347z5x/production/19e6e953c4120787894ea15986ab5864ba71bd1a.pdf",
  },
  {
    page: "-7db3c5b1-e809-504d-a00c-fae59988db0b",
    url: "https://cdn.sanity.io/files/vt347z5x/production/8485ea6fbfafd62206f68b1ad9d616ca0aeb612f.pdf",
  },
];

const REDIRECT_DELAY = 2000;

export const AssetRedirect = ({ section }) => {
  const site = useContext(SiteContext);
  const asset = ASSETS.find((a) => a.page === site?.doc.id);

  useEffect(() => {
    if (site.isBrowser) {
      // @todo time delay

      setTimeout(() => {
        window.location.replace(asset.url);
      }, REDIRECT_DELAY);
    }
  }, []);

  return asset ? (
    <div className="mx-auto text-center">
      <h2 className="text-3xl font-semibold mb-4">Redirecting...</h2>
      <span className="text-lg font-bold ">
        or you can{" "}
        <a
          className="text-link hover:underline"
          href={asset.url}
          onClick={() => {
            site.metrics.logClick("asset-redirect-click");
          }}
        >
          download now
        </a>
      </span>
    </div>
  ) : (
    <p>Missing asset.</p>
  );
};
