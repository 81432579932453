import { trackableFields } from "../components/web-form";
import { trackPath } from "../../helpers";
import { getStashed, removeStashed, setStashed } from "./helpers";
import { ATTRIBUTION_PARAM } from "../components/sections/snowflake/navigation";
import { getReferrerAttribution } from "./referrer";

export const COOKIE_NAME = "attribution";
export const HAS_SIGNUP_CLICK_STASH = "action.signupClick";
export const FIRST_SEEN_STASH = "origin.created";

export const simpleDate = (dateObj) => {
  const date = new Date(dateObj);

  return (
    date.getUTCFullYear() +
    "-" +
    ("0" + (date.getUTCMonth() + 1)).slice(-2) +
    "-" +
    (
      "0" +
      (date.getUTCHours() > 12 ? date.getUTCDate() + 1 : date.getUTCDate())
    ).slice(-2)
  );
};

export const getSimpleDate = (id, storage) =>
  storage.getItem(id)
    ? simpleDate(storage.getItem(id).replace(/"/g, ""))
    : null;

export const clientAttribution = ({
  storage,
  isActive,
  callback,
  referrer,
}) => {
  if (typeof window === "undefined") return;

  const urlParams = new URLSearchParams(window.location.search);
  let newParams = new URLSearchParams();

  // custom param for website click tracking
  const webAttr = urlParams.get(ATTRIBUTION_PARAM);
  if (webAttr) {
    newParams.set(ATTRIBUTION_PARAM, webAttr);
  }
  // debug convenience visibilty param
  if (urlParams.has("show")) {
    newParams.set("show", true);
    storage.setItem("show", true);
  }

  // stash referrer and retrieve utm inferences
  const referrerAttribution = getReferrerAttribution({ storage, referrer });

  // stash first seen date
  if (!storage.getItem(FIRST_SEEN_STASH)) {
    storage.setItem(FIRST_SEEN_STASH, new Date().toISOString());
  }

  // any utm params
  if (trackableFields.find((id) => urlParams.has(`utm_${id}`))) {
    const skipOrigin = trackableFields.find((id) =>
      storage.getItem(`origin.urlParams.${id}`)
    );

    trackableFields.forEach((id) => {
      const value = urlParams.get(`utm_${id}`);

      if (value) {
        newParams.set(`utm_${id}`, value);
        setStashed({
          storage,
          id: `urlParams.${id}`,
          value: value,
          skipOrigin,
        });
      } else {
        // ensure no mis-match combos
        removeStashed({ id: `urlParams.${id}`, storage });
      }
    });
  } else if (isActive("postReferrerAttribution") && referrerAttribution) {
    // use referrer to infer utms
    trackableFields.forEach((id) => {
      const value = referrerAttribution[id];
      if (value) {
        newParams.set(`utm_${id}`, value);
        setStashed({ storage, id: `urlParams.${id}`, value: value });
      } else {
        removeStashed({ id: `urlParams.${id}`, storage });
      }
    });
  } else if (isActive("postStashedParams")) {
    // use stashed utm values
    trackableFields.forEach((id) => {
      const value = getStashed({ storage, id: `urlParams.${id}` });
      if (value) {
        newParams.set(
          `utm_${id}`,
          getStashed({ storage, id: `urlParams.${id}` })
        );
      }
    });
  }

  callback(newParams, storage);
};

export const attributionCookie = (storage) =>
  [
    // recent utm params
    ["utm_parameter___medium", "urlParams.medium"],
    ["utm_parameter___source", "urlParams.source"],
    ["utm_parameter___campaign", "urlParams.campaign"],
    ["utm_parameter___content", "urlParams.content"],
    ["utm_parameter___term", "urlParams.term"],
    // original utm params
    ["utm_parameter___medium_original", "origin.urlParams.medium"],
    ["utm_parameter___source_original", "origin.urlParams.source"],
    ["utm_parameter___campaign_original", "origin.urlParams.campaign"],
    ["utm_parameter___content_original", "origin.urlParams.content"],
    ["utm_parameter___term_original", "origin.urlParams.term"],
    // absolute first seen date
    [
      "first_seen_on_website",
      {
        value: getSimpleDate(FIRST_SEEN_STASH, storage),
      },
    ],
    // utm dates
    [
      "utm_parameter___created_date",
      {
        value: getSimpleDate(
          `urlParams.${trackableFields.find((id) =>
            getStashed({ id: `urlParams.${id}`, storage })
          )}.created`,
          storage
        ),
      },
    ],
    [
      "utm_parameter___created_date_original",
      {
        value: getSimpleDate(
          `origin.urlParams.${trackableFields.find((id) =>
            storage.getItem(`origin.urlParams.${id}`)
          )}.created`,
          storage
        ),
      },
    ],
  ].reduce((cookie, f) => {
    const value = typeof f[1] === "object" ? f[1].value : storage.getItem(f[1]);
    const addition = value
      ? {
          [f[0]]: value,
        }
      : {};

    return {
      ...cookie,
      ...addition,
    };
  }, {});

export const appendUtms = ({ dom, urlParams, doc, storage }) => {
  dom.querySelectorAll("a[href*='/signup']").forEach((l) => {
    const url = new URL(l.href);
    const onclick = l.onclick;

    for (const [k, v] of urlParams) {
      url.searchParams.set(trackableFields.includes(k) ? `utm_${k}` : k, v);
    }
    if (!url.searchParams.has(ATTRIBUTION_PARAM)) {
      url.searchParams.set(ATTRIBUTION_PARAM, trackPath(doc));
    }

    l.href = url.toString();
    l.onclick = (e) => {
      storage.setItem(HAS_SIGNUP_CLICK_STASH, true);
      onclick(e);
    };
  });

  dom.querySelectorAll("a[href*='/contact'], a[href*='/demo']").forEach((l) => {
    const url = new URL(l.href);

    if (!url.searchParams.has(ATTRIBUTION_PARAM)) {
      url.searchParams.set(ATTRIBUTION_PARAM, trackPath(doc));
    }
    l.href = url.toString();
  });
};
