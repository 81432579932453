import React, { useEffect, useState } from "react";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { CardEvent } from "../../card/card-event";
import { CardContext } from "../../card/context";
import { isFuture } from "date-fns";
import { Button } from "../../button";
import PortableText from "../../portableText";
import { EmailSignup } from "../../global/email-signup";
import { Card } from "../../card/card";

export const EventsTeaser = ({ section }) => {
  const [listing, setListing] = useState([]);

  const { events, videos, posts } =
    useStaticQuery(graphql`
      query EventsTeaserQuery {
        events: allSanityEvent(
          limit: 1
          sort: { fields: [startDate], order: DESC }
          filter: {
            isPublished: { eq: true }
            slug: { current: { ne: null } }
            categories: {
              elemMatch: {
                _id: {
                  in: [
                    "17e46daf-7cde-44a3-b8b3-7ffcf6c7b86b" # Mavericks
                  ]
                }
              }
            }
          }
        ) {
          edges {
            node {
              ...cardNodeEvent
            }
          }
        }
        videos: allSanityVideo(
          limit: 1
          sort: { fields: [_createdAt], order: DESC }
          filter: {
            isPublished: { eq: true }
            slug: { current: { ne: null } }
            categories: {
              elemMatch: {
                _id: {
                  in: [
                    "17e46daf-7cde-44a3-b8b3-7ffcf6c7b86b" # Mavericks
                  ]
                }
              }
            }
          }
        ) {
          edges {
            node {
              ...cardNodeVideo
            }
          }
        }
        posts: allSanityPost(
          limit: 1
          sort: { fields: [_createdAt], order: DESC }
          filter: {
            isPublished: { eq: true }
            slug: { current: { ne: null } }
            categories: {
              elemMatch: {
                _id: {
                  in: [
                    "17e46daf-7cde-44a3-b8b3-7ffcf6c7b86b" # Mavericks
                  ]
                }
              }
            }
          }
        ) {
          edges {
            node {
              ...cardNode
            }
          }
        }
      }
    `) || {};

  useEffect(() => {
    const eventNodes = events && mapEdgesToNodes(events);
    const videoNodes = videos && mapEdgesToNodes(videos);
    const postNodes = posts && mapEdgesToNodes(posts);

    setListing(
      eventNodes
        .filter((e) => isFuture(new Date(e.startDate)))
        .reverse()
        .concat(
          videoNodes
            .concat(postNodes)
            .sort((a, b) => (a._createdAt < b._createdAt ? 1 : -1))
        )
    );
  }, [events, videos]);

  return (
    <div className="grid md:grid-cols-2 gap-12">
      <div>
        <PortableText blocks={section._rawSummary} />
        <div className="mt-4 border border-dark-20 p-0.25 rounded-xl max-w-sm">
          <EmailSignup
            buttonText="Email me"
            placeholder="Email me event reminders"
          />
        </div>
      </div>
      <div>
        {listing.length > 0 ? (
          <ul className="grid gap-4 grid-cols-1">
            {listing.slice(0, 1).map((node, i) => (
              <li
                key={node.id}
                className={i ? "border-t-2 pt-8 mt-4 border-gray-200" : ""}
              >
                <CardContext.Provider value={{ ...node, hideType: true }}>
                  {node._type === "event" ? <CardEvent /> : <Card />}
                </CardContext.Provider>
              </li>
            ))}
          </ul>
        ) : (
          <p className="md:ml-3 text-xl text-dark-50">Loading...</p>
        )}
      </div>
    </div>
  );
};
