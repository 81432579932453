import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import { Image } from "./sections/featured-media";
import { SiteContext } from "./global/site-context";
import { cn, imageBuilder } from "../lib/helpers";
import { Button } from "./button";
import { PlayIcon } from "./icon/play";
import { ImageBuilder } from "./global/image-builder";

// @ts-ignore
import * as tailwind from "../../tailwind.config";

const VIDEO_PREVIEW_DELAY = 2500;

/**
 * https://github.com/vimeo/player.js/blob/master/src/player.js
 * @type {React.VFC<ProductVideo>}
 */
export const VideoPopover = ({
  image,
  mobileImage,
  alt,
  width,
  height,
  className,
  vimeoId,
  previewVideo,
  iframe,
  buttonBg,
  buttonText,
  loomUrl,
  previewGif,
  videoCtaText = "Watch a product demo",
}) => {
  const site = useContext(SiteContext);
  const [hideVideo, setHidden] = useState(true);
  const [videoInit, setVideoInit] = useState(false);
  const playerId = "hero-product-video-player";
  const videoElementRef = useRef();
  const playerRef = useRef();
  const previewRef = useRef();

  const loomAdjusted = loomUrl ? new URL(loomUrl) : null;
  if (loomUrl) {
    loomAdjusted.searchParams.set("autoplay", true);
    loomAdjusted.searchParams.set("hide_share", true);
    loomAdjusted.searchParams.set("hideEmbedTopBar", true);
  }

  const videoReady = (player) => {
    playerRef.current = player;
    playerRef.current.play();
  };

  const trackPlayClick = (label) => {
    site.metrics.logEvent({
      category: "button_click",
      action: "hero-video-cta",
      label,
    });
  };

  useLayoutEffect(() => {
    if (previewRef.current) {
      setTimeout(() => {
        previewRef.current.play();
      }, VIDEO_PREVIEW_DELAY);
    }
  }, []);

  return (
    <div className={!buttonText ? "flex justify-center relative" : "inline"}>
      {buttonText && (
        <Button
          bgColor={buttonBg}
          text={buttonText}
          isBig={true}
          onClick={() => {
            trackPlayClick("iframe-open");
            setVideoInit(true);
            setHidden(false);
            if (!iframe && playerRef.current) {
              playerRef.current.play();
            }
          }}
        />
      )}
      <div
        className={cn(
          "cursor-pointer top-0 right-0 bottom-0 left-0 bg-dark-50 flex justify-center items-center",
          hideVideo ? "opacity-0" : "fixed z-100"
        )}
        // style={{ maxHeight: "70%" }}
        onClick={() => {
          setHidden(true);
          if (!iframe && playerRef.current) {
            playerRef.current.pause();
          }
        }}
      >
        {videoInit && !hideVideo && (
          <>
            {loomUrl ? (
              <iframe
                src={loomAdjusted.toString()}
                className="w-full h-full border-0"
                style={{ width: "90%", maxWidth: 1280, maxHeight: "70%" }}
                allow="fullscreen"
              ></iframe>
            ) : iframe ? (
              // Navattic tour
              <iframe
                src={iframe}
                className="w-full h-full border-0"
                allow="fullscreen"
                style={{ width: "90%", maxWidth: 1280, maxHeight: "70%" }}
              ></iframe>
            ) : (
              <Vimeo
                video={vimeoId}
                id={playerId}
                ref={videoElementRef}
                style={{ width: "80%", maxWidth: 1280 }}
                showTitle={false}
                showByline={false}
                showPortrait={false}
                responsive={true}
                onReady={videoReady}
                speed={true}
                textTrack="en-x-autogen"
                className="rounded-lg overflow-hidden"
              />
            )}
          </>
        )}
      </div>
      <div className="relative">
        {buttonText ? null : previewVideo || previewGif ? (
          <div className="relative bg-white border border-dark-20 rounded-2xl p-2">
            {previewVideo ? (
              <video
                ref={previewRef}
                loop={true}
                muted={true}
                preload="auto"
                playsInline={true}
                autoPlay={true}
                width={width}
                poster={imageBuilder(image)
                  .width(width * 2)
                  .quality(99)
                  .auto("format")
                  .url()}
                className="rounded-tr-xl rounded-tl-xl"
              >
                <source src={previewVideo.asset.url} type="video/mp4" />
              </video>
            ) : (
              <img
                src={previewGif}
                alt="preview gif"
                className="rounded-tr-xl rounded-tl-xl cursor-pointer transform transition-all scale-100 hover:scale-102"
                onClick={() => {
                  setVideoInit(true);
                  setHidden(false);
                }}
              />
            )}
            <div className="relative none:-mt-2.5 flex items-center bg-link text-white font-semibold p-2 px-3 rounded-bl-xl rounded-br-xl">
              <PlayIcon color={tailwind.theme.colors.link} />
              <span className="ml-2">{videoCtaText}</span>
            </div>
            <a
              className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center group cursor-pointer"
              role="button"
              onClick={() => {
                trackPlayClick("video-play");
                setVideoInit(true);
                setHidden(false);
                if (!iframe && playerRef.current) {
                  playerRef.current.play();
                }
              }}
            >
              <div className="absolute top-2 right-2 left-2 bottom-2 rounded-xl bg-link transition-opacity duration-300 opacity-0 group-hover:opacity-20"></div>
              {previewVideo && (
                <div className="relative transition-opacity duration-300 opacity-0 group-hover:opacity-100 bg-link rounded-full py-6 px-7 shadow-lg">
                  <ImageBuilder
                    src="/static/img/icons/play-button.svg"
                    width={24}
                    height={29}
                    alt="play icon"
                  />
                </div>
              )}
            </a>
          </div>
        ) : (
          <>
            <ImageBuilder
              src={mobileImage}
              width={337}
              height={182}
              className={cn(
                "block sm:hidden cursor-pointer transform transition-all scale-100 hover:scale-102",
                className
              )}
              alt={alt}
              onClick={() => {
                trackPlayClick("video-play");
                setVideoInit(true);
                setHidden(false);
                if (!iframe && playerRef.current) {
                  playerRef.current.play();
                }
              }}
            />
            <Image
              {...{ image, alt, width, height }}
              className={cn(
                "hidden sm:block cursor-pointer transform transition-all scale-100 hover:scale-102",
                className
              )}
              onClick={() => {
                trackPlayClick("video-play");
                setVideoInit(true);
                setHidden(false);
                if (!iframe && playerRef.current) {
                  playerRef.current.play();
                }
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
