import React, { useContext } from "react";
import { mapEdgesToNodes } from "../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { QuotePanels } from "./quote-panels";
import { Button } from "../button";
import { ArrowLeft } from "../icon/arrow-left";
import { SiteContext } from "../global/site-context";
import { Tweets } from "./snowflake/tweets";
import { SectionHeaders } from "./wrapper";

export const quoteWallQuery = graphql`
  query QuoteWallQuery {
    # confirm this does not bloat page data size
    docs: allSanityQuotable(
      sort: { fields: [orderRank] }
      filter: { _rawExcerpt: { ne: null } }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
    homepage: allSanityQuotable(
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        _rawExcerpt: { ne: null }
        url: { eq: null }
        categories: {
          elemMatch: { _id: { in: ["429d6b97-3b6d-4f55-9eb8-13589d5ea01a"] } }
        }
        headline: { eq: null }
        companies: {
          elemMatch: { mainImage: { asset: { _id: { ne: null } } } }
        }
      }
      limit: 8
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
  }
`;

export const QuoteWall = ({ section }) => {
  const site = useContext(SiteContext);
  const results = useStaticQuery(quoteWallQuery) || {};
  const docs =
    section?.category === "homepage" ? results.homepage : results.docs;

  return (
    <>
      <QuotePanels quotes={mapEdgesToNodes(docs)} showCardLogo={true} />
      <div className="flex justify-center mt-12">
        <Button
          text="See our customer stories"
          bgColor="bg-light-10"
          icon={<ArrowLeft rotate={180} />}
          iconRight={true}
          isSmall={true}
          href="/customers/"
        />
      </div>
      {site.isHomepage && (
        <div className="pt-16 md:pt-24">
          <SectionHeaders
            {...{
              ...section,
              header: "We appreciate you all, too.",
              _rawSummary: [],
            }}
          />
          <Tweets />
        </div>
      )}
    </>
  );
};
