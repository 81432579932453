import React, { useContext } from "react";
import { differenceInDays, formatDistance, format } from "date-fns";
import { CardContext } from "./context";

export const PublishDate = () => {
  const card = useContext(CardContext);
  const publishDate = card.publishedAt || card._createdAt;

  return publishDate ? (
    <span>
      {differenceInDays(new Date(publishDate), new Date()) > 3
        ? formatDistance(new Date(publishDate), new Date())
        : format(new Date(publishDate), "MMM do, yyyy")}
    </span>
  ) : null;
};
