import React, { useContext } from "react";
import { Emoji } from "../emoji";
import { cn } from "../../lib/helpers";
import { SiteContext } from "../global/site-context";
import { pageDocPath } from "../../../helpers";

const OFFER_MARKETING = "15% Demand Curve Discount";
const OFFER_SALES = "15% RevGenius Discount";
const OFFER_ACQUIRED = "20% Acquired Discount";

// "/hello/discover-pipeline-dark-funnel/",
// "/hello/discover-pipeline-dark-funnel-quotes/",

export const Badge = ({ section }) => {
  const site = useContext(SiteContext);
  const path = pageDocPath(site?.doc);

  const message = [
    "/hello/growth-hacks/",
    "/hello/dark-funnel-demand/",
    "/hello/demand-curve/",
    "/playbooks/hello/alert-keywords-topics-intent/",
  ].includes(path)
    ? OFFER_MARKETING
    : path === "/acquired/"
    ? OFFER_ACQUIRED
    : OFFER_SALES;

  return (
    <div
      className={cn(
        "fixed top-12 left-0 w-full flex justify-center",
        site.isPageTop ? "fixed top-12 left-0" : "opacity-0"
      )}
    >
      <div className="bg-lavender border-2 border-dark-10 p-1 px-2 rounded-lg font-bold text-purple-700">
        <Emoji symbol="🚨" label="alarm" />
        <span className="mx-2 text-xs relative -top-0.5">
          {section?.header || message}
        </span>
        <Emoji symbol="🚨" label="alarm" />
      </div>
    </div>
  );
};
