import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field } from "formik";
import { cn, isEmail, getStashed } from "../../lib/helpers";
import { Button } from "../button";
import { Dialog } from "./dialog";
import { HiddenFields } from "../web-form-hidden-fields";
import { SiteContext } from "./site-context";
import { clientAttribution, getSimpleDate } from "../../lib/client-attribution";
import { getUtmParam, trackableFields } from "../web-form";
import { ChannelsButtons } from "../sections";

import * as styles from "./email-signup.module.css";
import { Slack } from "../icon/slack";

const postUrl = "/.netlify/functions/sendgrid-forms";
const isBrowser = typeof window !== "undefined";

const slugFromUrl = (href = isBrowser ? window.location.pathname : "") => {
  if (!href) return "";
  const url = href.replace(/\/$/, "");
  return url.substring(url.lastIndexOf("/") + 1);
};

// TEMP DURING PHASED ROLLOUT
const SUBSCRIBE_ID = "email-signup";

export const EmailSignup = ({
  buttonText,
  buttonClasses,
  autoFocus,
  attribution,
  supressMessage,
  toggle,
  brand,
  placeholder,
  domId = SUBSCRIBE_ID,
  showChannels,
  buttonIsSmall,
}) => {
  const fields = [{ name: "email", value: "" }];
  const hiddenFields = []
    .concat(
      domId === SUBSCRIBE_ID ? [{ name: "lists", value: "uncommon" }] : []
    )
    .concat([
      { name: "source", value: attribution?.source },
      { name: "medium" },
      {
        name: "campaign",
        value: attribution?.campaign,
      },
      {
        name: "content",
        value: attribution?.content,
      },
      {
        name: "campaign_type",
        value: attribution?.campaignType,
      },
      {
        name: "page_cta_path_on_website",
        value: attribution?.website_attribution,
      },
      {
        name: "first_seen_on_website",
        storage: (storage) => getSimpleDate("origin.created", storage),
      },
      {
        name: "utm_parameter___created_date",
        storage: (storage) =>
          getSimpleDate(
            `urlParams.${trackableFields.find((f) =>
              getStashed({ id: `urlParams.${f}`, storage })
            )}.created`,
            storage
          ),
      },
      {
        name: "utm_parameter___created_date_original",
        storage: (storage) =>
          getSimpleDate(
            `origin.urlParams.${trackableFields.find((f) =>
              storage.getItem(`origin.urlParams.${f}`)
            )}.created`,
            storage
          ),
      },
    ])
    .concat(
      trackableFields.reduce((prev, id) => {
        return prev.concat([
          { name: `utm_parameter___${id}`, param: `utm_${id}` },
          {
            name: `utm_parameter___${id}_original`,
            storage: (storage) => storage.getItem(`origin.urlParams.${id}`),
          },
        ]);
      }, [])
    );

  const [initVals, setInitVales] = useState(
    fields.concat(hiddenFields).reduce(
      (obj, field) =>
        Object.assign({}, obj, {
          [field.name]: field.value || "",
        }),
      {}
    )
  );

  const [successTrigger, setSuccessTrigger] = useState(false);
  const [showHidden, setShowHidden] = useState(false);
  const site = useContext(SiteContext);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    console.debug("POST VALUES", values);

    try {
      const res = await fetch(postUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (!res.ok) {
        site.metrics.logError("post-response-fail", res);
      } else {
        console.debug("POST SUCCESS", res);
        site.metrics.logClick("header-widget--join-success");
      }
    } catch (e) {
      site.metrics.logError("post-try-catch", e);
    } finally {
      setSubmitting(false);
      setSuccessTrigger(true);
      toggle();
      resetForm();
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!isEmail(values.email)) {
      errors.email = "Invalid email address";
    }
    return errors;
  };

  useEffect(() => {
    clientAttribution({
      isActive: site.isActive,
      storage: site.metrics.analytics.storage,
      referrer: document.referrer,
      callback: (urlParams) => {
        // make key value and combine
        setInitVales(
          fields.concat(hiddenFields).reduce(
            (obj, field) =>
              Object.assign({}, obj, {
                [field.name]: field.storage
                  ? field.storage(site.metrics.analytics.storage) || ""
                  : field.param
                  ? urlParams.get(field.param) || ""
                  : getUtmParam({ id: field.name, urlParams }) ||
                    field.value ||
                    "",
              }),
            {}
          )
        );

        if (urlParams.has("show")) {
          setShowHidden(true);
        }
      },
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={initVals}
        validate={validate}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => (
          <Form id={domId}>
            <div
              className={cn(
                "flex bg-white p-1 sm:p-1.5 rounded-xl items-center",
                styles.appear
              )}
            >
              <div className="flex-grow">
                <Field
                  type="email"
                  name="email"
                  className={cn(
                    "w-full text-sm border-transparent outline-none focus:ring-0 outline-none",
                    "bg-white text-black rounded-xl border-none px-2 md:px-4 py-2 shadow-none",
                    styles.inputField
                  )}
                  placeholder={placeholder || "Your email"}
                  autoFocus={autoFocus}
                />
                <HiddenFields fields={hiddenFields} show={showHidden} />
                {/* <ErrorMessage
              name="email"
              component="p"
              className={cn(
                "absolute text-secondary mt-3",
                styles.errorMessage
              )}
            /> */}
              </div>
              <Button
                text={buttonText || "Subscribe"}
                deactivated={isSubmitting}
                className={buttonClasses}
                metrics={{
                  // @todo swap action for homepage
                  action: "uncommon",
                  label: `${attribution?.campaignType}--subscribe-attempt`,
                }}
                bgColor="bg-link"
                icon={domId === "join-slack" ? <Slack size={18} /> : null}
                isBig={buttonIsSmall ? null : true}
                isSmall={buttonIsSmall}
              />
            </div>
          </Form>
        )}
      </Formik>
      {showChannels && (
        <div className="mt-12">
          <p className="font-semibold mb-3">Or find us here:</p>
          <ChannelsButtons />
        </div>
      )}
      {successTrigger && !supressMessage && (
        <Dialog
          title="Thanks. Let's go!"
          message="We're uncommonly glad to have you."
        />
      )}
    </>
  );
};
