import React from "react";

export const ArrowElbow = ({ color, rotate }) => (
  <div style={rotate ? { transform: `rotate(${rotate}deg)` } : null}>
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.02189 10.8329C8.87814 10.6891 8.87814 10.4256 9.02189 10.2818L11.825 7.47876H2.79282C1.71471 7.47876 0.876178 6.64023 0.876178 5.56212V1.34552C0.876178 1.15385 1.04388 0.96219 1.25951 0.96219C1.45117 0.96219 1.64283 1.15385 1.64283 1.34552V5.56212C1.64283 6.20899 2.14595 6.71211 2.79282 6.71211H11.825L9.02189 3.93298C8.87814 3.78923 8.87814 3.52569 9.02189 3.38195C9.16564 3.2382 9.42918 3.2382 9.57293 3.38195L13.0229 6.8319C13.0947 6.90377 13.1427 6.9996 13.1427 7.09543C13.1427 7.21522 13.0947 7.31106 13.0229 7.38293L9.57293 10.8329C9.42918 10.9766 9.16564 10.9766 9.02189 10.8329Z"
        fill={color || "currentColor"}
      />
    </svg>
  </div>
);
