import React, { useContext } from "react";
import { Slack } from "../../icon/slack";
import { SiteContext } from "../../global/site-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRss,
  faCalendarDays,
  faArrowsSplitUpAndLeft,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { pageDocPath } from "../../../../helpers";

export const JOIN_SLACK_PATH = "/community/";

const CHANNELS = [
  {
    title: "LinkedIn",
    url: "https://www.linkedin.com/company/common-room-hq",
    component: () => <FontAwesomeIcon icon={faLinkedin} color="#2867B2" />,
    color: "#2867B2",
  },
  {
    title: "Twitter",
    url: "https://twitter.com/commonroomhq",
    component: () => <FontAwesomeIcon icon={faTwitter} color="#1D9BF0" />,
    color: "#1D9BF0",
  },
  {
    title: "YouTube",
    url: "https://www.youtube.com/channel/UC1YQ7n_u0Vt4XieANHNuJgw",
    component: () => <FontAwesomeIcon icon={faYoutube} color="red" />,
    color: "red",
  },
  {
    title: "Blog",
    url: "/blog/",
    component: () => <FontAwesomeIcon icon={faRss} />,
    internal: true,
  },
  {
    title: "Playbooks",
    url: "/playbooks/",
    icon: Slack,
    component: () => <FontAwesomeIcon icon={faArrowsSplitUpAndLeft} />,
    internal: true,
  },
  {
    title: "Events",
    url: "/series/mavericks/",
    component: () => <FontAwesomeIcon icon={faCalendarDays} />,
    internal: true,
  },
  {
    title: "Community",
    url: "/community/",
    component: () => <FontAwesomeIcon icon={faPeopleGroup} />,
    internal: true,
  },
];

export const ChannelsButtons = ({ section }) => {
  const site = useContext(SiteContext);
  const path = pageDocPath(site?.doc);

  return (
    <div className="flex flex-wrap md:flex-nowrap items-center justify-center gap-2 rounded-2xl md:rounded-3xl text-sm pb-3">
      {CHANNELS.map((channel, i) => {
        const attributes = !channel.internal ? { target: "_blank" } : {};

        return channel.url !== path ? (
          <a
            href={channel.url}
            rel="noopener"
            key={i}
            onClick={() => {
              site.metrics.logClick(`channel--${channel.title.toLowerCase()}`);
            }}
            className="flex gap-1 justify-center items-center p-2 sm:px-3 rounded-lg bg-white shadow-md transform transition-card scale-100 hover:scale-110"
            style={{ color: channel.color || "#000" }}
            {...attributes}
          >
            <div className="text-black">
              {!!channel.component ? (
                <channel.component />
              ) : (
                <channel.icon
                  size={channel.size || "24"}
                  className="rounded-md"
                  color="black"
                />
              )}
            </div>
            <p className="font-medium">{channel.title}</p>
          </a>
        ) : null;
      })}
    </div>
  );
};
