// https://knowledge.hubspot.com/reports/understand-hubspots-traffic-sources-in-the-traffic-analytics-tool

import { setStashed } from "./helpers";

const REFERRER_MAP = new Map([
  ["mail.google.com", { source: "Gmail", medium: "Email" }],
  ["google.com", { source: "Google", medium: "Organic search" }],
  ["bing.com", { source: "Bing", medium: "Organic search" }],
  ["duckduckgo.com", { source: "Duck Duck Go", medium: "Organic search" }],
  [
    "android-app://com.google.android.googlequicksearchbox",
    { source: "google.com", medium: "Organic search" },
  ],
  ["search.yahoo.com", { source: "Yahoo", medium: "Organic search" }],
  ["news.yahoo.com", { source: "Yahoo", medium: "News" }],
  ["news.google.com", { source: "Google", medium: "News" }],
  ["t.co", { source: "Twitter", medium: "social" }],
  ["twitter.com", { source: "Twitter", medium: "social" }],
  ["lnkd.in", { source: "LinkedIn", medium: "social" }],
  ["linkedin.com", { source: "LinkedIn", medium: "social" }],
  ["facebook.com", { source: "Facebook", medium: "social" }],
  ["reddit.com", { source: "Reddit", medium: "social" }],
  ["instagram.com", { source: "Instagram", medium: "social" }],
  ["youtube.com", { source: "YouTube", medium: "social" }],
  // ["app.commonroom.io", { source: "Common Room app", medium: "Internal" }],
  // ["docs.commonroom.io", { source: "Common Room docs", medium: "Internal" }],
  // ["new.commonroom.io", { source: "Common Room releases", medium: "Internal" }],
  // ["", { source: "Unknown", medium: "Unknown" }],
]);

const REFERRER_KEY = "referrer";

export const getReferrerAttribution = ({ storage, referrer }) => {
  if (typeof window === "undefined") return;

  // allows setting referrer for testing
  const urlParams = new URLSearchParams(window.location.search);
  const fauxRef = urlParams.get("referrer");

  const domain = !!fauxRef
    ? fauxRef
    : referrer
    ? new URL(referrer).hostname.replace("www.", "")
    : null;

  // stash referrer, overwrite
  if (!!domain && domain !== "") {
    setStashed({ storage, id: REFERRER_KEY, value: domain });
  }

  return REFERRER_MAP.has(domain) ? REFERRER_MAP.get(domain) : null;
};
