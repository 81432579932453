import React, { useContext } from "react";
// import { GatsbyImage } from "gatsby-plugin-image";
// import { getGatsbyImageData } from "gatsby-source-sanity";
// import clientConfig from "../../client-config";
import { cn, imageBuilder } from "../lib/helpers";
import { SectionContext } from "./sections/context";
import { SiteContext } from "./global/site-context";
import { ImageBuilder } from "./global/image-builder";

export const Figure = ({ node }) => {
  const section = useContext(SectionContext);

  if (!node || !node.asset || !node.asset._id) {
    return null;
  }
  // const gatsbyImageData = getGatsbyImageData(
  //   node,
  //   { maxWidth: 736 },
  //   clientConfig.sanity
  // );

  // only within PortableText on content
  const isClickable = (!section._type && node.asset.width >= 1304) || node.link;
  const href = isClickable
    ? node.link || imageBuilder(node).quality(100).url()
    : null;

  return (
    <figure>
      {/* <GatsbyImage image={gatsbyImageData} alt={node.alt} /> */}
      {isClickable ? (
        <a href={href} target="_blank">
          <TheImage node={node} />
        </a>
      ) : (
        <TheImage node={node} />
      )}
      {node.caption && (
        <figcaption className="text-secondary text-center text-sm mb-6">
          {node.caption}
        </figcaption>
      )}
    </figure>
  );
};

const TheImage = ({ node }) => {
  const site = useContext(SiteContext);
  const section = useContext(SectionContext);

  // @todo use the section width to limit image size

  const typeStyle =
    site?.doc._type === "documentation" && node.style !== "none"
      ? "shadow-lg rounded-xl mb-8"
      : null;

  const isSvg = node.asset.extension == "svg";
  const width = isSvg
    ? node.asset.width
    : Math.round(node.asset.width / 2 > 1024 ? 1024 : node.asset.width / 2);

  return (
    <ImageBuilder
      image={node}
      width={width}
      height={
        isSvg
          ? node.asset.metadata.dimensions.height
          : Math.round(width / node.asset.metadata.dimensions.aspectRatio)
      }
      alt={node.alt}
      className={cn(
        "inline-block max-w-full p-0 m-0",
        node.layout === "left"
          ? "lg:float-left lg:mt-2 lg:mr-8 mb-6"
          : node.layout === "right"
          ? "lg:float-right lg:mt-2 lg:ml-8 mb-6"
          : node.layout === "icon"
          ? "h-16 mb-4"
          : node.layout === "iconSmall"
          ? "float-left mr-2 mt-0.5 mb-2"
          : "my-4",
        section.rounded || site?.doc._type === "playbook" ? "rounded-lg" : null,
        site?.doc._type === "playbook" ? "border border-dark-10" : null,
        section.constrainImages ? "lg:max-w-image" : null,
        typeStyle
      )}
      style={
        node.layout === "overlap"
          ? {
              minWidth: "92rem",
            }
          : null
      }
    />
  );
};
