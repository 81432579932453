import React, { useContext } from "react";
import { SiteContext } from "./site-context";
import { CloseIcon } from "../icon/close-x";

// @todo accept metrics

export const Modal = ({ children, setOpen, maxWidth }) => {
  const site = useContext(SiteContext);

  return (
    <div
      className="fixed top-0 right-0 bottom-0 left-0 overflow-hidden flex items-center justify-center bg-dark-50 z-100"
      onClick={() => {
        setOpen(false);
        site.metrics.logClick("popup-dismiss-form", "book-demo");
      }}
    >
      <div
        className="relative md:max-w-2/3 mx-auto bg-white p-8 md:px-12 rounded-xl shadow-lg max-h-full overflow-scroll"
        onClick={(e) => e.stopPropagation()}
        style={maxWidth ? { maxWidth } : null}
      >
        <span
          className="float-right p-2 relative -top-2 -right-6 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
            site.metrics.logClick("popup-dismiss-form", "book-demo");
          }}
        >
          <CloseIcon size="18" />
        </span>
        {children}
      </div>
    </div>
  );
};
