import React, { useState, useRef, useEffect, useContext } from "react";
import clientConfig from "../../../../client-config";
import { pageDocPath } from "../../../../helpers";
import { cn, truncateText } from "../../../lib/helpers";
import { ArrowLeft, ArrowRight } from "../../icon/arrow-left";
import { Button } from "../../button";
import { Image } from "../featured-media";
import { SearchIcon } from "../../icon/search";
import { SiteContext } from "../../global/site-context";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientContext } from "../../global/client-context";

const TYPES_EVERYTHING = [
  "playbook",
  "post",
  "asset",
  "video",
  "page",
  "documentation",
];

export const SearchResources = ({ section }) => (
  <SearchClient
    section={{
      ...section,
      searchTypes: ["playbook", "post", "asset", "video", "page"],
    }}
    fullResults={true}
  />
);

export const SearchHelp = ({ section }) => (
  <SearchClient
    section={{
      ...section,
      searchTypes: ["documentation", "playbook", "asset", "video"],
    }}
    fullResults={true}
  />
);

export const SearchBlog = ({ section }) => (
  <SearchClient
    section={{ ...section, searchTypes: ["post"] }}
    fullResults={true}
  />
);

export const SearchDocs = ({ section, setOpen }) => (
  <SearchClient
    section={{ ...section, searchTypes: ["documentation"] }}
    setOpen={setOpen}
    pageMax={4}
  />
);

// Handle window objects loaded async via CDN
const waitForGlobal = (obj, prop, callback) => {
  if (obj && obj[prop] && typeof obj[prop] === "object") {
    callback();
  } else {
    setTimeout(() => {
      console.log("waiting for global", obj, prop);
      waitForGlobal(obj, prop, callback);
    }, 100);
  }
};

export const SearchClient = ({
  section,
  fullResults,
  setOpen,
  includeUnpublished,
  pageMax,
}) => {
  const site = useContext(SiteContext);
  const client = useContext(ClientContext);
  const searchBox = useRef();
  const [posts, setPosts] = useState([]);
  const [searchClient, setClient] = useState();

  const types = section?.searchTypes || TYPES_EVERYTHING;
  const showType = types.length > 1;

  if (!searchClient && typeof window !== "undefined" && window.SanityClient) {
    console.log("creating search client");
    setClient(
      window.SanityClient.createClient({
        projectId: clientConfig.sanity.projectId,
        dataset: clientConfig.sanity.dataset,
        useCdn: true,
        apiVersion: "2023-05-03",
      })
    );
  }

  useEffect(() => {
    // popover focus
    if (setOpen) {
      searchBox.current.focus();
    }
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const urlParams = new URLSearchParams(window.location.search);
    const freshQuery = urlParams.get("q") || client.query;

    // setTimeout(() => {
    //   client.setQuery(freshQuery);
    //   queryDocs({ query: freshQuery, searchClient, types }).then((posts) => {
    //     setPosts(posts);
    //   });
    // }, 1000);

    if (freshQuery) {
      if (searchClient) {
        client.setQuery(freshQuery);
        queryDocs({
          query: freshQuery,
          searchClient,
          types,
          fullResults,
          includeUnpublished,
        }).then((posts) => {
          setPosts(posts);
          client.setHasSearched(true);
        });
      } else {
        setTimeout(() => {
          client.setQuery(freshQuery);
          queryDocs({
            query: freshQuery,
            searchClient,
            types,
            fullResults,
            includeUnpublished,
          }).then((posts) => {
            setPosts(posts);
            client.setHasSearched(true);
          });
        }, 1000);
      }
    }

    // return () => {
    //   setPosts([]);
    // };
  }, [searchClient, client.query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    client.setQuery(e.target[0].value);

    if (searchClient) {
      queryDocs({
        query: client.query,
        searchClient,
        types,
        fullResults,
        includeUnpublished,
      }).then((posts) => {
        setPosts(posts);
        client.setHasSearched(true);
        site.metrics.logEvent({
          label: client.query,
          category: "inputs",
          action: "search",
        });
      });
      // setTimeout(() => {
      //   client.setHasSearched(true);
      // }, 1000);
    }
  };

  return (
    <>
      <form
        className={cn(
          "bg-dark-5 rounded-xl p-4 flex flex-wrap justify-center items-center",
          posts.length === 0 ? "mb-12" : null
        )}
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          placeholder="Keyword search..."
          className="border border-dark-10 bg-white rounded-xl py-2 px-4 mr-4 flex-grow mb-4 sm:mb-0"
          ref={searchBox}
          defaultValue={client.query}
          autoFocus={true}
          style={{
            outline: "none",
          }}
        />
        <Button
          bgColor="bg-link"
          text="Search"
          type="submit"
          className="mb-4 sm:mb-0"
        />
        <div className="ml-4 md:mb-0">
          <a
            href={`https://www.google.com/search?q=site%3Acommonroom.io+${client.query}`}
            className="text-secondary text-sm hover:text-link block"
            target="_blank"
          >
            <FontAwesomeIcon icon={faGoogle} size={"lg"} />
            <ArrowLeft rotate={180} className="ml-1" />
          </a>
        </div>
      </form>
      {posts.length > 0 ? (
        <ResultsList {...{ posts, includeUnpublished, showType, pageMax }} />
      ) : (
        <>
          {client.hasSearched && (
            <p className="text-center mt-6">
              😭 Sorry, no results. Please try a more simple search.
            </p>
          )}
        </>
      )}
      {!client.hasSearched && setOpen && (
        <p className="text-center text-xl m-12 text-auxiliary">
          {client.query ? "Searching..." : "Please enter a search query."}
        </p>
      )}
      {client.hasSearched && setOpen && (
        <h3 className="mt-12 text-center">
          <a href="/help/" className="text-link hover:text-secondary">
            Couldn't find the answer? Get more help.
          </a>
        </h3>
      )}
    </>
  );
};

const ResultsList = ({ posts, includeUnpublished, showType, pageMax = 5 }) => {
  const [pagination, setPage] = useState(0);
  const [shownPosts, setShownPosts] = useState([]);

  useEffect(() => {
    setShownPosts(
      posts.length > pagination * pageMax
        ? posts?.slice(pageMax * pagination, pageMax * (pagination + 1))
        : []
    );
  }, [pagination, posts]);

  useEffect(() => {
    setPage(0);
  }, [posts]);

  return (
    <div>
      <ul className="mt-8">
        {shownPosts.map((p) => (
          <li className="mb-2" key={p._id}>
            <a
              href={pageDocPath(p)}
              className="py-4 pr-6 text-link hover:text-black bg-dark-2 border border-dark-5 hover:bg-dark-10 rounded-xl flex cursor-pointer"
            >
              {!includeUnpublished && (
                <div className="hidden md:block flex-none">
                  <Image
                    image={p.cardImage || p.mainImage}
                    width={160}
                    className={cn(
                      "rounded-md bg-dark-5",
                      p._type === "integration" ? "px-12 py-4" : null
                    )}
                  />
                </div>
              )}
              <div className="ml-4 flex-grow">
                <p className="font-medium mb-1">
                  {p.title || `${p.company} integration`}
                </p>
                <p className="text-sm text-secondary leading-tight">
                  {p.summary
                    ? truncateText({ str: p.summary, max: 180 })
                    : null}
                </p>
                {showType ? (
                  <div className="bg-dark-10 py-1 px-2 mt-2 mr-2 rounded-md text-sm text-dark-50 inline-block">
                    {p._type === "documentation"
                      ? "docs"
                      : p._type === "post"
                      ? "blog"
                      : p._type === "asset"
                      ? "guide"
                      : p._type}
                  </div>
                ) : p.menu ? (
                  <p className="text-mono text-xs mt-2 text-secondary">
                    {pageDocPath(p)}
                  </p>
                ) : null}
              </div>
              <div className="flex items-center pl-6">
                <ArrowRight />
              </div>
            </a>
          </li>
        ))}
      </ul>
      <div className="flex mt-6">
        {pagination > 0 && (
          <a
            onClick={() => {
              setPage(pagination - 1);
            }}
            className="text-link text-semibold hover:text-black text-left flex items-center mr-4 border-r border-dark-10 pr-4 cursor-pointer"
          >
            <ArrowLeft className="mr-1" /> Previous
          </a>
        )}
        {posts.length > (pagination + 1) * pageMax ? (
          <a
            onClick={() => {
              setPage(pagination + 1);
            }}
            className="text-link text-semibold hover:text-black text-right cursor-pointer"
          >
            Next <ArrowLeft rotate={180} />
          </a>
        ) : (
          <p>No more results.</p>
        )}
      </div>
    </div>
  );
};

const queryDocs = async ({
  query,
  searchClient,
  types,
  fullResults,
  includeUnpublished,
}) => {
  if (!searchClient) return [];

  const typeQuery = types.map((t) => `_type == "${t}"`).join(" || ");
  const propQuery =
    (includeUnpublished ? "" : "status == 'published' && ") +
    "contentType != 'landing'";
  const matchQuery =
    'title match "*"+$query+"*" || pt::text(excerpt) match "*"+$query+"*" || pt::text(body) match "*"+$query+"*"';

  // boost(_type == "integration", 6),
  // boost(_type == "asset", 2)
  const posts = await searchClient.fetch(
    `*[(${typeQuery}) && (${propQuery}) && (${matchQuery})] | score(
      boost(title match "*"+$query+"*", 5),
      boost(pt::text(excerpt) match "*"+$query+"*", 3),
      boost(_type == "documentation", 5),
      boost(_type == "playbook", 2),
    ) | order(_score desc, _createdAt desc)
    [0...${fullResults ? "50" : "25"}]
    { _id, title, _type, slug, company, _createdAt, mainImage, cardImage, menu, menuParent, "summary": (pt::text(excerpt))}`,
    {
      query: query || "",
    }
  );

  return posts;
};

export const SearchPopup = ({ iconOnly, results }) => {
  const client = useContext(ClientContext);

  return (
    <>
      {iconOnly ? (
        <a
          onClick={() => {
            client.setSearchActive(true);
          }}
          className="h-full block flex items-center px-1 cursor-pointer text-link"
        >
          <SearchIcon width="16" />
        </a>
      ) : (
        <form
          onSubmit={(e) => {
            console.log("submitting", e.target[0].value);
            e.preventDefault();
            e.stopPropagation();
            client.setSearchTypes(results);
            client.setQuery(e.target[0].value);
            client.setSearchActive(true);
          }}
        >
          <div className="flex gap-2">
            <input
              type="text"
              placeholder="Search"
              className="border border-dark-10 bg-white rounded-lg p-2 placeholder:text-dark-50 text-sm"
              style={{ width: "100%" }}
            />
            <Button
              bgColor="bg-dark-20 text-white hover:bg-black"
              isSmall={true}
              icon={<SearchIcon width="12" />}
              type="submit"
            />
          </div>
        </form>
      )}
    </>
  );
};

export const SearchUX = () => {
  const client = useContext(ClientContext);

  return (
    <div className="relative z-100 h-full">
      <div
        className={cn(
          "cursor-pointer top-0 right-0 bottom-0 left-0 bg-dark-50 flex justify-center items-center",
          !client.isSearchActive ? "opacity-0" : "fixed"
        )}
        onClick={() => {
          client.setSearchActive(false);
        }}
      >
        {client.isSearchActive && (
          <div
            className="relative z-100 bg-white p-4 lg:p-6 shadow-lg rounded-xl z-100 overflow-y-scroll w-screen-md"
            style={{
              width: 768,
              maxWidth: "80%",
              maxHeight: "90%",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {client.searchTypes ? (
              client.searchTypes === "resources" ? (
                <SearchResources setOpen={client.setSearchActive} />
              ) : (
                <SearchClient
                  section={{ searchTypes: [client.searchTypes] }}
                  setOpen={client.setSearchActive}
                />
              )
            ) : (
              <SearchDocs setOpen={client.setSearchActive} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
